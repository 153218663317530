import api from '@/services/api';
import { randomNumber, sleep } from '@/helpers';
import { DONT_SHOW_CHANGE_PLAYER_POSITION_NOTIFICATION_KEY } from '@/consts';
import { get } from 'local-storage';

export default {
	async fetchBotCards ({ commit, getters }) {
		commit('setBotCards', []);
		const response = await api.deals.getDealPredefinedCards({
			dealId: getters.getGameId
		});
		if (response?.length) {
			commit('setBotCards', response);
		}
	},

	async startStudyModeTricks ({ commit, dispatch, getters }) {
		commit('setStudyModeTricksInProgress', true);
		await dispatch('fetchBotCards');

		const currentPlayerPosition = getters.getPlayerPosition;
		if (getters.getIsDummyByPosition({ position: currentPlayerPosition })) {
			const newPlayerPosition = getters.getPartnershipByPosition({ position: currentPlayerPosition });
			commit('setPlayerPosition', newPlayerPosition);
			dispatch('turnHandsForMode', getters.getGameMode);
			dispatch('setPlayerTablePosition');

			if (!get(DONT_SHOW_CHANGE_PLAYER_POSITION_NOTIFICATION_KEY)) {
				commit('setChangePlayerPositionNotificationIsVisible', true);
			} else {
				dispatch('startTricksLoop');
			}
		} else {
			dispatch('startTricksLoop');
		}
	},

	async startTricksLoop ({ commit, dispatch, getters }) {
		while (getters.getGameTricksLoopIsPossible) {
			const delay = randomNumber(10, 20) * 100; // In 1000-2000 ms
			await sleep(delay);

			if (getters.getGameData) { // Additional check
				if (!getters.getOpeningLeadHasBeenPlayed && getters.getOpeningLead) {
					await dispatch('playCardByName', { cardName: getters.getOpeningLead });
				} else if (
					getters.getOpeningLeadHasBeenPlayed &&
					!getters.getPlayerHasWon &&
					getters.getBotCards?.length
				) {
					await dispatch('playBotCard');
				} else {
					await dispatch('playCardFromDDS');
				}
			}
		}

		if (getters.getGameIsComplete) {
			commit('setStudyModeTricksInProgress', false);
		}
	},

	async playBotCard ({ dispatch, getters }) {
		const botCards = getters.getBotCards;
		const currentTrick = botCards[getters.getCurrentTrickIndexExactly] || [];
		const botCard = currentTrick.find(card => card.side === getters.getCurrentPlayer);

		if (currentTrick?.length && botCard) {
			await dispatch('playCardByName', { cardName: botCard.name });
		} else {
			// Play a card from DDS if no card is specified
			await dispatch('playCardFromDDS');
		}
	},

	async playStudyCardByName ({ commit, dispatch, state, getters }, { cardName }) {
		await dispatch('playCardByName', { cardName });
		await dispatch('startTricksLoop');
	},

	showDeclarerHint ({ dispatch, getters }) {
		dispatch(
			'simulatorParameters/showDeclarerHint',
			getters.getDeclarerHint,
			{ root: true }
		);
	},

	closeChangePlayerPositionNotification ({ dispatch, commit }) {
		dispatch('startTricksLoop');
		commit('setChangePlayerPositionNotificationIsVisible', false);
	}

};
