export default {

	getAuction: (state, getters) => {
		if (!getters.getGameData) {
			return false;
		}
		return getters.getGameData.auction;
	},

	getInitialBidsList: (state) => {
		return state.initialBidsList;
	},

	getAuctionIsComplete: (state, getters) => {
		if (!getters.getAuction ||
				!getters.getAuction.isComplete) {
			return false;
		}
		return true;
	},

	getAuctionIsVisible: (state, getters) => {
		if (
			getters.getIsStudyMode ||
			!getters.getGameData ||
			getters.getAuctionIsComplete
		) {
			return false;
		}
		return true;
	},

	getAuctionHasJustEnded: (state, getters) => {
		if (!getters.getContract) {
			return false;
		}
		if (getters.getOpeningLeadHasBeenPlayed) {
			return false;
		}
		return true;
	},

	getBidHistoryIsVisible: (state, getters) => {
		if (!getters.getGameData) {
			return false;
		}
		if (!getters.getOpeningLeadHasBeenPlayed) {
			return true;
		}
		return state.bidHistoryIsVisible;
	},

	getPossibleBids: (state, getters) => {
		if (!getters.getGameData) {
			return false;
		}
		if (getters.getAuctionIsComplete) {
			return false;
		}
		return getters.getGameData.bidOptions.contract;
	},

	getCanPass: (state, getters) => {
		if (
			!getters.getAuctionIsVisible &&
			!getters.getStudyAuctionIsVisible
		) {
			return false;
		}
		return getters.getGameData.bidOptions.canPass;
	},

	getCanDouble: (state, getters) => {
		if (
			!getters.getAuctionIsVisible &&
			!getters.getStudyAuctionIsVisible
		) {
			return false;
		}
		return getters.getGameData.bidOptions.canDouble;
	},

	getCanReDouble: (state, getters) => {
		if (
			!getters.getAuctionIsVisible &&
			!getters.getStudyAuctionIsVisible
		) {
			return false;
		}
		return getters.getGameData.bidOptions.canReDouble;
	},

	getBidHistory: (state, getters) => {
		if (!getters.getGameData) {
			return false;
		}
		return getters.getAuction.bids;
	},

	getBidsHaveBeenMade: (state, getters) => {
		if (!getters.getBidHistory ||
				!getters.getBidHistory.length) {
			return false;
		}
		return true;
	},

	getRecentBidsByPosition: (state, getters) => ({ position }) => {
		if (!getters.getGameData) {
			return false;
		}
		return getters.getBidHistory.filter((bid) => {
			return bid.player === position;
		}).slice(-6).reverse();
	},

	getBidsWithoutPasses: (state, getters) => {
		if (!getters.getBidHistory) {
			return false;
		}
		return getters.getBidHistory.filter((bid) => {
			return bid.type !== 'pass';
		});
	},

	getBidsWithContracts: (state, getters) => {
		if (!getters.getGameData) {
			return false;
		}
		return getters.getBidHistory.filter((bid) => {
			return bid.type === 'contract';
		});
	},

	getOpeningBid: (state, getters) => {
		if (!getters.getBidsWithContracts) {
			return false;
		}
		return getters.getBidsWithContracts[0];
	},

	getCurrentBid: (state, getters) => {
		if (!getters.getBidsWithContracts) {
			return false;
		}
		const lastBidIndex = getters.getBidsWithContracts.length - 1;
		return getters.getBidsWithContracts[lastBidIndex];
	},

	getBidIndex: (state, getters) => ({ bid }) => {
		return getters.getBidHistory.indexOf(bid);
	},

	getCurrentBidIndex: (state, getters) => {
		if (!getters.getCurrentBid) {
			return false;
		}
		return getters.getBidIndex({
			bid: getters.getCurrentBid
		});
	},

	getBidIsBeforeCurrentBid: (state, getters) => ({ bid }) => {
		if (!getters.getCurrentBid) {
			return false;
		}
		const currentBidIndex = getters.getCurrentBidIndex;
		const thisBidIndex = getters.getBidIndex({ bid });
		if (thisBidIndex >= currentBidIndex) {
			return false;
		}
		return true;
	},

	getBidIsCurrentBid: (state, getters) => ({ bid }) => {
		if (!getters.getGameData) {
			return false;
		}
		return bid === getters.getCurrentBid;
	},

	getContract: (state, getters) => {
		if (!getters.getAuctionIsComplete) {
			return false;
		}
		return getters.getGameData.contract;
	},

	getContractValue: (state, getters) => {
		if (!getters.getContract) {
			return false;
		}
		if (getters.getContract[1] === 'N') {
			return `${getters.getContract[0]}NT`;
		}
		return getters.getContract[0];
	},

	getContractBid: (state, getters) => {
		if (!getters.getContract) {
			return false;
		}
		return {
			bid: getters.getContract,
			value: getters.getContract[0],
			suit: getters.getContractSuit
		};
	},

	getContractSuit: (state, getters) => {
		if (!getters.getContract) {
			return false;
		}
		return getters.getGameData.contractSuit;
	},

	getTrumpSuitIconName: (state, getters) => {
		if (!getters.getTrumpSuit) {
			return false;
		}
		return getters.getTrumpSuit.fullName
			.toLowerCase().slice(0, -1);
	},

	getBidIsContract: (state, getters) => ({ bid }) => {
		if (!getters.getContract) {
			return false;
		}
		return bid.bid === getters.getContract;
	}

};
