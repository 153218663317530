import { SIMULATOR_MODES } from '@/consts';

export default {

	getStudyAuctionIsVisible: (state, getters) => {
		return !!(
			getters.getIsStudyMode &&
			getters.getGameData &&
			!getters.getAuctionIsComplete
		);
	},

	getGameBiddingLoopIsPossible: (state, getters) => {
		return !!(
			getters.getGameData &&
			!getters.getAuctionIsComplete &&
			!getters.getPlayerIsCurrentByPosition({
				position: getters.getPlayerPosition
			})
		);
	},

	getPlayerFailedAttemptToBid: (state) => {
		return state.playerFailedAttemptToBid;
	},

	getInitialBidByIndex: (state, getters) => (index) => {
		return getters.getInitialBidsList[index];
	},

	getNextCorrectBid: (state, getters) => {
		const nextIndex = getters.getBidHistory.length;
		return getters.getInitialBidByIndex(nextIndex);
	},

	getNextCorrectBidHint: (state, getters, rootState, rootGetters) => {
		const bid = getters.getNextCorrectBid;
		const position = getters.getCurrentBidder;
		const playerPosition = getters.getPlayerPosition;
		const getBidHistory = getters.getBidHistory || [];
		const alreadyPlacedBids = getBidHistory.filter(
			bid => bid.player === playerPosition
		);

		if (bid && position === playerPosition) {
			return rootGetters['simulatorParameters/getBidHintByIndex'](alreadyPlacedBids.length);
		}
		return '';
	},

	getIsBidCorrect: (state, getters) => ({ bid }) => {
		return getters.getNextCorrectBid.bid === bid;
	},

	getIsBidHighlighted: (state) => ({ bid }) => {
		return state.highlightedBid === bid;
	},

	getBidExplanationIsVisibleForMode: (state) => (mode) => {
		return [
			SIMULATOR_MODES.STUDY,
			SIMULATOR_MODES.PREVIEW
			// TODO: Add more modes if they should start with an empty auction
		].includes(mode);
	},

	getBidExplanationIsVisible: (state, getters) => {
		return getters.getBidExplanationIsVisibleForMode(getters.getGameMode);
	}

};
