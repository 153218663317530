export default {

	showBidExplanationByIndex ({ commit, getters }, bidIndex) {
		const explanation = getters.getBidExplanationByIndex(bidIndex);
		if (explanation) {
			commit('setActiveBidExplanation', explanation);
			commit('setBidExplanationIsVisible', true);
		}
	},

	hideBidExplanation ({ commit, getters }) {
		if (getters.getBidExplanationIsVisible) {
			commit('setBidExplanationIsVisible', false);
			commit('setActiveBidExplanation', '');
		}
	}
};
