import { defaultTablePositions, DEFAULT_PLAYER_TABLE_POSITION, DEFAULT_PLAYER_POSITION } from '@/consts';
import { arrayShift } from '@/helpers';

export default {

	getTablePositionsForPlayer: (state, getters) => (position) => {
		if (position !== DEFAULT_PLAYER_POSITION) {
			const currentTablePositions = defaultTablePositions.map(pos => pos.tablePosition);
			const defaultPositionIndex = currentTablePositions.indexOf(DEFAULT_PLAYER_TABLE_POSITION);

			const playerPositionIndex = defaultTablePositions
				.map(pos => pos.playerPosition)
				.indexOf(position);

			const shiftedTablePositions = arrayShift(
				currentTablePositions,
				defaultPositionIndex + playerPositionIndex
			);

			return defaultTablePositions.map((pos, index) => {
				return {
					playerPosition: pos.playerPosition,
					tablePosition: shiftedTablePositions[index]
				};
			});
		}

		return [...defaultTablePositions];
	},

	getNewTablePositions: (state, getters) => ({ direction }) => {
		return state.tablePositions.map((position, positionIndex) => {
			return {
				...position,
				tablePosition: getters.getNewTablePosition({ direction, positionIndex })
			};
		});
	},

	getNewTablePosition: (state, getters) => ({ direction, positionIndex }) => {
		const newPositionIndex = getters.getNewPositionIndex({ direction, positionIndex });
		return state.tablePositions[newPositionIndex].tablePosition;
	},

	getNewPositionIndex: (state) => ({ direction, positionIndex }) => {
		if (direction === 'clockwise') {
			if (positionIndex === 3) {
				return 0;
			}
			return positionIndex + 1;
		}
		if (positionIndex === 0) {
			return 3;
		}
		return positionIndex - 1;
	},

	getTablePositionByPosition: (state, getters) => ({ position }) => {
		return state.tablePositions.find((thisPosition) => {
			return thisPosition.playerPosition === position;
		}).tablePosition;
	}

};
