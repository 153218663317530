export default {

	setBidExplanations (state, value) {
		state.bidExplanations = value;
	},

	setBidExplanationIsVisible (state, value) {
		state.bidExplanationIsVisible = value;
	},

	setActiveBidExplanation (state, explanation) {
		state.activeBidExplanation = explanation;
	}

};
