export const supportEmail = 'mailto:support@bridge128.com';

export const biddingSystems = [
	{
		text: 'ACOL (UK, NZ, Ireland)',
		value: 'ACOL'
	},
	{
		text: 'SAYC (USA, most of the rest of the world)',
		value: 'SAYC'
	},
	{
		text: 'Majeurs Cinquieme (France)',
		value: 'Majeurs Cinquieme'
	},
	{
		text: '2-over-1 (Advanced players)',
		value: '2-over-1'
	}
];

export const suitOrder = [
	'spades',
	'hearts',
	'diamonds',
	'clubs'
];

export const vulnerabilities = [
	'Unimportant',
	'Non-Vol vs Non-Vul',
	'Non-Vul vs Vul',
	'Vul vs Non-vul',
	'Vul vs Vul'
];

export const activityTypes = [
	{
		text: 'Video',
		value: 'video'
	},
	{
		text: 'Presentation',
		value: 'embeddedPresentation'
	},
	// decided late in the project that
	// quiz should be called a question in FE
	// it is too late to change all FE code to refer
	// to the term question instead of quiz
	// so keep calling it a quiz internally
	// a later v2 of quiz might have multiple questions
	// and use the term quiz in FE
	{
		text: 'Question',
		value: 'quiz'
	},
	{
		text: 'Deal run-through',
		value: 'deal'
	},
	{
		text: 'Intertitle',
		value: 'titlePage'
	}
];

export const cardOrder = [
	'A',
	'K',
	'Q',
	'J',
	'T',
	'9',
	'8',
	'7',
	'6',
	'5',
	'4',
	'3',
	'2'
];

export const playerAbility = [
	'Club',
	'Tournament',
	'National Team',
	'Champion'
];

export const POSITIONS_SHORT = {
	WEST: 'W',
	NORTH: 'N',
	EAST: 'E',
	SOUTH: 'S'
};

export const DEFAULT_PLAYER_POSITION = POSITIONS_SHORT.SOUTH;

export const TABLE_POSITIONS = {
	TOP: 'top',
	RIGHT: 'right',
	BOTTOM: 'bottom',
	LEFT: 'left'
};

export const defaultTablePositions = [
	{
		tablePosition: TABLE_POSITIONS.TOP,
		playerPosition: POSITIONS_SHORT.NORTH
	},
	{
		tablePosition: TABLE_POSITIONS.RIGHT,
		playerPosition: POSITIONS_SHORT.EAST
	},
	{
		tablePosition: TABLE_POSITIONS.BOTTOM,
		playerPosition: POSITIONS_SHORT.SOUTH
	},
	{
		tablePosition: TABLE_POSITIONS.LEFT,
		playerPosition: POSITIONS_SHORT.WEST
	}
];

export const DEFAULT_PLAYER_TABLE_POSITION = TABLE_POSITIONS.BOTTOM;

export const seatPositions = [
	{
		text: 'West',
		value: POSITIONS_SHORT.WEST
	},
	{
		text: 'North',
		value: POSITIONS_SHORT.NORTH
	},
	{
		text: 'East',
		value: POSITIONS_SHORT.EAST
	},
	{
		text: 'South',
		value: POSITIONS_SHORT.SOUTH
	}
];

export const SEATS = {
	N: { name: 'North', short: 'N', partner: 'S', pair: 'NS', left: 'E' },
	S: { name: 'South', short: 'S', partner: 'N', pair: 'NS', left: 'W' },
	E: { name: 'East', short: 'E', partner: 'W', pair: 'EW', left: 'S' },
	W: { name: 'West', short: 'W', partner: 'E', pair: 'EW', left: 'N' }
};

export const partnerships = [
	['N', 'S'],
	['E', 'W']
];

export const experienceLevels = [
	{
		text: '1 (Absolute Beginner)',
		value: 1
	},
	{
		text: '2 (Beginner+)',
		value: 2
	},
	{
		text: '3 (Improver)',
		value: 3
	},
	{
		text: '4 (Improver+)',
		value: 4
	},
	{
		text: '5 (Intermediate)',
		value: 5
	},
	{
		text: '6 (Intermediate+)',
		value: 6
	},
	{
		text: '7 (Advanced)',
		value: 7
	},
	{
		text: '8 (Advanced+)',
		value: 8
	},
	{
		text: '9 (Tournament)',
		value: 9
	},
	{
		text: '10 (World Class)',
		value: 10
	}
];

export const defaultResourceCategory = 'general_lesson_resource';

export const resourceCategoryOptions = [
	{
		text: 'General lesson resource',
		value: 'general_lesson_resource'
	},
	{
		text: 'Hand record',
		value: 'hand_record'
	},
	// TODO: Keep this file category just in case
	//  We already have a separate page for simulator deals management
	//  /teaching/courses/{course_id}/lessons/{lesson_id}/deals
	{
		text: 'Simulator deal',
		value: 'simulator_deal'
	}
];

export const dealRecordingType = 'deal_recording';

export const dealAnalysisType = 'deal_analysis';

export const SIMULATOR_MODES = {
	PREVIEW: 'preview',
	STUDY: 'study',
	PLAY: 'play'
};

export const DONT_SHOW_CHANGE_PLAYER_POSITION_NOTIFICATION_KEY = 'DONT_SHOW_CHANGE_PLAYER_POSITION_NOTIFICATION_KEY';

export const suitOptions = [
	{
		value: 'H',
		symbol: '♥',
		colour: 'red'
	},
	{
		value: 'D',
		symbol: '♦',
		colour: 'red'
	},
	{
		value: 'S',
		symbol: '♠',
		colour: 'black'
	},
	{
		value: 'C',
		symbol: '♣',
		colour: 'black'
	}
];

export const differenceOptions = [
	{
		value: '-',
		condition: 'less'
	},
	{
		value: '=',
		condition: 'equal'
	},
	{
		value: '+',
		condition: 'more'
	}
];

export const defaultResultMessages = {
	'-': 'Bad luck!',
	'=': 'Well played!',
	'+': 'Brilliant!'
};
