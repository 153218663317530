export default {

	showDeclarerHint ({ commit, getters }, hint) {
		if (hint) {
			commit('setActiveDeclarerHint', hint);
			commit('setDeclarerHintIsVisible', true);
		}
	},

	hideDeclarerHint ({ commit, getters }) {
		if (getters.getDeclarerHintIsVisible) {
			commit('setDeclarerHintIsVisible', false);
			commit('setActiveDeclarerHint', '');
		}
	}
};
