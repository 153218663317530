import bidExplanations from './bidExplanations';
import bidHints from './bidHints';
import declarerHint from './declarerHint';

export default {

	...bidExplanations,
	...bidHints,
	...declarerHint

};
