import api      from '@/services/api/init';
import store    from '@/store';
import helpers  from '@/services/api/helpers';

const req = async ({
	method,
	url,
	options,
	requestData,
	isFormData
}) => {
	try {
		const reqUrl = helpers.getReqUrl({ url, options });
		const response = await api[method](
			reqUrl,
			isFormData ? requestData : helpers.formatRequestData(requestData),
			isFormData ? {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			} : undefined
		);
		// if (method === 'delete') {
		// 	return response;
		// }
		if (!response) {
			throw new Error('No data found');
		}
		// workaround for some delete responses having false data
		if (!response.data) {
			return true;
		}
		return helpers.formatResponseData(response.data);
	} catch (error) {
		if (error?.response?.status === 401 &&
				store.getters['auth/getIsAuthenticated']) {
			store.dispatch('auth/logOut');
			return;
		}
		helpers.handleRequestError(error);
	}
};

export const get = (url, params) => {
	console.log(`Get ${url}`, params);
	let options;
	if (params?.options) {
		options = params.options;
	}
	return req({
		method: 'get',
		url,
		options
	});
};

export const post = (url, params) => {
	console.log(`Post ${url}`, params);
	let requestData;
	if (params?.requestData) {
		requestData = params.requestData;
	}
	let options;
	if (params?.options) {
		options = params.options;
	}
	let isFormData;
	if (params?.isFormData) {
		isFormData = true;
	}
	return req({
		method: 'post',
		url,
		requestData,
		isFormData,
		options
	});
};

export const put = (url, params) => {
	console.log(`Put ${url}`, params);
	let requestData;
	if (params?.requestData) {
		requestData = params.requestData;
	}
	return req({
		method: 'put',
		url,
		requestData
	});
};

export const del = (url) => {
	console.log(`Delete ${url}`);
	return req({
		method: 'delete',
		url
	});
};
