export default {
	activity:           [],
	groups:             [],
	previousGroups:     [],
	nextGroups:         [],
	// pagination
	perPage:            20,
	currentPage:        1,
	numPages:           0,
	totalItems:         0,
	// search
	searchString:       '',
	// filters
	activeFilters:      {},
	boardFilters:       [],
	dealerFilters:      [],
	filenameFilters:    [],
	focusFilters:       [],
	keywordsFilters:    [],
	nameFilters:        [],
	tagsFilters:        [],
	classifiedFilter:   false
};
