export default {

	// Correct answers

	getCorrectAnswers: (state) => {
		if (!state?.diagrams) {
			return false;
		}
		return state.diagrams.filter((diagram) => {
			if (diagram.type === 'BiddingInterface') {
				return true;
			}
			return diagram.config.isCorrect;
		});
	},

	getHasMultipleCorrectAnswers: (state, getters) => {
		if (!getters.getCorrectAnswers) {
			return false;
		}
		return getters.getCorrectAnswers?.length > 1;
	},

	getNumberOfCorrectAnswers: (state, getters) => {
		if (!getters.getCorrectAnswers) {
			return false;
		}
		return getters.getCorrectAnswers?.length;
	},

	getAnswerIsCorrectByUuidAndAnswerValue: (state, getters) => ({
		uuid,
		answerValue
	}) => {
		const diagram = getters.getDiagramByUuid({
			uuid: uuid
		});
		if (!diagram) {
			return false;
		}
		if (diagram.type === 'BiddingInterface') {
			return diagram.config.answerValue === answerValue;
		}
		return diagram.config.isCorrect;
	},

	// Selected answers

	getSelectedAnswers: (state, getters) => {
		if (!state?.selectedAnswers) {
			return false;
		}
		return state.selectedAnswers;
	},

	// get by UUID

	getAnswerIsSelectedByUuidAndAnswerValue: (state, getters) => ({ uuid, answerValue }) => {
		if (!getters.getSelectedAnswers) {
			return false;
		}
		return getters.getSelectedAnswers.findIndex((answer) => {
			if (answer.uuid !== uuid ||
					answer.answerValue !== answerValue) {
				return false;
			}
			return true;
		}) >= 0;
	},

	getSomeAnswersAreSelected: (state, getters) => {
		if (!getters.getSelectedAnswers) {
			return false;
		}
		return getters.getSelectedAnswers?.length > 0;
	},

	getNumberOfSelectedAnswers: (state, getters) => {
		if (!getters.getSelectedAnswers) {
			return false;
		}
		return getters.getSelectedAnswers?.length;
	},

	// Selected correct answers

	getSelectedCorrectAnswers: (state, getters) => {
		if (!getters.getSelectedAnswers) {
			return false;
		}
		return getters.getSelectedAnswers.filter((answer) => {
			return getters.getAnswerIsCorrectByUuidAndAnswerValue({
				uuid: answer.uuid,
				answerValue: answer.answerValue
			});
		});
	},

	getAnswerIsSelectedAndCorrectByUuidAndAnswerValue: (state, getters) => ({ uuid, answerValue }) => {
		if (!getters.getSelectedCorrectAnswers) {
			return false;
		}
		return getters.getSelectedCorrectAnswers.findIndex((answer) => {
			if (answer.uuid !== uuid ||
					answer.answerValue !== answerValue) {
				return false;
			}
			return true;
		}) >= 0;
	},

	getAnswerIsSelectedAndCorrectByUuid: (state, getters) => ({ uuid }) => {
		if (!getters.getSelectedCorrectAnswers) {
			return false;
		}
		return getters.getSelectedCorrectAnswers.findIndex((answer) => {
			if (answer.uuid !== uuid) {
				return false;
			}
			return true;
		}) >= 0;
	},

	getAnswerIsSelectedAndCorrectByUuidAndAnswerValueAndQuizIsComplete: (state, getters) => ({ uuid, answerValue }) => {
		if (!getters.getQuizIsComplete) {
			return false;
		}
		return getters.getAnswerIsSelectedAndCorrectByUuidAndAnswerValue({ uuid, answerValue });
	},

	getAnswerIsSelectedAndCorrectByUuidAndQuizIsComplete: (state, getters) => ({ uuid }) => {
		if (!getters.getQuizIsComplete) {
			return false;
		}
		return getters.getAnswerIsSelectedAndCorrectByUuid({ uuid });
	},

	getNumberOfSelectedCorrectAnswers: (state, getters) => {
		if (!getters.getSelectedCorrectAnswers) {
			return false;
		}
		return getters.getSelectedCorrectAnswers?.length;
	},

	getSomeSelectedAnswersAreCorrect: (state, getters) => {
		if (!getters.getNumberOfSelectedCorrectAnswers) {
			return false;
		}
		return getters.getNumberOfSelectedCorrectAnswers > 0;
	},

	getAllCorrectAnswersAreSelected: (state, getters) => {
		const numCorrect = getters.getNumberOfCorrectAnswers;
		const numSelected = getters.getNumberOfSelectedAnswers;
		return numCorrect === numSelected;
	},

	getAllSelectedAnswersAreCorrect: (state, getters) => {
		return !getters.getSomeSelectedAnswersAreIncorrect;
	},

	// Selected incorrect answers

	getSelectedIncorrectAnswers: (state, getters) => {
		if (!getters.getSelectedAnswers) {
			return false;
		}
		return getters.getSelectedAnswers.filter((answer) => {
			return !getters.getAnswerIsCorrectByUuidAndAnswerValue({
				uuid: answer.uuid,
				answerValue: answer.answerValue
			});
		});
	},

	getAnswerIsSelectedAndIncorrectByUuidAndAnswerValue: (state, getters) => ({ uuid, answerValue }) => {
		if (!getters.getSelectedIncorrectAnswers) {
			return false;
		}
		return getters.getSelectedIncorrectAnswers.findIndex((answer) => {
			if (answer.uuid !== uuid ||
					answer.answerValue !== answerValue) {
				return false;
			}
			return true;
		}) >= 0;
	},

	getAnswerIsSelectedAndIncorrectByUuid: (state, getters) => ({ uuid }) => {
		if (!getters.getSelectedIncorrectAnswers) {
			return false;
		}
		return getters.getSelectedIncorrectAnswers.findIndex((answer) => {
			if (answer.uuid !== uuid) {
				return false;
			}
			return true;
		}) >= 0;
	},

	getAnswerIsSelectedAndIncorrectByUuidAndAnswerValueAndQuizIsComplete: (state, getters) => ({ uuid, answerValue }) => {
		if (!getters.getQuizIsComplete) {
			return false;
		}
		return getters.getAnswerIsSelectedAndIncorrectByUuidAndAnswerValue({ uuid, answerValue });
	},

	getAnswerIsSelectedAndIncorrectByUuidAndQuizIsComplete: (state, getters) => ({ uuid }) => {
		if (!getters.getQuizIsComplete) {
			return false;
		}
		return getters.getAnswerIsSelectedAndIncorrectByUuid({ uuid });
	},

	getNumberOfSelectedIncorrectAnswers: (state, getters) => {
		return getters.getSelectedIncorrectAnswers.length;
	},

	getSomeSelectedAnswersAreIncorrect: (state, getters) => {
		return getters.getNumberOfSelectedIncorrectAnswers > 0;
	},

	// ui

	getCorrectAnswersAreHighlighted: (state) => {
		return state.correctAnswersAreHighlighted;
	},

	getAnswerIsHighlightedByUuidAndAnswerValue: (state, getters) => ({
		uuid,
		answerValue
	}) => {
		if (!getters.getAnswerIsCorrectByUuidAndAnswerValue({
			uuid,
			answerValue
		})) {
			return false;
		}
		if (!getters.getCorrectAnswersAreHighlighted) {
			return false;
		}
		return true;
	},

	// progressing

	getQuizIsComplete: (state, getters) => {
		if (getters.getMustChooseAllCorrectAnswers) {
			return getters.getAllCorrectAnswersAreSelected;
		}
		return getters.getSomeAnswersAreSelected;
	},

	getQuizIsCompleteAndCorrect: (state, getters) => {
		return getters.getQuizIsComplete && getters.getAllSelectedAnswersAreCorrect;
	},

	getQuizIsCompleteButIncorrect: (state, getters) => {
		return getters.getQuizIsComplete && getters.getSomeSelectedAnswersAreIncorrect;
	},

	getCurrentProgress: (state, getters) => {
		if (!getters.getQuizIsComplete) {
			return null;
		}
		if (getters.getQuizIsCompleteButIncorrect) {
			return false;
		}
		return true;
	}

};
