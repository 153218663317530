export default {

	showBidHintByIndex ({ commit, getters }, bidIndex) {
		const hint = getters.getBidHintByIndex(bidIndex);
		if (hint) {
			commit('setActiveBidHint', hint);
			commit('setBidHintIsVisible', true);
		}
	},

	showBidHint ({ commit, getters }, hint) {
		if (hint) {
			commit('setActiveBidHint', hint);
			commit('setBidHintIsVisible', true);
		}
	},

	hideBidHint ({ commit, getters }) {
		if (getters.getBidHintIsVisible) {
			commit('setBidHintIsVisible', false);
			commit('setActiveBidHint', '');
		}
	}
};
