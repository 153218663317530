export default {

	clearAllHiddenCards (state) {
		state.hiddenCards = [];
	},

	setCardIsHidden (state, { cardName }) {
		state.hiddenCards.push(cardName);
	},

	setCardsAreHidden (state, { cardNames }) {
		state.hiddenCards = [
			...state.hiddenCards,
			...cardNames
		];
	},

	setCardsAreNotHidden (state, { cardNames }) {
		state.hiddenCards = state.hiddenCards.filter((thisCardName) => {
			return !cardNames.includes(thisCardName);
		});
	},

	setCardIsNotHidden (state, { cardName }) {
		state.hiddenCards = state.hiddenCards.filter((thisCardName) => {
			return cardName !== thisCardName;
		});
	}

};
