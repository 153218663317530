export default {

	getRolesControlIsDisabled: (state, getters, rootState, rootGetters) => {
		if (rootGetters['simulator/getGameData']) {
			return false;
		}
		return true;
	},

	getRolesControlAction: (state, getters, rootState, rootGetters) => {
		if (rootGetters['simulator/getRolesAreVisible']) {
			return 'setRolesAreNotVisible';
		}
		return 'setRolesAreVisible';
	},

	getRolesControl: (state, getters) => {
		return {
			icon: 'user circle',
			id: 'showRoles',
			text: 'Roles',
			isDisabled: getters.getRolesControlIsDisabled,
			onClick: {
				type: 'commit',
				module: 'simulator',
				mutation: getters.getRolesControlAction
			}
		};
	}

};
