import { pascalCase } from 'change-case';

export default {

	getTurnControlsIsDisabled: (state, getters, rootState, rootGetters) => {
		if (rootGetters['simulator/getGameData']) {
			return false;
		}
		return true;
	},

	getTurnSuitText: (state, getters, rootState, rootGetters) => ({ suit }) => {
		const pascalSuit = pascalCase(suit);
		if (rootGetters[`simulator/get${pascalSuit}AreFaceDown`]) {
			return `Turn ${suit} face up`;
		}
		return `Turn ${suit} face down`;
	},

	getTurnSuitAction: (state, getters, rootState, rootGetters) => ({ suit }) => {
		const pascalSuit = pascalCase(suit);
		if (rootGetters[`simulator/get${pascalSuit}AreFaceDown`]) {
			return `set${pascalSuit}AreFaceUp`;
		}
		return `set${pascalSuit}AreFaceDown`;
	},

	getTurnSuitMenuControl: (state, getters) => ({ suit }) => {
		return {
			text: getters.getTurnSuitText({ suit }),
			onClick: {
				type: 'dispatch',
				module: 'simulator',
				action: getters.getTurnSuitAction({ suit })
			}
		};
	},

	getTurnAllCardsFaceUpMenuControl: (state, getters, rootState, rootGetters) => {
		if (!rootGetters['simulator/getCardsAreFaceDown']) {
			return false;
		}
		return {
			text: 'Turn all cards face up',
			onClick: {
				type: 'dispatch',
				module: 'simulator',
				action: 'turnAllCardsFaceUp'
			}
		};
	},

	getTurnAllCardsFaceDownMenuControl: (state, getters, rootState, rootGetters) => {
		if (rootGetters['simulator/getAllCardsAreFaceDown']) {
			return false;
		}
		return {
			text: 'Turn all cards face down',
			onClick: {
				type: 'dispatch',
				module: 'simulator',
				action: 'turnAllCardsFaceDown'
			}
		};
	},

	getTurnControl: (state, getters) => {
		return {
			icon: 'flip card',
			id: 'turn',
			text: 'Turn',
			isDisabled: getters.getTurnControlIsDisabled,
			menu: [
				getters.getTurnSuitMenuControl({ suit: 'spades' }),
				getters.getTurnSuitMenuControl({ suit: 'hearts' }),
				getters.getTurnSuitMenuControl({ suit: 'diamonds' }),
				getters.getTurnSuitMenuControl({ suit: 'clubs' }),
				getters.getTurnAllCardsFaceUpMenuControl,
				getters.getTurnAllCardsFaceDownMenuControl
			].filter(Boolean)
		};
	}

};
