export default {
	props: {
		actionId: {
			type: String,
			default: undefined
		},
		type: {
			type: String,
			default: undefined
		},
		route: {
			type: [String, Boolean],
			default: undefined
		},
		action: {
			type: String,
			default: undefined
		},
		payload: {
			type: Object,
			default: () => {}
		},
		url: {
			type: String,
			default: undefined
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		getComponent () {
			if (this.url) {
				return 'a';
			}
			if (this.route) {
				return 'router-link';
			}
			if (this.action) {
				return 'button';
			}
			if (this.actionId) {
				return 'button';
			}
			if (this.type === 'submit') {
				return 'button';
			}
			return 'div';
		},
		getProps () {
			if (this.getComponent === 'router-link') {
				return {
					to: this.route
				};
			}
			if (this.getComponent === 'a') {
				return {
					href: this.url,
					target: '_blank'
				};
			}
			if (!this.type) {
				if (this.getComponent === 'button') {
					return {
						type: 'button'
					};
				}
				return {};
			}
			return {
				type: this.type
			};
		}
	},
	methods: {
		click () {
			if (this.actionId) {
				this.$emit('actionClick', this.actionId);
			}
			if (this.getComponent === 'router-link' || !this.action || this.url) {
				return false;
			}
			this.$store.dispatch(this.action, this.payload, { root: true });
		}
	}
};
