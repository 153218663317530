export default {
	bidExplanations: [],
	bidExplanationIsVisible: false,
	activeBidExplanation: '',
	bidHints: [],
	bidHintIsVisible: false,
	activeBidHint: '',
	declarerHintIsVisible: false,
	activeDeclarerHint: ''
};
