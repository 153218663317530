export default {

	getTricks: (state, getters) => {
		if (!getters.getGameData ||
				!getters.getGameData.tricks) {
			return false;
		}
		return getters.getGameData.tricks;
	},

	getCardsHaveBeenPlayed: (state, getters) => {
		if (!getters.getTricks ||
				!getters.getTricks.length) {
			return false;
		}
		return true;
	},

	getCurrentTrickIndex: (state, getters) => {
		if (!getters.getTricks) {
			return false;
		}
		return getters.getTricks.length - 1;
	},

	getOpeningLeadHasBeenPlayed: (state, getters) => {
		return getters.getCardsHaveBeenPlayed;
	},

	getOpeningLeadHasJustBeenPlayed: (state, getters) => {
		if (!getters.getCardsHaveBeenPlayed) {
			return false;
		}
		if (getters.getCurrentTrickIndex !== 0) {
			return false;
		}
		if (getters.getNumCardsInCurrentTrick !== 1) {
			return false;
		}
		return true;
	},

	getCurrentTrick: (state, getters) => {
		if (!getters.getTricks ||
				!getters.getTricks.length) {
			return false;
		}
		return getters.getTricks[getters.getCurrentTrickIndex];
	},

	getCardsInCurrentTrick: (state, getters) => {
		if (!getters.getCurrentTrick ||
				!getters.getCurrentTrick.cards ||
				!getters.getCurrentTrick.cards.length) {
			return false;
		}
		return getters.getCurrentTrick.cards;
	},

	getNumCardsInCurrentTrick: (state, getters) => {
		if (!getters.getCurrentTrick ||
				!getters.getCurrentTrick.cards ||
				!getters.getCurrentTrick.cards.length) {
			return false;
		}
		return getters.getCurrentTrick.cards.length;
	},

	getWinningCardInCurrentTrick: (state, getters) => {
		if (getters.getNumCardsInCurrentTrick !== 4) {
			return false;
		}
		return getters.getCurrentTrick.winningCard;
	},

	getCardIsWinningCardInCurrentTrick: (state, getters) => ({ card }) => {
		if (!getters.getWinningCardInCurrentTrick) {
			return false;
		}
		return getters.getWinningCardInCurrentTrick === card.name;
	},

	getCardIsInCurrentTrickByCardName: (state, getters) => ({ cardName }) => {
		if (!getters.getCardsInCurrentTrick) {
			return false;
		}
		return getters.getCardsInCurrentTrick.find((card) => {
			return cardName === card.name;
		});
	},

	getGameTricksLoopIsPossible: (state, getters) => {
		return !!(
			getters.getGameData &&
			!getters.getGameIsComplete &&
			!getters.getPlayerIsCurrentByPosition({
				position: getters.getPlayerPosition
			}) &&
			!getters.getIsDummyByPosition({
				position: getters.getCurrentPlayer
			})
		);
	},

	getStudyModeTricksInProgress: (state) => {
		return state.studyModeTricksInProgress;
	},

	getPlayerHasWon: (state, getters) => {
		const tricks = getters.getTricks;
		if (tricks?.length) {
			return !!tricks.find(trick => trick.winningPair?.includes(getters.getPlayerPosition));
		}

		return false;
	},

	getPlayerHasWonTricksCount: (state, getters) => {
		const tricks = getters.getTricks;
		if (tricks?.length) {
			return tricks.filter(trick => trick.winningPair?.includes(getters.getPlayerPosition)).length;
		}

		return 0;
	},

	getCurrentTrickIndexExactly: (state, getters) => {
		if (!getters.getTricks) {
			return false;
		}
		return getters.getTricks.filter(trick => !!trick.winningPair).length;
	},

	getBotCards: (state) => {
		return state.botCards;
	}

};
