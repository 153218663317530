import gameService from '@/services/gameService';

export default {

	async addBid ({ commit, dispatch, getters }, { bid }) {
		try {
			const gameData = await gameService.addBidByName({
				bidName: bid.bid,
				gameData: getters.getGameData
			});
			commit('setCurrentGameData', { currentGameData: gameData });
		} catch (err) {
			console.log(err);
		}
	},

	async resetToBeginningOfAuction (
		{ commit, dispatch, state, getters },
		payload
	) {
		const gameData = payload?.gameData || getters.getGameData;
		if (!gameData) {
			return false;
		}

		await dispatch('setDDSScoresAreNotVisible');

		const currentGameData = await gameService.resetToBeginningOfAuction({
			gameData
		});

		commit('setCurrentGameData', { currentGameData });
		dispatch('turnAllCardsFaceDown');
		commit('clearAllHighlightedCards');
	}

};
