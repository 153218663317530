export default {

	setFilterOptions (state, {
		board       = [],
		dealer      = [],
		filename    = [],
		focus       = [],
		keywords    = [],
		name        = [],
		tags        = [],
		classified  = undefined
	}) {
		state.boardFilters      = board;
		state.dealerFilters     = dealer;
		state.filenameFilters   = filename;
		state.focusFilters      = focus;
		state.keywordsFilters   = keywords;
		state.nameFilters       = name;
		state.tagsFilters       = tags;
		state.classifiedFilter  = classified;
	},

	clearFilterOptions (state) {
		state.boardFilters      = [];
		state.dealerFilters     = [];
		state.filenameFilters   = [];
		state.focusFilters      = [];
		state.keywordsFilters   = [];
		state.nameFilters       = [];
		state.tagsFilters       = [];
		state.classifiedFilter  = undefined;
	},

	setActiveFilters (state, { activeFilters }) {
		state.activeFilters = activeFilters;
	},

	clearActiveFilters (state) {
		state.activeFilters = {};
	},

	setSearchString (state, { searchString }) {
		state.searchString = searchString;
	},

	clearSearchString (state) {
		state.searchString = '';
	}

};
