import { pascalCase } from 'change-case';

export default {

	getHideControlIsDisabled: (state, getters, rootState, rootGetters) => {
		if (rootGetters['simulator/getGameData']) {
			return false;
		}
		return true;
	},

	getHideSuitText: (state, getters, rootState, rootGetters) => ({ suit }) => {
		const pascalSuit = pascalCase(suit);
		if (rootGetters[`simulator/get${pascalSuit}AreHidden`]) {
			return `Show ${suit}`;
		}
		return `Hide ${suit}`;
	},

	getHideSuitAction: (state, getters, rootState, rootGetters) => ({ suit }) => {
		const pascalSuit = pascalCase(suit);
		if (rootGetters[`simulator/get${pascalSuit}AreHidden`]) {
			return `set${pascalSuit}AreNotHidden`;
		}
		return `set${pascalSuit}AreHidden`;
	},

	getHideSuitMenuControl: (state, getters) => ({ suit }) => {
		return {
			text: getters.getHideSuitText({ suit }),
			onClick: {
				type: 'dispatch',
				module: 'simulator',
				action: getters.getHideSuitAction({ suit })
			}
		};
	},

	getHideAllCardsAction: (state, getters, rootState, rootGetters) => {
		if (rootGetters['simulator/getCardsAreHidden']) {
			return 'setAllCardsAreVisible';
		}
		return 'hideAllHands';
	},

	getShowAllCardsMenuControl: (state, getters, rootState, rootGetters) => {
		if (!rootGetters['simulator/getCardsAreHidden']) {
			return false;
		}
		return {
			text: 'Show all cards',
			onClick: {
				type: 'dispatch',
				module: 'simulator',
				action: 'setAllCardsAreVisible'
			}
		};
	},

	getHideAllCardsMenuControl: (state, getters, rootState, rootGetters) => {
		if (rootGetters['simulator/getAllCardsAreHidden']) {
			return false;
		}
		return {
			text: 'Hide all cards',
			onClick: {
				type: 'dispatch',
				module: 'simulator',
				action: 'hideAllHands'
			}
		};
	},

	getHideControl: (state, getters) => {
		return {
			icon: 'hide',
			id: 'hide',
			text: 'Hide',
			isDisabled: getters.getHideControlIsDisabled,
			menu: [
				getters.getHideSuitMenuControl({ suit: 'spades' }),
				getters.getHideSuitMenuControl({ suit: 'hearts' }),
				getters.getHideSuitMenuControl({ suit: 'diamonds' }),
				getters.getHideSuitMenuControl({ suit: 'clubs' }),
				getters.getShowAllCardsMenuControl,
				getters.getHideAllCardsMenuControl
			].filter(Boolean)
		};
	}

};
