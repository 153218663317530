export default {

	setPlayerTablePosition ({ commit, getters }) {
		const position = getters.getPlayerPosition;
		const tablePositions = getters.getTablePositionsForPlayer(position);

		commit('setTablePositions', { tablePositions });
	},

	rotateTablePositionsClockwise ({ commit, getters }) {
		commit('setTablePositions', {
			tablePositions: getters.getNewTablePositions({
				direction: 'clockwise'
			})
		});
	},

	rotateTablePositionsAnticlockwise ({ commit, getters }) {
		commit('setTablePositions', {
			tablePositions: getters.getNewTablePositions({
				direction: 'anticlockwise'
			})
		});
	}

};
