import { DEFAULT_PLAYER_POSITION, partnerships, SEATS } from '@/consts';
import { seatPositions }  from '@/consts';

export default {

	getPlayerPosition: (state) => {
		return state.playerPosition;
	},

	getPlayerPositionByIndex: (state) => ({ index }) => {
		if (!state.playersPositions[index]) {
			return DEFAULT_PLAYER_POSITION;
		}
		return state.playersPositions[index];
	},

	getPositionStringByPosition: (state, getters) => ({ position }) => {
		return seatPositions.find((seatPosition) => {
			return seatPosition.value === position;
		}).text;
	},

	getPlayersAreVisible: (state, getters) => {
		if (!getters.getGameData) {
			return false;
		}
		return true;
	},

	getPlayerByPosition: (state, getters) => ({ position }) => {
		if (!getters.getGameData) {
			return false;
		}
		return getters.getGameData[`player${position}`];
	},

	getCurrentBidder: (state, getters) => {
		if (
			!getters.getAuctionIsVisible &&
			!getters.getStudyAuctionIsVisible
		) {
			return false;
		}
		return getters.getGameData.auction.currentBidder;
	},

	getCurrentPlayer: (state, getters) => {
		if (!getters.getAuctionIsComplete ||
				!getters.getGameData.currentPlayer) {
			return false;
		}
		return getters.getGameData.currentPlayer;
	},

	getPlayerIsCurrentBidderByPosition: (state, getters) => ({ position }) => {
		return getters.getCurrentBidder === position;
	},

	getPlayerIsCurrentPlayerByPosition: (state, getters) => ({ position }) => {
		return getters.getCurrentPlayer === position;
	},

	getPlayerIsCurrentByPosition: (state, getters) => ({ position }) => {
		if (getters.getPlayerIsCurrentBidderByPosition({ position })) {
			return true;
		}
		return getters.getPlayerIsCurrentPlayerByPosition({ position });
	},

	getRolesByPosition: (state, getters) => ({
		position
	}) => {
		if (!getters.getRolesAreVisible) {
			return false;
		}
		return [
			(getters.getIsOpenerByPosition({ position }) ?
				{
					text: 'Opener',
					showDuringAuction: true
				} : false
			),
			(getters.getIsResponderByPosition({ position }) ?
				{
					text: 'Responder',
					showDuringAuction: true
				} : false
			),
			(getters.getIsOvercallerByPosition({ position }) ?
				{
					text: 'Overcaller',
					showDuringAuction: true
				} : false
			),
			(getters.getIsDeclarerByPosition({ position }) ?
				{
					text: 'Declarer'
				} : false
			),
			(getters.getIsDummyByPosition({ position }) ?
				{
					text: 'Dummy'
				} : false
			),
			(getters.getIsDefenderByPosition({ position }) ?
				{
					text: 'Defender'
				} : false
			)
		].filter(Boolean).filter((role) => {
			if (!getters.getAuctionIsComplete &&
					role.showDuringAuction === true) {
				return true;
			}
			if (role.showDuringAuction) {
				return false;
			}
			return true;
		});
	},

	getRolesAreVisible: (state) => {
		return state.rolesAreVisible;
	},

	getDealer: (state, getters) => {
		if (!getters.getGameData ||
				!getters.getGameData.dealer) {
			return false;
		}
		return getters.getGameData.dealer;
	},

	getIsDealerByPosition: (state, getters) => ({ position }) => {
		return getters.getDealer === position;
	},

	getDeclarer: (state, getters) => {
		if (!getters.getContract) {
			return false;
		}
		return getters.getGameData.declarer;
	},

	getIsDeclarerByPosition: (state, getters) => ({ position }) => {
		return getters.getDeclarer === position;
	},

	getDummy: (state, getters) => {
		if (!getters.getGameData ||
				!getters.getGameData.dummy) {
			return false;
		}
		return getters.getGameData.dummy;
	},

	getIsDummyByPosition: (state, getters) => ({ position }) => {
		return getters.getDummy === position;
	},

	getDummyLayoutByPosition: (state, getters) => ({ position }) => {
		if (!getters.getIsDummyLayoutByPosition({ position })) {
			return false;
		}
		const tablePosition = getters.getTablePositionByPosition({ position });
		const lengthOfLongestSuit = getters.getLengthOfLongestSuitByPosition({
			position
		});
		if (tablePosition === 'right' ||
				tablePosition === 'left') {
			return 'columns';
		}
		if (lengthOfLongestSuit >= 9) {
			return 'rows';
		}
		return 'columns';
	},

	getIsDummyLayoutByPosition: (state, getters) => ({ position }) => {
		if (!getters.getOpeningLeadHasBeenPlayed) {
			return false;
		}
		return getters.getIsDummyByPosition({ position });
	},

	getOpener: (state, getters) => {
		if (!getters.getOpeningBid) {
			return false;
		}
		return getters.getOpeningBid.player;
	},

	getIsOpenerByPosition: (state, getters) => ({ position }) => {
		return getters.getOpener === position;
	},

	getOpenersPartnership: (state, getters) => {
		if (!getters.getOpener) {
			return false;
		}
		return partnerships.find((partnership) => {
			return partnership.includes(getters.getOpener);
		});
	},

	getResponder: (state, getters) => {
		if (!getters.getOpenersPartnership) {
			return false;
		}
		return getters.getOpenersPartnership.find((position) => {
			return position !== getters.getOpener;
		});
	},

	getIsResponderByPosition: (state, getters) => ({ position }) => {
		return getters.getResponder === position;
	},

	getOvercallers: (state, getters) => {
		if (!getters.getOpener) {
			return false;
		}
		return partnerships.find((partnership) => {
			return !partnership.includes(getters.getOpener);
		});
	},

	getIsOvercallerByPosition: (state, getters) => ({ position }) => {
		if (!getters.getOvercallers) {
			return false;
		}
		return getters.getOvercallers.includes(position);
	},

	getDefenders: (state, getters) => {
		if (!getters.getDeclarer) {
			return false;
		}
		return partnerships.find((partnership) => {
			return !partnership.includes(getters.getDeclarer);
		});
	},

	getIsDefenderByPosition: (state, getters) => ({ position }) => {
		if (!getters.getDefenders) {
			return false;
		}
		return getters.getDefenders.includes(position);
	},

	getIsVulnerableByPosition: (state, getters) => ({ position }) => {
		if (!getters.getGameData ||
				!getters.getGameData.vulnerable) {
			return false;
		}
		if (getters.getGameData.vulnerable === 'None') {
			return false;
		}
		if (getters.getGameData.vulnerable === 'All') {
			return true;
		}
		if (!getters.getGameData.vulnerable.includes(position)) {
			return false;
		}
		return true;
	},

	getPartnershipByPosition: (state, getters) => ({ position }) => {
		return SEATS[position].partner;
	},

	getChangePlayerPositionNotificationIsVisible: (state) => {
		return state.changePlayerPositionNotificationIsVisible;
	},

	getOriginalPlayerPosition: (state, getters) => {
		const sessionGameData = getters.getSessionGameData;
		const currentGameData = sessionGameData[getters.getCurrentGameStateIndex];
		return currentGameData?.playerPosition;
	}

};
