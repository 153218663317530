import controls      from './controls';
import undo          from './undo';
import table         from './table';
import turning       from './turning';
import hiding        from './hiding';
import highlighting  from './highlighting';
import roles         from './roles';
import dds           from './dds';
import remote        from './remote';

export default {

	...controls,
	...undo,
	...table,
	...turning,
	...hiding,
	...highlighting,
	...roles,
	...dds,
	...remote

};
