import dayjs           from 'dayjs';
import tz              from 'dayjs/plugin/timezone';
import utc             from 'dayjs/plugin/utc';
import advancedFormat  from 'dayjs/plugin/advancedFormat';
dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(advancedFormat);

export default {

	getIsAuthenticated: (state) => {
		if (!state.token ||
				!state.token.length) {
			return false;
		}
		return true;
	},

	getProfile: (state) => {
		return state.profile;
	},

	getIsTeacher: (state) => {
		if (!state.profile ||
				!state.profile.isTeacher) {
			return false;
		}
		return true;
	},

	getPayoutIsAllowed: (state) => {
		return state?.profile?.allowPayout;
	},

	getTeacherId: (state) => {
		if (!state.profile ||
			!state.profile.teacherProfileId) {
			return false;
		}
		return state.profile.teacherProfileId;
	},

	getFullName: (state) => {
		if (!state?.profile?.firstName ||
				!state?.profile?.lastName) {
			return false;
		}
		return `${state.profile.firstName} ${state.profile.lastName}`;
	},

	getIsAdmin: (state) => {
		if (!state.profile ||
				!state.profile.isAdmin) {
			return false;
		}
		return true;
	},

	getRefreshIsInProgress: (state) => {
		if (!('tokenRefreshInProgress' in state)) {
			return false;
		}
		return state.tokenRefreshInProgress;
	},

	getTokenRefreshAfter: (state) => {
		if (!('refreshAfter' in state)) {
			return false;
		}
		return (state.refreshAfter) ? new Date(state.refreshAfter) : false;
	},

	getToken: (state, getters) => {
		if (!getters.getIsAuthenticated) {
			return false;
		}
		return state.token;
	},

	getIsVerified: (state) => {
		return state.profile.verified;
	},

	getVerifyDate: (state) => {
		return state.profile.verifiedDate;
	},

	getVerifyTokenCreatedDate: (state) => {
		return state.profile.verifyTokenCreated;
	},

	getCanPublishCourses: (state) => {
		return (state.profile.allowPayout === false) || (state.profile.allowPayout && state.profile.stripeLinked);
	},

	getUsersDefaultExperienceLevel: (state) => {
		return state?.profile?.experienceLevel;
	},

	getUsersDefaultBiddingSystem: (state) => {
		return state?.profile?.biddingSystem;
	},

	getUsersTimezone: (state) => {
		return state.profile.timezone;
	},

	getTimeInLocalTimezone: (state) => ({ time }) => {
		const tz = state.profile.timezone;
		return dayjs(time).tz(tz).format('h:mma on Do MMM ’YY ([GMT] Z)');
	},

	getISOTimeString: () => ({ time }) => {
		return dayjs(time).utc().format();
	}
};
