export default {

	getHighlightingModeIsEnabled: (state, getters) => {
		return state.highlightingModeIsEnabled;
	},

	getCardsAreHighlighted: (state, getters) => {
		if (!state.highlightedCards ||
				!state.highlightedCards.length) {
			return false;
		}
		return true;
	},

	getCardIsHighlightedByCardName: (state, getters) => ({ cardName }) => {
		return state.highlightedCards.includes(cardName);
	},

	getHighlightedCardsInSuit: (state, getters) => ({ suit }) => {
		if (!getters.getCardsAreHighlighted) {
			return false;
		}
		return state.highlightedCards.filter((cardName) => {
			return cardName.includes(suit);
		});
	},

	getCardsAreHighlightedBySuit: (state, getters) => ({ suit }) => {
		if (!getters.getCardsAreHighlighted) {
			return false;
		}
		return getters.getHighlightedCardsInSuit({ suit }).length > 0;
	},

	getSpadesAreHighlighted: (state, getters) => {
		return getters.getCardsAreHighlightedBySuit({ suit: 'S' });
	},

	getHeartsAreHighlighted: (state, getters) => {
		return getters.getCardsAreHighlightedBySuit({ suit: 'H' });
	},

	getDiamondsAreHighlighted: (state, getters) => {
		return getters.getCardsAreHighlightedBySuit({ suit: 'D' });
	},

	getClubsAreHighlighted: (state, getters) => {
		return getters.getCardsAreHighlightedBySuit({ suit: 'C' });
	}

};
