export default {
	title: undefined,
	question: undefined,
	hint: undefined,
	mustChooseAllCorrectAnswers: false,
	explanation: undefined,
	diagrams: [],
	diagramInModal: undefined,
	selectedAnswers: [],
	correctAnswersAreHighlighted: false,

	diagramTypes: [
		{
			name: 'Hand',
			type: 'Hand',
			contexts: [
				'question',
				'answer'
			]
		},
		{
			name: 'Bidding table',
			type: 'BiddingTable',
			contexts: [
				'question',
				'answer'
			]
		},
		{
			name: 'Deal matrix',
			type: 'DealMatrix',
			contexts: [
				'question',
				'answer'
			]
		},
		{
			name: 'Bid box',
			type: 'BiddingInterface',
			contexts: [
				'answer'
			]
		},
		{
			name: 'Text answer',
			type: 'TextAnswer',
			contexts: [
				'answer'
			]
		}
	]

};
