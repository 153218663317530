export default {

	setCurrentPage (state, { currentPage }) {
		state.currentPage = currentPage;
	},

	clearCurrentPage (state) {
		state.currentPage = 1;
	},

	setNumPages (state, { numPages }) {
		state.numPages = numPages;
	},

	clearNumPages (state) {
		state.numPages = 0;
	},

	setTotalItems (state, { totalItems }) {
		state.totalItems = totalItems;
	},

	clearTotalItems (state) {
		state.totalItems = 0;
	}

};
