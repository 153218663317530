export default {
	profileMenuIsVisible: false,
	drawerIsVisible: false,
	notification: '',
	loadingIsVisible: false,
	errorIsVisible: false,
	breadcrumbs: undefined,
	tourSteps: [],
	seenTourSteps: []
};
