export default {

	getScoreByPartnership: (state, getters) => ({ partnership }) => {
		if (!getters.getAuctionIsComplete) {
			return false;
		}
		return getters.getGameData.score[partnership];
	},

	getPartnershipIsHorizontalByPartnership: (state, getters) => ({ partnership }) => {
		const partners = partnership.replace('/', '');
		console.log('Partners', partners);
		const partnerOneIsTop = (getters.getTablePositionByPosition({
			position: partners[0]
		}) === 'top');
		const partnerTwoIsTop = (getters.getTablePositionByPosition({
			position: partners[1]
		}) === 'top');
		if (partnerOneIsTop ||
				partnerTwoIsTop) {
			return false;
		}
		return true;
	},

	getPartnershipIsVerticalByPartnership: (state, getters) => ({ partnership }) => {
		const partners = partnership.replace('/', '');
		console.log('Partners', partners);
		const partnerOneIsTop = (getters.getTablePositionByPosition({
			position: partners[0]
		}) === 'top');
		const partnerTwoIsTop = (getters.getTablePositionByPosition({
			position: partners[1]
		}) === 'top');
		if (!partnerOneIsTop &&
				!partnerTwoIsTop) {
			console.log(partnership, 'does not include any at top');
			return false;
		}
		console.log(partnership, 'does include any at top');
		return true;
	}

};
