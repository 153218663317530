import { get }      from '@/services/api/methods';
import { post }     from '@/services/api/methods';
import { put }      from '@/services/api/methods';
import { del }      from '@/services/api/methods';

export default {

	// courses

	createCourse: ({ course }) => {
		return post('/teacher/courses', {
			requestData: course
		});
	},

	updateCourseById: ({ course, courseId }) => {
		return put(`/teacher/courses/${courseId}`, {
			requestData: course
		});
	},

	getCourses: ({
		include,
		searchString,
		page,
		per
	}) => {
		return get('/teacher/courses', {
			options: {
				include,
				searchString,
				page,
				per
			}
		});
	},

	getCourseById: ({ courseId }) => {
		return get(`/teacher/courses/${courseId}`);
	},

	duplicateCourseById: ({ courseId, convertTo }) => {
		return post(`/teacher/courses/${courseId}/duplicate`, {
			options: {
				convertTo
			}
		});
	},

	// course actions

	requestPublicationByCourseId: ({ courseId }) => {
		return post(`/teacher/courses/${courseId}/publish`);
	},

	unpublishByCourseId: ({ courseId }) => {
		return del(`/teacher/courses/${courseId}/publish`);
	},

	deleteByCourseId: ({ courseId }) => {
		return del(`/teacher/courses/${courseId}`);
	},

	archiveByCourseId: ({ courseId }) => {
		return post(`/teacher/courses/${courseId}/archive`);
	},

	unarchiveByCourseId: ({ courseId }) => {
		return del(`/teacher/courses/${courseId}/archive`);
	},

	// lessons

	createLessonByCourseId: ({ lesson, courseId }) => {
		return post(`/teacher/courses/${courseId}/lessons`, {
			requestData: lesson
		});
	},

	getLessonsByCourseId: ({
		courseId
	}) => {
		return get(`/teacher/courses/${courseId}/lessons`, {
		});
	},

	getLessonById: ({
		lessonId
	}) => {
		return get(`/teacher/lessons/${lessonId}`, {
		});
	},

	updateLessonById: ({ lesson, lessonId }) => {
		return put(`/teacher/lessons/${lessonId}`, {
			requestData: lesson
		});
	},

	deleteLessonById: ({ lessonId }) => {
		return del(`/teacher/lessons/${lessonId}`);
	},

	reorderLesssonsByCourseId: ({ courseId, lessons }) => {
		return put(`teacher/courses/${courseId}/lessons/order`, {
			requestData: lessons
		});
	},

	duplicateLessonById: ({ lessonId }) => {
		return post(`/teacher/lessons/${lessonId}/duplicate`);
	},

	// lesson media

	createMediaByLessonId: ({ lessonId, media }) => {
		return post(`/teacher/lessons/${lessonId}/media`, {
			requestData: media,
			isFormData: true
		});
	},

	updateMediaByLessonIdAndMediaId: ({ lessonId, mediaId, media }) => {
		return put(`/teacher/lessons/${lessonId}/media/${mediaId}`, {
			requestData: media
		});
	},

	deleteMediaByLessonIdAndMediaId: ({ lessonId, mediaId }) => {
		return del(`/teacher/lessons/${lessonId}/media/${mediaId}`, {
		});
	},

	// activities

	createActivityByLessonId: ({ lessonId, activity }) => {
		return post(`/teacher/lessons/${lessonId}/activities`, {
			requestData: activity
		});
	},

	getActivitiesByLessonId: ({ lessonId }) => {
		return get(`/teacher/lessons/${lessonId}/activities`, {
		});
	},

	getActivityByIdAndLessonId: ({ lessonId, activityId }) => {
		return get(`/teacher/lessons/${lessonId}/activities/${activityId}`, {
		});
	},

	updateActivityByIdAndLessonId: ({ activity, lessonId, activityId }) => {
		return put(`/teacher/lessons/${lessonId}/activities/${activityId}`, {
			requestData: activity
		});
	},

	deleteActivityByIdAndLessonId: ({ lessonId, activityId }) => {
		return del(`/teacher/lessons/${lessonId}/activities/${activityId}`);
	},

	reorderActivitiesByLessonId: ({ lessonId, activities }) => {
		return put(`/teacher/lessons/${lessonId}/activities/order`, {
			requestData: activities
		});
	},

	duplicateActivityByLessonAndActivityId: ({ lessonId, activityId, prefix = '', suffix = ' - copy' }) => {
		return post(`/teacher/lessons/${lessonId}/activities/${activityId}/duplicate`, {
			requestData: {
				prefix: prefix,
				suffix: suffix
			}
		});
	},

	// activity media

	getAllMediaByActivityIdandLessonId: ({ lessonId, activityId }) => {
		return get(`/teacher/lessons/${lessonId}/activities/${activityId}/media`);
	},

	getMediaByIdAndActivityIdAndLessonId: ({ activityId, lessonId, mediaId }) => {
		return get(`/teacher/lessons/${lessonId}/activities/${activityId}/media/${mediaId}`);
	},

	deleteMediaByIdAndActivityIdAndLessonId: ({ activityId, lessonId, mediaId }) => {
		return del(`/teacher/lessons/${lessonId}/activities/${activityId}/media/${mediaId}`);
	},

	createMediaByActivityIdAndLessonId: ({ activityId, lessonId, media }) => {
		return post(`/teacher/lessons/${lessonId}/activities/${activityId}/media`, {
			requestData: media,
			isFormData: true
		});
	},

	// transactions

	getTransactions: ({ teacherId, page, include, per, sortBy, sortDir }) => {
		return get('teacher/transactions', {
			options: {
				include,
				page,
				per,
				sortBy,
				sortDir
			}
		});
	},

	// students

	getAllStudents: ({
		include,
		searchString,
		page,
		per
	}) => {
		return get('/teacher/students', {
			options: {
				include,
				searchString,
				page,
				per
			}
		});
	},

	getStudentsByCourseId: ({
		courseId,
		include,
		searchString,
		page,
		per
	}) => {
		const url = `/teacher/courses/${courseId}/students`;
		return get(url, {
			options: {
				include,
				searchString,
				page,
				per
			}
		});
	},

	getStudentById: ({ studentId }) => {
		return get(`/teacher/students/${studentId}`, {
		});
	},

	// focuses

	getAllFocuses: () => {
		return get('/teacher/courses/focus');
	}

};
