import Vue                      from 'vue';
import router                   from './router';
import store                    from './store';
import App                      from './App.vue';
import VueGtag                  from 'vue-gtag';
import VueMeta                  from 'vue-meta';
import vClickOutside            from 'v-click-outside';
import VuePlyr                  from 'vue-plyr';
import                               'vue-plyr/dist/vue-plyr.css';
import PortalVue                from 'portal-vue';
import stripe                   from '@/services/stripe';
import VueTour                  from 'vue-tour';
import { ValidationObserver }   from 'vee-validate';
import { ValidationProvider }   from 'vee-validate';
import registerValidationRules  from './validationRules';
import * as Sentry              from '@sentry/vue';
import { H } 										from 'highlight.run';
import { BrowserTracing }       from '@sentry/tracing';
import VueSocketIO              from 'vue-socket.io';
import { SocketIOService }      from '@/services/socketIOService';
import AsyncComputed            from 'vue-async-computed';
import SlideUpDown              from 'vue-slide-up-down';

Vue.config.productionTip = false;

if (process.env.VUE_APP_GA_ID) {
	Vue.use(VueGtag, {
		config: {
			id: process.env.VUE_APP_GA_ID
		}
	}, router);
}

if (process.env.VUE_APP_HIGHLIGHT_PROJECT_ID) {
	H.init(process.env.VUE_APP_HIGHLIGHT_PROJECT_ID, {
		environment: process.env.VUE_APP_HIGHLIGHT_ENV,
		version: '1.44.0',
		tracingOrigins: process.env.VUE_APP_HIGHLIGHT_TRACING_ORIGINS.split(','),
		networkRecording: {
			enabled: true,
			recordHeadersAndBody: true
		}
	});
}

Vue.use(AsyncComputed);

Vue.use(VueMeta);

Vue.use(vClickOutside);

Vue.use(PortalVue);

Vue.use(VuePlyr, {
	plyr: {}
});

Vue.use(stripe);

require('vue-tour/dist/vue-tour.css');
Vue.use(VueTour);

store.subscribe((mutation) => {
	const socket = SocketIOService.getInstance();

	if (mutation.type === 'auth/setToken') {
		socket.init();
	}

	if (mutation.type === 'auth/logOut') {
		socket.disconnect();
	}
});

Vue.use(new VueSocketIO({
	debug: process.env.NODE_ENV === 'development',
	connection: SocketIOService.getInstance().init()
}));

// vee-validate
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Vue.component('slide-up-down', SlideUpDown);

registerValidationRules();

if (process.env.VUE_APP_SENTRY_DSN &&
		process.env.VUE_APP_SENTRY_TRACING_ORIGINS &&
		process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE &&
		process.env.VUE_APP_SENTRY_ENV) {
	const tracingOrigins = [
		...process.env.VUE_APP_SENTRY_TRACING_ORIGINS.split(','),
		/^\//
	];
	Sentry.init({
		Vue,
		dsn: process.env.VUE_APP_SENTRY_DSN,
		integrations: [
			new BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				tracingOrigins
			})
		],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE,
		environment: process.env.VUE_APP_SENTRY_ENV,
		release: 'bridge128-vue-app@' + process.env.VUE_APP_VERSION
	});
}

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
