export default {

	getUndoControlIsDisabled: (state, getters, rootState, rootGetters) => {
		return rootGetters['simulator/getBidHistory']?.length < 1;
	},

	getResetControlIsDisabled: (state, getters, rootState, rootGetters) => {
		return rootGetters['simulator/getBidsHaveBeenMade'] === false;
	},

	getResetControlAction: (state, getters, rootState, rootGetters) => {
		if (rootGetters['simulator/getCardsHaveBeenPlayed']) {
			return 'resetToBeginningOfTricks';
		}
		return 'resetToBeginningOfAuction';
	},

	getUndoControls: (state, getters) => {
		return [
			{
				icon: 'chevron left',
				id: 'undo',
				text: 'Undo',
				isDisabled: getters.getUndoControlIsDisabled,
				onClick: {
					type: 'dispatch',
					module: 'simulator',
					action: 'undo'
				}
			},
			{
				icon: 'chevron left double',
				id: 'resetToAuction',
				text: 'Reset',
				isDisabled: getters.getResetControlIsDisabled,
				onClick: {
					type: 'dispatch',
					module: 'simulator',
					action: getters.getResetControlAction
				}
			}
		];
	}

};
