export default {

	setBidExplanationsList (state, list) {
		state.bidExplanationsList = list;
	},

	setBidHintsList (state, list) {
		state.bidHintsList = list;
	},

	setDeclarerHint (state, hint) {
		state.declarerHint = hint;
	},

	setDeclarerHintsList (state, list) {
		state.declarerHintsList = list;
	},

	setOpeningLead (state, value) {
		state.openingLead = value;
	},

	setOpeningLeadsList (state, list) {
		state.openingLeadsList = list;
	},

	setClaimParameters (state, value) {
		state.claimParameters = value;
	},

	setClaimParametersList (state, list) {
		state.claimParametersList = list;
	},

	setClaimingTricksModalIsVisible (state, value) {
		state.claimingTricksModalIsVisible = value;
	},

	setDealResultsModalIsVisible (state, value) {
		state.dealResultsModalIsVisible = value;
	}

};
