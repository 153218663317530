export default {

	//
	// general
	//

	getGroups: (state, getters) => {
		return state.groups;
	},

	getPreviousGroups: (state, getters) => {
		return state.previousGroups;
	},

	getNextGroups: (state, getters) => {
		return state.nextGroups;
	},

	getNumberOfGroups: (state, getters) => {
		return getters.getGroups.length;
	},

	//
	// get group in groups
	//

	getGroupInGroupsByDealId: (state, getters) => ({ id }) => {
		return getters.getGroups.find((group) => {
			return group.deals.findIndex((deal) => {
				return deal.id === id;
			}) >= 0;
		});
	},

	getGroupIsInGroupsById: (state, getters) => ({ id }) => {
		const group = getters.getGroupInGroupsById({ id });
		if (!group) {
			return false;
		}
		return true;
	},

	getIndexOfGroupInGroupsByDealId: (state, getters) => ({ id }) => {
		return getters.getGroups.findIndex((group) => {
			return group.deals.findIndex((deal) => {
				return deal.id === id;
			}) >= 0;
		});
	},

	//
	// get deal in group
	//

	getIndexOfDealInGroupByDealId: (state, getters) => ({ id }) => {
		if (!getters.getGroups) {
			return false;
		}
		const groupIndex = getters.getIndexOfGroupInGroupsByDealId({
			id
		});
		return getters.getGroups[groupIndex].deals.findIndex((deal) => {
			return deal.id === id;
		});
	},

	// get deal is first deal in group by deal id
	getDealIsFirstDealInGroupByDealId: (state, getters) => ({ id }) => {
		if (!getters.getDealIsInGroupsById({ id })) {
			return false;
		}
		const dealIndex = getters.getIndexOfDealInGroupByDealId({ id });
		return dealIndex === 0;
	},

	// get deal is in first group in groups by deal id
	getDealIsInFirstGroupInGroupsByDealId: (state, getters) => ({ id }) => {
		return getters.getIndexOfGroupInGroupsByDealId({ id }) === 0;
	},

	// get deal is in last group in groups by deal id
	getDealIsInLastGroupInGroupsByDealId: (state, getters) => ({ id }) => {
		if (!getters.getDealIsInGroupsById({ id })) {
			return false;
		}
		const indexOfLastGroup = getters.getNumberOfGroups - 1;
		const indexOfGroup = getters.getIndexOfGroupInGroupsByDealId({ id });
		return indexOfLastGroup === indexOfGroup;
	},

	// get deal is last deal in group by deal id
	getDealIsLastDealInGroupByDealId: (state, getters) => ({ id }) => {
		if (!getters.getDealIsInGroupsById({ id })) {
			return false;
		}
		const group = getters.getGroupInGroupsByDealId({ id });
		if (!group) {
			return false;
		}
		const groupLength = group.deals.length;
		const dealIndex = getters.getIndexOfDealInGroupByDealId({ id });
		if (dealIndex < 0) {
			return false;
		}
		return dealIndex + 1 === groupLength;
	},

	// get id of previous deal in group
	getIdOfPreviousDealInGroupByDealId: (state, getters) => ({ id }) => {
		if (!getters.getDealIsInGroupsById({ id })) {
			return false;
		}
		if (getters.getDealIsFirstDealInGroupByDealId({ id })) {
			return false;
		}
		const group = getters.getGroupInGroupsByDealId({ id });
		const dealIndex = getters.getIndexOfDealInGroupByDealId({ id });
		return group.deals[dealIndex - 1].id;
	},

	// get id of next deal in group
	getIdOfNextDealInGroupByDealId: (state, getters) => ({ id }) => {
		if (!getters.getDealIsInGroupsById({ id })) {
			return false;
		}
		if (getters.getDealIsLastDealInGroupByDealId({ id })) {
			return false;
		}
		const group = getters.getGroupInGroupsByDealId({ id });
		const dealIndex = getters.getIndexOfDealInGroupByDealId({ id });
		return group.deals[dealIndex + 1].id;
	},

	// next and previous group

	getIdOfLastDealInPreviousGroupByDealId: (state, getters) => ({ id }) => {
		if (!getters.getDealIsInGroupsById({ id })) {
			return false;
		}
		const groupIndex = getters.getIndexOfGroupInGroupsByDealId({ id });
		if (groupIndex === 0) {
			return false;
		}
		const previousGroup = getters.getGroups[groupIndex - 1];
		const lengthOfPreviousGroup = previousGroup.deals.length;
		const lastIndexOfPreviousGroup = lengthOfPreviousGroup - 1;
		return previousGroup.deals[lastIndexOfPreviousGroup].id;
	},

	getIdOfFirstDealInNextGroupByDealId: (state, getters) => ({ id }) => {
		if (!getters.getDealIsInGroupsById({ id })) {
			return false;
		}
		const groupIndex = getters.getIndexOfGroupInGroupsByDealId({ id });
		return getters.getGroups[groupIndex + 1].deals[0].id;
	},

	//
	// get deal in groups
	//

	getDealInGroupsById: (state, getters) => ({ id }) => {
		if (!getters.getGroups) {
			return false;
		}
		return getters.getGroups.find((group) => {
			return group.deals.find((deal) => {
				return deal.id === id;
			});
		});
	},

	getDealIsInGroupsById: (state, getters) => ({ id }) => {
		if (!getters.getGroups) {
			return false;
		}
		if (!getters.getDealInGroupsById({ id })) {
			return false;
		}
		return true;
	},

	// get deal is first deal in groups
	getDealIsFirstDealInGroupsById: (state, getters) => ({ id }) => {
		if (!getters.getDealIsInGroupsById({ id })) {
			return false;
		}
		if (!getters.getDealIsFirstDealInGroupByDealId({ id })) {
			return false;
		}
		if (!getters.getDealIsInFirstGroupInGroupsByDealId({ id })) {
			return false;
		}
		return true;
	},

	// get deal is last deal in groups
	getDealIsLastDealInGroupsById: (state, getters) => ({ id }) => {
		if (!getters.getDealIsInGroupsById({ id })) {
			return false;
		}
		if (!getters.getDealIsLastDealInGroupByDealId({ id })) {
			return false;
		}
		if (!getters.getDealIsInLastGroupInGroupsByDealId({ id })) {
			return false;
		}
		return true;
	},

	// get id of previous deal in groups
	getIdOfPreviousDealInGroupsById: (state, getters) => ({ id }) => {
		if (!getters.getDealIsInGroupsById({ id })) {
			return false;
		}
		if (getters.getDealIsFirstDealInGroupsById({ id })) {
			return false;
		}
		if (getters.getDealIsFirstDealInGroupByDealId({ id })) {
			return getters.getIdOfLastDealInPreviousGroupByDealId({ id });
		}
		return getters.getIdOfPreviousDealInGroupByDealId({ id });
	},

	// get id of next deal in groups
	getIdOfNextDealInGroupsById: (state, getters) => ({ id }) => {
		if (!getters.getDealIsInGroupsById({ id })) {
			return false;
		}
		if (getters.getDealIsLastDealInGroupsById({ id })) {
			return false;
		}
		if (getters.getDealIsLastDealInGroupByDealId({ id })) {
			return getters.getIdOfFirstDealInNextGroupByDealId({ id });
		}
		return getters.getIdOfNextDealInGroupByDealId({ id });
	},

	//
	// previous and next groups
	//

	// get id of last deal in previous groups
	getIdOfLastDealInPreviousGroups: (state, getters) => {
		if (!getters.getPreviousGroups?.length) {
			return false;
		}
		const lastGroupIndex = getters.getPreviousGroups.length - 1;
		const lastGroup = getters.getPreviousGroups[lastGroupIndex];
		const lengthOfDeals = lastGroup.deals.length;
		const lastDealIndex = lengthOfDeals - 1;
		return lastGroup.deals[lastDealIndex].id;
	},

	// get id of first deal in next groups
	getIdOfFirstDealInNextGroups: (state, getters) => {
		if (!getters.getNextGroups?.length) {
			return false;
		}
		return getters.getNextGroups[0].deals[0].id;
	},

	//
	// whole filter query
	//

	// get deal is first deal in query
	getDealIsFirstDealInQueryById: (state, getters) => ({ id }) => {
		if (state.currentPage !== 1) {
			return false;
		}
		if (!getters.getDealIsFirstDealInGroupsById({ id })) {
			return false;
		}
		return true;
	},

	// get deal is last deal in query
	getDealIsLastDealInQueryById: (state, getters) => ({ id }) => {
		if (state.currentPage !== state.numPages) {
			return false;
		}
		if (!getters.getDealIsLastDealInGroupsById({ id })) {
			return false;
		}
		return true;
	}

};
