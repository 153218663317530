import { extend }                   from 'vee-validate';
import { email }                    from 'vee-validate/dist/rules';
import { required }                 from 'vee-validate/dist/rules';
import { min }                      from 'vee-validate/dist/rules';
import { max }                      from 'vee-validate/dist/rules';
import { size }                     from 'vee-validate/dist/rules';
import { double }                   from 'vee-validate/dist/rules';
import { between }                  from 'vee-validate/dist/rules';
import { confirmed }                from 'vee-validate/dist/rules';
import { min_value as minValue }    from 'vee-validate/dist/rules';
import { max_value as maxValue }    from 'vee-validate/dist/rules';
import { ext }                      from 'vee-validate/dist/rules';
import { cardOrder as validCards }  from '@/consts';
import { Url as URL }               from 'url';
import gameService                from '@/services/gameService';

export default function () {

	extend('required', {
		...required,
		message: '{_field_} is required'
	});

	extend('email', {
		...email,
		message: '{_field_} is not in the right format'
	});

	extend('url', {
		validate (value) {
			try {
				const url = new URL().parse(value);
				if (!(['https:', 'http:'].includes(url.protocol) && url.host)) {
					throw new Error('Must be a valid website address');
				}
			} catch (err) {
				return err.message;
			}
			return true;
		}
	});

	extend('url-regex', {
		validate (value) {
			var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
				'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
				'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
				'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
			const valid = !!pattern.test(value);
			if (!valid) {
				return 'Must be a valid website address';
			}
			return true;
		}
	});

	extend('min', {
		...min,
		message: '{_field_} must be at least {length} characters'
	});

	extend('max', {
		...max,
		message: '{_field_} must be no more than {length} characters'
	});

	extend('confirmed', {
		...confirmed,
		message: 'Does not match'
	});

	extend('minValue', {
		...minValue,
		message: '{_field_} must be at least {min}'
	});

	extend('maxValue', {
		...maxValue,
		message: '{_field_} must be less than {max}'
	});

	extend('decimal', {
		...double,
		message: 'The {_field_} must have {decimals} decimals.'
	});

	extend('between', {
		...between,
		message: 'The {_field_} must be a number between {min} and {max}.'
	});

	extend('png', {
		...ext,
		message: 'The {_field_} must be a .png image file'
	});

	extend('ppt', {
		...ext,
		message: 'The {_field_} must be a .pptx powerpoint file'
	});

	extend('size', {
		...size,
		message: 'The {_field_} must be below {size}kb'
	});

	extend('video', {
		...ext,
		message: 'The {_field_} must be a .mp4 video file'
	});

	extend('image', {
		...ext,
		message: 'The {_field_} must be a .jpeg, or .png image file'
	});

	extend('pbn', {
		...ext,
		message: 'The {_field_} must be a .pbn deal file'
	});

	extend('lessonResources', {
		...ext,
		message: 'The {_field_} must be a .pbn or .lin deal file, a .pdf, a .doc or .docx word document, a .ppt, .pptx or .pps power point file or a .mp4 video'
	});

	extend('auction', {
		validate (value, { gameIsValid, validationMessage }) {
			if (!gameIsValid) {
				return validationMessage;
			}
			return true;
		},
		params: ['gameIsValid', 'validationMessage']
	});

	extend('validCards', {
		validate (value) {
			const cards = value.split('');
			const invalidCards = cards.filter((card) => {
				return !validCards.includes(card);
			});
			if (invalidCards.length > 0) {
				return `${invalidCards[0]} is not a valid card`;
			}
			return true;
		}
	});

	extend('suitLength', {
		validate (hand1, { hand2, hand3, hand4 }) {
			const suit = [
				...(hand1 || []),
				...(hand2 || []),
				...(hand3 || []),
				...(hand4 || [])
			];
			if (suit.length > 13) {
				return 'There cannot be more than 13 cards in each suit';
			}
			return true;
		},
		params: ['hand2', 'hand3', 'hand4']
	});

	extend('handLength', {
		validate (suit1, { suit2, suit3, suit4 }) {
			const hand = [
				...(suit1 || []),
				...(suit2 || []),
				...(suit3 || []),
				...(suit4 || [])
			];
			if (hand.length > 13) {
				return 'There cannot be more than 13 cards in each hand';
			}
			return true;
		},
		params: ['suit2', 'suit3', 'suit4']
	});

	extend('suitDuplicates', {
		validate (hand1, { hand2, hand3, hand4 }) {
			const suit = [
				...(hand1?.split('') || []),
				...(hand2?.split('') || []),
				...(hand3?.split('') || []),
				...(hand4?.split('') || [])
			];
			const duplicateCards = suit.find((card, index) => {
				return suit.some((thisCard, thisIndex) => {
					if (card === thisCard &&
							index !== thisIndex) {
						return true;
					}
				});
			});
			if (duplicateCards?.length > 0) {
				return `${duplicateCards[0]} must not be in this suit more than once`;
			}
			return true;
		},
		params: ['hand2', 'hand3', 'hand4']
	});

	extend('bids', {
		async validate (bids, { type = 'multiple', dealer = undefined } = {}) {
			if (!bids?.length) {
				return 'Enter a bid';
			}
			if (type === 'single') {
				if (bids.includes(',') ||
						bids.includes(' ')) {
					return 'Enter a single bid';
				}
				if (bids === 'X' ||
						bids === 'XX') {
					return true;
				}
			}
			if (!dealer) {
				return 'Select a dealer first';
			}
			try {
				await gameService.getNewGameFromGameState({
					gameState: {
						name: 'Bidding table',
						deal: `${dealer}:`,
						auction: {
							value: dealer,
							bids: bids.split(',')
						}
					}
				});
				return true;
			} catch (error) {
				return error.message;
			}
		},
		params: ['type', 'dealer']
	});

	extend('openingLead', {
		async validate (
			openingLead,
			{
				dealer   = undefined,
				auction  = undefined,
				deal     = undefined
			} = {}
		) {
			if (openingLead.length !== 2) {
				return 'Enter a valid card';
			}
			if (!dealer) {
				return 'You can only enter an opening lead for deals which have a dealer';
			}
			if (!auction) {
				return 'You can only enter an opening lead for deals which have a complete auction';
			}
			if (!deal) {
				return 'You can only enter an opening lead for deals which have a complete deal';
			}
			try {
				const gameData = await gameService.getNewGameFromGameState({
					gameState: {
						name: 'Opening lead',
						deal,
						auction
					}
				});
				await gameService.playCardByName({
					cardName: openingLead,
					gameData
				});
				return true;
			} catch (error) {
				return error.message;
			}
		},
		params: ['dealer', 'auction', 'deal']
	});

	extend('canClaim', {
		validate (value, { isCanClaim, validationMessage }) {
			if (!isCanClaim) {
				return validationMessage;
			}
			return true;
		},
		params: ['isCanClaim', 'validationMessage']
	});

	extend('canPlayCardToTrick', {
		validate (card, { trickSuit, hand }) {
			if (card?.value) {
				const cardSuit = card?.value[1];

				if (trickSuit && cardSuit === trickSuit) {
					return true;
				}

				const suitCardsInHand = hand.filter((card) => card[1] === trickSuit);
				return suitCardsInHand.length === 0 || 'A card of this suit cannot be added';
			}
			return true;
		},
		params: ['trickSuit', 'hand']
	});

}
