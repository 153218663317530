export default {

	getDealsInActivity: (state) => {
		if (!state.activity ||
				!state.activity.length) {
			return [];
		}
		return state.activity;
	},

	getNumberOfDealsInActivity: (state, getters) => {
		return getters.getDealsInActivity.length;
	},

	getGameStatesInActivity: (state, getters) => {
		if (!getters.getDealsInActivity) {
			return false;
		}
		return getters.getDealsInActivity.map((deal) => {
			return deal.deal;
		});
	},

	getDealInActivityById: (state, getters) => ({ id }) => {
		if (!getters.getDealsInActivity) {
			return false;
		}
		return getters.getDealsInActivity.find((deal) => {
			return deal.id === id;
		});
	},

	getDealIsInActivityById: (state, getters) => ({ id }) => {
		const deal = getters.getDealInActivityById({ id });
		if (!deal) {
			return false;
		}
		return true;
	},

	getStartWithEmptyAuctionByDealId: (state, getters) => ({ id }) => {
		return getters.getDealInActivityById({ id }).deal.startWithEmptyAuction;
	}

};
