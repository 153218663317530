export default {

	setTextByUuid (state, { uuid, text }) {
		state.diagrams = state.diagrams.map((diagram) => {
			if (diagram.uuid !== uuid) {
				return diagram;
			}
			return {
				...diagram,
				config: {
					...diagram.config,
					text
				}
			};
		});
	},

	setIsCorrectByUuid (state, { uuid, isCorrect }) {
		state.diagrams = state.diagrams.map((diagram) => {
			if (diagram.uuid !== uuid) {
				return diagram;
			}
			return {
				...diagram,
				config: {
					...diagram.config,
					isCorrect
				}
			};
		});
	}

};
