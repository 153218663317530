export default {

	clearSimulator ({ commit }) {
		commit('clearCurrentGameData');
		commit('clearGameStates');
		commit('setDDSScoresAreNotVisible');
		commit('resetCurrentGameStateIndex');
		commit('clearAllHiddenCards');
		commit('clearAllHighlightedCards');
		commit('setHighlightingModeIsDisabled');
		// TODO: Set player table position based on data
		commit('resetTablePositions');
		commit('turnAllCardsFaceUp');
		commit('setDDSScoresAreNotVisible');
	}

};
