import { get }      from '@/services/api/methods';
import { post }     from '@/services/api/methods';
import { put }      from '@/services/api/methods';
import { del }      from '@/services/api/methods';

export default {

	getPublishedCourses: ({
		include,
		searchString,
		filters,
		page,
		per,
		sortBy,
		sortDir
	}) => {
		return get('/courses', {
			options: {
				include,
				searchString,
				filters,
				page,
				per,
				sortBy,
				sortDir
			}
		});
	},

	getPublishedPublicCourses: ({
		include,
		searchString,
		filters,
		page,
		per,
		sortBy,
		sortDir
	}) => {
		return get('/browse/courses', {
			options: {
				include,
				searchString,
				filters,
				page,
				per,
				sortBy,
				sortDir
			}
		});
	},

	getPublishedCourseById: ({ courseId }) => {
		return get(`/courses/${courseId}`);
	},

	joinCourseById: ({ courseId }) => {
		return post(`/courses/${courseId}/checkout`);
	},

	getCourseById: ({ courseId }) => {
		return get(`/user/courses/${courseId}`);
	},

	getCurrentUsersCourses: ({
		include,
		searchString,
		page,
		per
	}) => {
		return get('/user/courses', {
			options: {
				include,
				searchString,
				page,
				per
			}
		});
	},

	getEnrolmentById: ({ enrolmentId }) => {
		return get(`/user/enrolments/${enrolmentId}`);
	},

	getLessonsByCourseId: ({ courseId }) => {
		return get(`/courses/${courseId}/lessons`);
	},

	getLessonById: ({ lessonId }) => {
		return get(`/lessons/${lessonId}`);
	},

	getActivitiesByLessonId: ({ lessonId }) => {
		return get(`/lessons/${lessonId}/activities`);
	},

	addNotificationsByCourseId: ({ courseId }) => {
		return post(`/user/courses/${courseId}/notifications`);
	},

	removeNotificationsByCourseId: ({ courseId }) => {
		return del(`/user/courses/${courseId}/notifications`);
	},

	getActivityByIdAndLessonId: ({ lessonId, activityId }) => {
		return get(`/lessons/${lessonId}/activities/${activityId}`);
	},

	setActivityProgressByIdAndLessonId: ({ progress, lessonId, activityId }) => {
		return put(`/lessons/${lessonId}/activities/${activityId}/progress`, {
			requestData: {
				progress: progress
			}
		});
	},

	setCourseProgressByCourseId: ({ progress, courseId }) => {
		return put(`/user/courses/${courseId}/progress`, {
			requestData: {
				progress
			}
		});
	}

};
