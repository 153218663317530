<template>
	<component
		data-component="btn"
		:is="getComponent"
		v-bind="getProps"
		@click="click"
		:data-size="size"
		:data-inline="inline"
		:data-ghost="ghost"
		:data-colour="colour"
		:data-block="block"
		:data-waiting="getIsWaiting"
		:data-inactive="getIsInactive"
		:data-disabled="getIsDisabled"
	>
		<slot name="start" />
		{{ text }}
		<icon
			data-element="spinner"
			v-if="getIsWaiting"
			icon="spinner"
			:colour="getSpinnerColour"
		/>
	</component>
</template>

<script>

	import Icon    from '@/components/ui/Icon';
	import action  from '@/mixins/action';

	export default {
		components: {
			Icon
		},
		mixins: [action],
		props: {
			text: {
				type: String,
				default: ''
			},
			size: {
				type: [String, Boolean],
				default: false
			},
			inline: {
				type: Boolean,
				default: false
			},
			colour: {
				type: String,
				default: 'blue'
			},
			block: {
				type: Boolean,
				default: false
			},
			centred: {
				type: Boolean,
				default: false
			},
			ghost: {
				type: Boolean,
				default: false
			},
			isWaiting: {
				type: String,
				default: undefined
			},
			isDisabled: {
				type: [String, undefined],
				default: undefined
			},
			btnId: {
				type: String,
				default: undefined
			}
		},
		computed: {
			getIsWaiting () {
				if (!this.isWaiting ||
						!this.btnId) {
					return false;
				}
				return this.isWaiting === this.btnId;
			},
			getIsInactive () {
				if (!this.isWaiting) {
					return false;
				}
				return this.isWaiting !== this.btnId;
			},
			getIsDisabled () {
				if (!this.isDisabled ||
					!this.btnId) {
					return false;
				}
				return this.isDisabled === this.btnId;
			},
			getSpinnerColour () {
				if (this.colour === 'white') {
					return 'blue';
				}
				return 'white';
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='btn'] {
		display:flex;
		align-items:center;
		flex-shrink:0;
		width:auto;
		height:rem(40);
		padding:0 rem(16);
		font-size:rem(18);
		line-height:1;
		font-weight:700;
		color:$c-white;
		background-color:$c-brand-blue;
		border:none;
		border-radius:4px;
		user-select:none;
		cursor:pointer;
		transition:all 0.05s ease-in;
		[data-element='spinner'] {
			width:rem(22);
			height:auto;
			margin:auto 0 auto rem(6);
			cursor:pointer;
			animation:rotate 2s linear infinite;
			@keyframes rotate {
				0%   { transform:rotate(0deg) }
				100% { transform:rotate(360deg) }
			}
		}
		&[data-waiting] {
			pointer-events:none;
			cursor:default;
		}
		&[data-colour='grey'] {
			background-color:$c-brand-grey;
		}
		&[data-colour='red'] {
			background-color:$c-brand-red;
		}
		&[data-colour='blue-lighter-1'] {
			background-color:$c-brand-blue-lighter-1;
		}
		&[data-colour='blue-lighter-2'] {
			background-color:$c-brand-blue-lighter-2;
		}
		&[data-colour='white'] {
			background-color:$c-brand-white;
			color:$c-brand-blue;
		}
		&[data-inactive],
		&[data-disabled] {
			opacity:0.8;
			pointer-events:none;
			cursor:default;
			background-color:$c-brand-grey-lighter-1;
		}
		&[data-inline] {
			background-color:transparent;
		}
		&[data-block] {
			width:100%;
		}
		&[data-ghost] {
			background-color:transparent;
			border:3px solid $c-brand-blue;
			color:$c-brand-blue;
			&:hover {
				background-color:$c-brand-blue;
				color:$c-white;
			}
			&[data-colour='grey'] {
				border-color:$c-brand-grey;
				color:$c-brand-grey;
				&:hover {
					background-color:$c-brand-grey;
					color:$c-white;
				}
			}
		}
		&[data-size='xs'] {
			// used for add/remove link buttons
			// in teacher profile
			height:rem(28);
			padding:0 rem(10);
			font-size:rem(11);
			[data-element='spinner'] {
				width:rem(16);
				margin:auto 0 auto rem(2);
			}
		}
		&[data-size='sm'] {
			// used for action groups
			height:rem(30);
			padding:0 rem(12);
			font-size:rem(14);
			[data-element='spinner'] {
				width:rem(16);
				margin:auto 0 auto rem(4);
			}
		}
		&[data-size='lg'] {
			// used for action lists
			height:rem(44);
			padding:0 rem(20);
			font-size:rem(16);
			border-radius:8px;
			[data-element='spinner'] {
				width:rem(24);
				margin:auto 0 auto rem(8);
			}
		}
		&[data-size='xl'] {
			// used for main cta on course and lesson view
			height:rem(50);
			padding:0 rem(24);
			font-size:rem(20);
			border-radius:8px;
			[data-element='spinner'] {
				width:rem(28);
				margin:auto 0 auto rem(10);
			}
		}
  }

</style>
