// import gameService from '@/lib/gameService';

import { SIMULATOR_MODES } from '@/consts';

export default {

	getTurningButtonIsVisibleForMode: () => (mode) => {
		return [
			SIMULATOR_MODES.PREVIEW
		].includes(mode);
	},

	getCardsAreFaceDown: (state, getters) => {
		if (!state.faceDownCards ||
				!state.faceDownCards.length) {
			return false;
		}
		return true;
	},

	getAllCardsAreFaceDown: (state, getters) => {
		if (!getters.getCardsAreFaceDown) {
			return false;
		}
		return state.faceDownCards.length >= 52;
	},

	getCardIsFaceDownByCardName: (state, getters) => ({ cardName }) => {
		return state.faceDownCards.includes(cardName);
	},

	getFaceDownCardsInSuit: (state, getters) => ({ suit }) => {
		if (!getters.getCardsAreFaceDown) {
			return false;
		}
		return state.faceDownCards.filter((cardName) => {
			return cardName.includes(suit);
		});
	},

	getHandIsFaceDownByPosition: (state, getters) => ({ position }) => {
		const cards = getters.getUnplayedCardsByPosition({
			position
		});
		return cards.map((card) => {
			return card.name;
		}).map((cardName) => {
			return getters.getCardIsFaceDownByCardName({ cardName });
		}).every((isFaceDown) => {
			return isFaceDown === true;
		});
	},

	getCardsAreFaceDownBySuit: (state, getters) => ({ suit }) => {
		if (!getters.getCardsAreFaceDown) {
			return false;
		}
		return getters.getFaceDownCardsInSuit({ suit }).length > 0;
	},

	getSpadesAreFaceDown: (state, getters) => {
		return getters.getCardsAreFaceDownBySuit({ suit: 'S' });
	},

	getHeartsAreFaceDown: (state, getters) => {
		return getters.getCardsAreFaceDownBySuit({ suit: 'H' });
	},

	getDiamondsAreFaceDown: (state, getters) => {
		return getters.getCardsAreFaceDownBySuit({ suit: 'D' });
	},

	getClubsAreFaceDown: (state, getters) => {
		return getters.getCardsAreFaceDownBySuit({ suit: 'C' });
	}

};
