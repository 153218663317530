export default {

	setStudyModeBiddingInProgress (state, value) {
		state.studyModeBiddingInProgress = value;
	},

	setPlayerFailedAttemptToBid (state, value) {
		state.playerFailedAttemptToBid = value;
	},

	setHighlightedBid (state, bid) {
		state.highlightedBid = bid;
	}

};
