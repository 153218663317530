export default {

	setCurrentBidByUuid (state, { uuid, currentBid }) {
		state.diagrams = state.diagrams.map((diagram) => {
			if (diagram.uuid !== uuid) {
				return diagram;
			}
			return {
				...diagram,
				config: {
					...diagram.config,
					currentBid
				}
			};
		});
	},

	setAnswerValueByUuid (state, { uuid, answerValue }) {
		state.diagrams = state.diagrams.map((diagram) => {
			if (diagram.uuid !== uuid) {
				return diagram;
			}
			return {
				...diagram,
				config: {
					...diagram.config,
					answerValue
				}
			};
		});
	}

};
