export default {

	getRotateControlsAreDisabled: (state, getters, rootState, rootGetters) => {
		if (rootGetters['simulator/getGameData']) {
			return false;
		}
		return true;
	},

	getRotateControls: (state, getters) => {
		return [
			{
				icon: 'rotate-forward',
				id: 'rotateClockwise',
				text: 'Rotate',
				isDisabled: getters.getRotateControlsAreDisabled,
				onClick: {
					type: 'dispatch',
					module: 'simulator',
					action: 'rotateTablePositionsClockwise'
				}
			},
			{
				icon: 'rotate-back',
				id: 'rotateAnticlockwise',
				text: 'Rotate',
				isDisabled: getters.getRotateControlsAreDisabled,
				onClick: {
					type: 'dispatch',
					module: 'simulator',
					action: 'rotateTablePositionsAnticlockwise'
				}
			}
		];
	}

};
