import { paramCase }  from 'change-case';

export default {

	getContext: (state) => {
		return state.context;
	},

	getCourseId: (state) => {
		return state?.courseId;
	},

	getLesson: (state) => {
		return state.lesson;
	},

	getLessonId: (state, getters) => {
		return getters.getLesson?.id;
	},

	getCourseTitle: (state, getters) => {
		return getters.getLesson?.courseTitle;
	},

	getLessonTitle: (state, getters) => {
		return getters.getLesson?.title;
	},

	getLessonIsLive: (state, getters) => {
		return getters.getLesson?.live;
	},

	getContextSlug: (state, getters) => {
		if (!getters.getContext) {
			return undefined;
		}
		return paramCase(getters.getContext);
	},

	getCourseRoute: (state, getters) => {
		const courseId = getters.getCourseId;
		if (!courseId ||
				!getters.getContext) {
			return undefined;
		}
		if (getters.getContext === 'teaching') {
			return `/teaching/courses/${courseId}`;
		}
		if (getters.getContext === 'admin') {
			return `/admin/courses/${courseId}`;
		}
		return `/my-courses/${courseId}`;
	},

	getLessonRoute: (state, getters) => {
		const courseRoute = getters.getCourseRoute;
		const lessonId = getters.getLessonId;
		if (!courseRoute ||
				!lessonId) {
			return undefined;
		}
		return `${courseRoute}/lessons/${lessonId}`;
	}

};
