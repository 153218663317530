export default {

	setActiveDeclarerHint (state, value) {
		state.activeDeclarerHint = value;
	},

	setDeclarerHintIsVisible (state, value) {
		state.declarerHintIsVisible = value;
	}

};
