import { SIMULATOR_MODES } from '@/consts';

export default {

	getHidingButtonIsVisibleForMode: () => (mode) => {
		return [
			SIMULATOR_MODES.PREVIEW
		].includes(mode);
	},

	getCardsAreHidden: (state, getters) => {
		if (!state.hiddenCards ||
				!state.hiddenCards.length) {
			return false;
		}
		return true;
	},

	getAllCardsAreHidden: (state, getters) => {
		if (!getters.getCardsAreHidden) {
			return false;
		}
		return state.hiddenCards.length >= 52;
	},

	getCardIsHiddenByCardName: (state, getters) => ({ cardName }) => {
		return state.hiddenCards.includes(cardName);
	},

	getHiddenCardsInSuit: (state, getters) => ({ suit }) => {
		if (!getters.getCardsAreHidden) {
			return false;
		}
		return state.hiddenCards.filter((cardName) => {
			return cardName.includes(suit);
		});
	},

	getHandIsHiddenByPosition: (state, getters) => ({ position }) => {
		const cards = getters.getUnplayedCardsByPosition({
			position
		});
		return cards.map((card) => {
			return card.name;
		}).map((cardName) => {
			return getters.getCardIsHiddenByCardName({ cardName });
		}).every((isHidden) => {
			return isHidden === true;
		});
	},

	getCardsAreHiddenBySuit: (state, getters) => ({ suit }) => {
		if (!getters.getCardsAreHidden) {
			return false;
		}
		return getters.getHiddenCardsInSuit({ suit }).length > 0;
	},

	getSpadesAreHidden: (state, getters) => {
		return getters.getCardsAreHiddenBySuit({ suit: 'S' });
	},

	getHeartsAreHidden: (state, getters) => {
		return getters.getCardsAreHiddenBySuit({ suit: 'H' });
	},

	getDiamondsAreHidden: (state, getters) => {
		return getters.getCardsAreHiddenBySuit({ suit: 'D' });
	},

	getClubsAreHidden: (state, getters) => {
		return getters.getCardsAreHiddenBySuit({ suit: 'C' });
	}

};
