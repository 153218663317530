export default {

	toggleLessonNavIsVisible (state) {
		state.lessonNavIsVisible = !state.lessonNavIsVisible;
	},

	setLessonNavIsHidden (state) {
		state.lessonNavIsVisible = false;
	}

};
