export default {

	getCurrentBidByUuid: (state, getters) => ({ uuid }) => {
		const diagram = getters.getDiagramByUuid({ uuid });
		return diagram?.config?.currentBid;
	},

	getAnswerValueByUuid: (state, getters) => ({ uuid, answerValue }) => {
		const diagram = getters.getDiagramByUuid({ uuid });
		return diagram?.config?.answerValue;
	}

};
