export default {

	clearQuiz ({ commit, getters  }) {
		commit('clearAllDiagrams');
		commit('clearTitle');
		commit('clearQuestion');
		commit('clearHint');
		commit('clearMustChooseAllCorrectAnswers');
		commit('clearExplanation');
		commit('clearDiagramInModal');
		commit('clearSelectedAnswers');
		commit('unhighlightCorrectAnswers');
	},

	setQuiz ({ commit }, { quiz }) {
		commit('setDiagrams', {
			diagrams: quiz?.config?.diagrams
		});
		commit('setTitle', {
			title: quiz?.title
		});
		commit('setQuestion', {
			question: quiz?.config?.question
		});
		commit('setHint', {
			hint: quiz?.config?.hint
		});
		commit('setMustChooseAllCorrectAnswers', {
			mustChooseAllCorrectAnswers: quiz?.config?.mustChooseAllCorrectAnswers
		});
		commit('setExplanation', {
			explanation: quiz?.config?.explanation
		});
	}

};
