import lesson      from './lesson';
import activities  from './activities';
import activity    from './activity';

export default {

	...lesson,
	...activities,
	...activity

};
