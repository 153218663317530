export default {

	toggleCardIsHighlighted ({ commit, getters }, { cardName }) {
		if (getters.getCardIsHighlightedByCardName({ cardName })) {
			commit('setCardIsNotHighlighted', { cardName });
			return;
		}
		commit('setCardIsHighlighted', { cardName });
	},

	setSpadesAreHighlighted ({ dispatch }) {
		dispatch('setCardsAreNotHighlightedBySuit', { suit: 'D' });
		dispatch('setCardsAreNotHighlightedBySuit', { suit: 'H' });
		dispatch('setCardsAreNotHighlightedBySuit', { suit: 'C' });
		dispatch('setCardsAreHighlightedBySuit', { suit: 'S' });
	},

	setSpadesAreNotHighlighted ({ dispatch }) {
		dispatch('setCardsAreNotHighlightedBySuit', { suit: 'S' });
	},

	setHeartsAreHighlighted ({ dispatch }) {
		dispatch('setCardsAreNotHighlightedBySuit', { suit: 'S' });
		dispatch('setCardsAreNotHighlightedBySuit', { suit: 'D' });
		dispatch('setCardsAreNotHighlightedBySuit', { suit: 'C' });
		dispatch('setCardsAreHighlightedBySuit', { suit: 'H' });
	},

	setHeartsAreNotHighlighted ({ dispatch }) {
		dispatch('setCardsAreNotHighlightedBySuit', { suit: 'H' });
	},

	setDiamondsAreHighlighted ({ dispatch }) {
		dispatch('setCardsAreNotHighlightedBySuit', { suit: 'S' });
		dispatch('setCardsAreNotHighlightedBySuit', { suit: 'H' });
		dispatch('setCardsAreNotHighlightedBySuit', { suit: 'C' });
		dispatch('setCardsAreHighlightedBySuit', { suit: 'D' });
	},

	setDiamondsAreNotHighlighted ({ dispatch }) {
		dispatch('setCardsAreNotHighlightedBySuit', { suit: 'D' });
	},

	setClubsAreHighlighted ({ dispatch }) {
		dispatch('setCardsAreNotHighlightedBySuit', { suit: 'S' });
		dispatch('setCardsAreNotHighlightedBySuit', { suit: 'H' });
		dispatch('setCardsAreNotHighlightedBySuit', { suit: 'D' });
		dispatch('setCardsAreHighlightedBySuit', { suit: 'C' });
	},

	setClubsAreNotHighlighted ({ dispatch }) {
		dispatch('setCardsAreNotHighlightedBySuit', { suit: 'C' });
	},

	setCardsAreHighlightedBySuit ({ commit, getters }, { suit }) {
		if (!getters.getDeck) {
			return false;
		}
		commit('setCardsAreHighlighted', {
			cardNames: getters.getDeck.filter((cardName) => {
				return cardName.includes(suit);
			})
		});
	},

	setCardsAreNotHighlightedBySuit ({ commit, getters }, { suit }) {
		if (!getters.getDeck) {
			return false;
		}
		commit('setCardsAreNotHighlighted', {
			cardNames: getters.getDeck.filter((cardName) => {
				return cardName.includes(suit);
			})
		});
	},

	setHighlightingModeIsEnabled ({ commit }) {
		commit('setHighlightingModeIsEnabled');
	},

	setHighlightingModeIsDisabled ({ commit }) {
		commit('setHighlightingModeIsDisabled');
		commit('clearAllHighlightedCards');
	}

};
