import ScreenRecorderService from '@/services/screenRecorderService';

const screenRecorderService = new ScreenRecorderService();

const DEFAULT_ERROR_MESSAGE =
	'Something went wrong, please try again or reach out to our support team if the issue persists.';

const handleError = (error, commit) => {
	commit(
		'ui/showNotification',
		{ notification: error?.message || DEFAULT_ERROR_MESSAGE },
		{ root: true }
	);
	console.log(error);
};

export default {

	setMimeType ({ commit }, value) {
		commit('setMimeType', value);
	},

	setMicrophonePermission ({ commit }, value) {
		commit('setMicrophonePermission', value);
	},

	showControls ({ commit }) {
		commit('setControlsVisible', true);
	},

	hideControls ({ commit }) {
		commit('setControlsVisible', false);
	},

	clearPreviewBlob ({ commit }) {
		commit('setPreviewBlob', null);
	},

	async startRecording ({ commit, getters }) {
		try {
			await screenRecorderService.startRecording({
				screen: true,
				audio: getters.getMicrophonePermission,
				mimeType: getters.getMimeType
			});
			commit('setRecordingInProgress', true);
		} catch (error) {
			handleError(error, commit);
		}
	},

	async stopRecording ({ commit }) {
		try {
			const blob = await screenRecorderService.stopRecording();
			commit('setPreviewBlob', blob);
			commit('setRecordingInProgress', false);
			commit('setRecordingPaused', false);
		} catch (error) {
			handleError(error, commit);
		}
	},

	pauseRecording ({ commit }) {
		try {
			screenRecorderService.pauseRecording();
			commit('setRecordingPaused', true);
		} catch (error) {
			handleError(error, commit);
		}
	},

	resumeRecording ({ commit }) {
		try {
			screenRecorderService.resumeRecording();
			commit('setRecordingPaused', false);
		} catch (error) {
			handleError(error, commit);
		}
	}

};
