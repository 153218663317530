import quiz               from './quiz';
import diagrams           from './diagrams';
import hands              from './hands';
import biddingTables      from './biddingTables';
import biddingInterfaces  from './biddingInterfaces';
import textAnswers        from './textAnswers';
import answers            from './answers';

export default {

	...quiz,
	...diagrams,
	...hands,
	...biddingTables,
	...biddingInterfaces,
	...textAnswers,
	...answers

};
