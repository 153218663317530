export default {

	setStudyModeTricksInProgress (state, value) {
		state.studyModeTricksInProgress = value;
	},

	setBotCards (state, cards) {
		state.botCards = cards;
	}

};
