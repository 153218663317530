export default {

	setLoading (state, value) {
		state.loading = value;
	},

	setGameMode (state, mode) {
		state.mode = mode;
	},

	setGameIds (state, ids) {
		state.gameIds = ids;
	},

	setCurrentGameData (state, { currentGameData }) {
		state.currentGameData = currentGameData;
	},

	setCurrentGameState (state, { currentGameState }) {
		state.currentGameState = currentGameState;
	},

	clearCurrentGameData (state) {
		state.currentGameData = undefined;
	},

	setGameId (state, { gameId }) {
		state.gameId = gameId;
	},

	setSessionGameData (state, data) {
		state.sessionGameData = [...state.sessionGameData, data];
	},

	clearSessionGameData (state) {
		state.sessionGameData = [];
	},

	setLessonId (state, { lessonId }) {
		state.lessonId = lessonId;
	},

	clearGameId (state) {
		state.gameId = null;
	}

};
