export default {

	setGameStates (state, { gameStates }) {
		state.gameStates = gameStates;
	},

	clearGameStates (state) {
		state.gameStates = [];
	},

	setCurrentGameStateIndex (state, { index }) {
		state.currentGameStateIndex = index;
	},

	resetCurrentGameStateIndex (state) {
		state.currentGameStateIndex = 0;
	}

};
