import { randomNumber, sleep } from '@/helpers';

export default {

	async startStudyModeBidding ({ commit, dispatch }) {
		commit('setStudyModeBiddingInProgress', true);
		dispatch('startGameBiddingLoop', { fromIndex: 0 });
	},

	async startGameBiddingLoop ({ commit, dispatch, getters }, { fromIndex }) {
		let currentIndex = fromIndex || 0;

		commit('setPlayerFailedAttemptToBid', false);

		while (getters.getGameBiddingLoopIsPossible) {
			const bid = getters.getInitialBidsList[currentIndex];
			const delay = randomNumber(10, 20) * 100; // In 1000-2000 ms
			await sleep(delay);

			if (getters.getGameData) { // Additional check
				await dispatch('addBid', { bid });
			}

			currentIndex++;
		}

		if (getters.getAuctionIsComplete) {
			commit('setStudyModeBiddingInProgress', false);
			// TODO: Decide whether we need to pause between phases
			//  or start tricks immediately after auction is complete
			await sleep(1000);
			dispatch('startStudyModeTricks');
		}
	},

	async attemptToBid ({ commit, dispatch, getters }, { bid }) {
		if (bid.bid === getters.getNextCorrectBid.bid) {
			commit('setHighlightedBid', null);
			await dispatch('addBid', { bid });

			const nextBidIndex = getters.getBidHistory.length || 0;

			dispatch('startGameBiddingLoop', { fromIndex: nextBidIndex });
		} else {
			commit('setPlayerFailedAttemptToBid', true);
		}
	},

	showHint ({ dispatch,  getters }) {
		dispatch(
			'simulatorParameters/showBidHint',
			getters.getNextCorrectBidHint,
			{ root: true }
		);
	},

	highlightCorrectBid ({ commit, getters }) {
		commit('setHighlightedBid', getters.getNextCorrectBid.bid);
	}

};
