import { SocketIOService } from '@/services/socketIOService';
import { Game } from '@bridge128/bridge128-game-logic-lib';

const socket = SocketIOService.getInstance();

const joinGame = () => {
	return socket.emitWithAck('joinGame');
};

const leaveGame = () => {
	return socket.emitWithAck('leaveGame');
};

const canClaim = ({ tricks }) => {
	return socket.emitWithAckError('canClaim', { tricks });
};

const claimTricks = ({ tricks }) => {
	return socket.emitWithAckError('claimTricks', { tricks });
};

const playCardFromDDS = () => {
	return socket.emitWithAckError('playCardFromDDS');
};

const getNewGameFromGameState = ({ gameState }) => {
	return socket.emitWithAckError('getNewGameFromGameState', { gameState });
};

const getGameInstanceFromGameState = ({ gameState }) => {
	const newGame = Game.fromState(gameState);
	if (!newGame) {
		return false;
	}
	return newGame;
};

const playCardByName = ({ cardName, gameData }) => {
	return socket.emitWithAckError('playCardByName', { cardName, gameData });
};

const addBidByName = ({ bidName, gameData }) => {
	return socket.emitWithAckError('addBidByName', { bidName, gameData });
};

const undo = ({ gameData }) => {
	return socket.emitWithAckError('undo', { gameData });
};

const undoCard = ({ gameData }) => {
	return socket.emitWithAckError('undoCard', { gameData });
};

const undoTrick = ({ gameData }) => {
	return socket.emitWithAckError('undoTrick', { gameData });
};

const resetToBeginningOfAuction = ({ gameData }) => {
	return socket.emitWithAckError('resetToBeginningOfAuction', { gameData });
};

const resetToBeginningOfTricks = ({ gameData }) => {
	return socket.emitWithAckError('resetToBeginningOfTricks', { gameData });
};

const enableDDS = ({ gameData }) => {
	return socket.emitWithAckError('enableDDS', { gameData });
};

const disableDDS = ({ gameData }) => {
	return socket.emitWithAckError('disableDDS', { gameData });
};

export default {
	joinGame,
	leaveGame,
	canClaim,
	claimTricks,
	playCardFromDDS,
	getNewGameFromGameState,
	getGameInstanceFromGameState,
	playCardByName,
	addBidByName,
	undo,
	undoCard,
	undoTrick,
	resetToBeginningOfAuction,
	resetToBeginningOfTricks,
	enableDDS,
	disableDDS
};
