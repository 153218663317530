import { post } from '@/services/api/methods';
import { get }  from '@/services/api/methods';
import { put }  from '@/services/api/methods';

export default {

	createAccount: ({ profile }) => {
		return post('/auth/create', {
			requestData: profile
		});
	},

	logIn: ({ credentials }) => {
		return post('/auth/login', {
			requestData: credentials
		});
	},

	logOut: () => {
		return post('/auth/logout');
	},

	refreshAuth: () => {
		return get('/auth/refresh');
	},

	getCurrentUserProfile: () => {
		return get('/profile');
	},

	updateCurrentUserProfile: ({ profile }) => {
		return put('/profile', {
			requestData: profile
		});
	},

	updateCurrentUserPassword: ({ credentials }) => {
		return put('/auth/setpassword', {
			requestData: credentials
		});
	},

	updateCurrentUserEmail: ({ email }) => {
		return put('/auth/email', {
			requestData: { email: email }
		});
	},

	resetPassword: ({ credentials }) => {
		return post('/auth/reset', {
			requestData: credentials
		});
	},

	verifyPasswordResetCode: ({ code }) => {
		return post('/auth/reset/code', {
			requestData: { code }
		});
	},

	verifyEmail: () => {
		return post('/auth/verify');
	}

	// refreshToken: () => {
	// 	return post('/auth/refresh');
	// }
	//

};
