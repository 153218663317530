export default {

	setActivity (state, { deals }) {
		state.activity = deals;
	},

	clearActivity (state) {
		state.activity = [];
	},

	addDealToActivity (state, { deal }) {
		state.activity.push(deal);
	},

	removeDealFromActivityById (state, { id }) {
		state.activity = state.activity.filter((deal) => {
			return deal.id !== id;
		});
	},

	setStartWithEmptyAuctionByDealId (state, { id, value }) {
		state.activity = state.activity.map((deal) => {
			if (deal.id !== id) {
				return deal;
			}
			return {
				...deal,
				deal: {
					...deal.deal,
					startWithEmptyAuction: value
				}
			};
		});
	}

};
