import { v4 as uuid } from 'uuid';

export default {

	setDiagramInModal (state, { diagram }) {
		state.diagramInModal = diagram;
	},

	clearDiagramInModal (state) {
		state.diagramInModal = undefined;
	},

	clearAllDiagrams (state) {
		state.diagrams = [];
	},

	setDiagrams (state, { diagrams }) {
		state.diagrams = diagrams;
	},

	setDiagramByUuid (state, { uuid, diagram }) {
		state.diagrams = state.diagrams.map((thisDiagram) => {
			if (thisDiagram.uuid !== uuid) {
				return thisDiagram;
			}
			return diagram;
		});
	},

	setDiagramsByContext (state, { context, diagrams }) {
		state.diagrams = [
			...state.diagrams.filter((diagram) => {
				return diagram.context !== context;
			}),
			...diagrams
		];
	},

	addDiagramByContextAndType (state, { context, type }) {
		state.diagrams.push({
			uuid: uuid(),
			context,
			type,
			config: {}
		});
	},

	removeDiagramByUuid (state, { uuid }) {
		state.diagrams = state.diagrams.filter((diagram) => {
			return diagram.uuid !== uuid;
		});
	}

};
