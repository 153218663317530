import { experienceLevels }  from '@/consts';
import { biddingSystems   }  from '@/consts';

export default {

	getActiveFilters: (state, getters) => {
		return state.activeFilters;
	},

	getFilterOptions: (state, getters) => {
		return [
			{
				text: 'Experience level',
				value: 'experienceLevel',
				options: experienceLevels
			},
			{
				text: 'Deal name',
				value: 'name',
				options: state.nameFilters.map((name) => {
					return {
						text: name,
						value: name
					};
				})
			},
			{
				text: 'Bidding system',
				value: 'biddingSystems',
				options: biddingSystems.map((biddingSystem) => {
					return {
						text: biddingSystem.value,
						value: biddingSystem.value
					};
				})
			},
			{
				text: 'Focus',
				value: 'focus',
				options: state?.focusFilters?.map((focus) => {
					return {
						text: focus,
						value: focus
					};
				})
			},
			{
				text: 'Keyword',
				value: 'keywords',
				options: state?.keywordsFilters?.map(keyword => {
					return {
						text: keyword,
						value: keyword
					};
				})
			},
			{
				text: 'Min tricks',
				value: 'minTricks',
				options: [...Array(7).keys()].map((trick) => {
					return {
						text: trick + 7,
						value: trick + 7
					};
				})
			},
			{
				text: 'Tag',
				value: 'tags',
				options: state?.tagsFilters?.map((tag) => {
					return {
						text: tag,
						value: tag
					};
				})
			},
			{
				text: 'Classified',
				value: 'classified',
				type: 'boolean',
				default: undefined
			}
		];
	}

};
