export default {

	getActivities: (state, getters) => {
		return getters.getLesson?.activities;
	},

	getActivityById: (state, getters) => ({ activityId }) => {
		return getters.getActivities?.find((activity) => {
			return activity.id === activityId;
		});
	},

	getPreviousActivityId: (state, getters) => {
		if (getters?.getActivityIndex < 0 ||
				!getters.getActivities?.length) {
			return false;
		}
		const previousIndex = getters.getActivityIndex - 1;
		if (previousIndex < 0) {
			return false;
		}
		if (previousIndex > getters.getActivities.length) {
			return false;
		}
		return getters.getActivities?.[previousIndex]?.id;
	},

	getNextActivityId: (state, getters) => {
		if (getters?.getActivityIndex < 0 ||
				!getters.getActivities?.length) {
			return false;
		}
		const nextIndex = getters.getActivityIndex + 1;
		if (nextIndex < 0) {
			return false;
		}
		if (nextIndex > getters.getActivities.length) {
			return false;
		}
		return getters.getActivities?.[nextIndex]?.id;
	},

	getActivityRoute: (state, getters) => {
		const lessonRoute = getters.getLessonRoute;
		const activityId = getters.getActivityId;
		if (!lessonRoute ||
				!activityId) {
			return false;
		}
		if (getters.getContext === 'teaching') {
			return `${lessonRoute}/activities/${activityId}/view`;
		}
		return `${lessonRoute}/activities/${activityId}`;
	},

	getPreviousActivityRoute: (state, getters) => {
		const lessonRoute = getters.getLessonRoute;
		const activityId = getters.getPreviousActivityId;
		if (!lessonRoute ||
				!activityId) {
			return false;
		}
		if (getters.getContext === 'teaching') {
			return `${lessonRoute}/activities/${activityId}/view`;
		}
		return `${lessonRoute}/activities/${activityId}`;
	},

	getNextActivityRoute: (state, getters) => {
		const lessonRoute = getters.getLessonRoute;
		const activityId = getters.getNextActivityId;
		if (!lessonRoute ||
				!activityId) {
			return false;
		}
		if (getters.getContext === 'teaching' ||
				getters.getContext === 'admin') {
			return `${lessonRoute}/activities/${activityId}/view`;
		}
		return `${lessonRoute}/activities/${activityId}`;
	}

};
