export default {

	closeAllMenus (state) {
		state.openMenu = '';
	},

	openMenu (state, { control }) {
		state.openMenu = control.id;
	},

	setReturnRoute (state, { returnRoute }) {
		state.returnRoute = returnRoute;
	}

};
