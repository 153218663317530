export default {

	setRolesAreVisible (state) {
		state.rolesAreVisible = true;
	},

	setRolesAreNotVisible (state) {
		state.rolesAreVisible = false;
	},

	setChangePlayerPositionNotificationIsVisible (state, value) {
		state.changePlayerPositionNotificationIsVisible = value;
	}

};
