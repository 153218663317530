export default {

	setBidHistoryIsVisible (state) {
		state.bidHistoryIsVisible = true;
	},

	setBidHistoryIsNotVisible (state) {
		state.bidHistoryIsVisible = false;
	},

	setInitialBidsList (state, bids) {
		state.initialBidsList = bids;
	}

};
