import { SIMULATOR_MODES } from '@/consts';

export default {

	getControlsIsVisibleForMode: () => (mode) => {
		return [
			SIMULATOR_MODES.PREVIEW
		].includes(mode);
	},

	getGameInfoIsVisibleForMode: () => (mode) => {
		return [
			SIMULATOR_MODES.PREVIEW
		].includes(mode);
	},

	getOpenMenu: (state) => {
		if (!state.openMenu ||
				!state.openMenu.length) {
			return false;
		}
		return state.openMenu;
	},

	getControlMenuIsOpen: (state, getters) => ({ control }) => {
		if (!getters.getOpenMenu) {
			return false;
		}
		return control.id === getters.getOpenMenu;
	},

	getControlHasMenu: (state) => ({ control }) => {
		if (!control.menu ||
				!control.menu.length) {
			return false;
		}
		return true;
	},

	getSimulatorControls: (state, getters) => ({
		isRemote
	}) => {
		// TODO: The remote control for the simulator is under development and currently unavailable
		// if (!isRemote && getters.getRemoteControlWindowIsOpen) {
		// 	return [
		// 		[
		// 			getters.getRemoteControlControl
		// 		]
		// 	];
		// }
		return [
			getters.getUndoControls,
			getters.getRotateControls,
			[
				getters.getTurnControl,
				getters.getHideControl
			],
			[
				getters.getHighlightControl,
				getters.getRolesControl
			],
			[
				getters.getDDSScoresControl
				// TODO: The remote control for the simulator is under development and currently unavailable
				// getters.getRemoteControlControl
			]
		].filter(Boolean);

	}

};
