import { RecordRTCPromisesHandler } from 'recordrtc';

export const DEFAULT_MIMETYPE = 'video/webm;codecs=vp8';

const DEFAULT_EXT = 'webm';

const SCREEN_CONSTRAINTS = {
	video: true,
	audio: {
		echoCancellation: true
	},
	preferCurrentTab: true
};

const AUDIO_CONSTRAINTS = {
	video: false,
	audio: {
		echoCancellation: true
	}
};

export default class ScreenRecorderService {
	mimeType = DEFAULT_MIMETYPE;
	ext = DEFAULT_EXT;
	screen = null;
	audio = null;
	screenConstraints = SCREEN_CONSTRAINTS;
	audioConstraints = AUDIO_CONSTRAINTS;
	recorder = null;

	constructor (options = {}) {
		if (options.mimeType) {
			this.mimeType = options.mimeType;
		}

		if (options.ext) {
			this.ext = options.ext;
		}

		if (options.screenConstraints) {
			this.screenConstraints = options.screenConstraints;
		}

		if (options.audioConstraints) {
			this.audioConstraints = options.audioConstraints;
		}
	}

	isMimeTypeSupported (mimeType) {
		if (
			typeof MediaRecorder !== 'undefined' &&
			typeof MediaRecorder.isTypeSupported === 'function'
		) {
			return MediaRecorder.isTypeSupported(mimeType);
		}

		return false;
	}

	checkSupport () {
		if (!navigator.getDisplayMedia && !navigator.mediaDevices.getDisplayMedia) {
			throw new Error('Your browser does not supports screen recording.');
		} else if (!this.isMimeTypeSupported(this.mimeType)) {
			throw new Error('Your browser does not support the required MIME type.');
		}
	}

	setMimeType (mimeType) {
		this.mimeType = mimeType;
	}

	async captureScreen (constraints) {
		if (navigator.mediaDevices.getDisplayMedia) {
			return navigator.mediaDevices.getDisplayMedia(constraints);
		} else {
			return navigator.getDisplayMedia(constraints);
		}
	}

	async captureAudio (constraints) {
		return navigator.mediaDevices.getUserMedia(constraints);
	}

	stopStream (stream) {
		if (stream && stream.stop) {
			stream.stop();
			stream = null;
		}

		if (stream && stream instanceof Array) {
			stream.getTracks().forEach((track) => {
				track.stop();
			});
			stream = null;
		}
	}

	async resetRecorder () {
		if (this.recorder) {
			await this.recorder.reset();

			// clear the memory
			await this.recorder.destroy();
			this.recorder = null;
		}
	}

	async startRecording (
		options = {
			screen: true,
			audio: true,
			mimeType: null
		}
	) {
		if (this.recorder === null) {
			if (options.mimeType) {
				this.setMimeType(options.mimeType);
			}

			this.checkSupport();

			if (options.screen) {
				this.screen = await this.captureScreen(this.screenConstraints);
				// TODO: Use options
				this.screen.width = document.documentElement.clientWidth;
				this.screen.height = document.documentElement.clientHeight;
				this.screen.fullcanvas = true;
			}

			if (options.audio) {
				this.audio = await this.captureAudio(this.audioConstraints);
			}

			this.recorder = new RecordRTCPromisesHandler(
				[this.screen, this.audio].filter(Boolean),
				{
					type: 'video',
					mimeType: this.mimeType
				}
			);

			await this.recorder.startRecording();
		}
	}

	async stopRecording () {
		if (this.recorder) {
			await this.recorder.stopRecording();

			this.stopStream(this.screen);
			this.stopStream(this.audio);

			const blob = await this.recorder.getBlob();
			await this.resetRecorder();

			return blob;
		}
	}

	pauseRecording () {
		if (this.recorder) {
			this.recorder.pauseRecording();
		}
	}

	resumeRecording () {
		if (this.recorder) {
			this.recorder.resumeRecording();
		}
	}

	prepareBlob (blob, name = '', type = this.mimeType) {
		return new File([blob], name, {
			type
		});
	}

	openBlobInNewTab (blob, name = '', type = this.mimeType) {
		const file = this.prepareBlob(blob, name, type);

		window.open(URL.createObjectURL(file));
	}
}
