export default {

	getBidExplanationsByIndex: (state) => ({ index }) => {
		if (!state.bidExplanationsList[index]) {
			return [];
		}
		return state.bidExplanationsList[index];
	},

	getBidHintsByIndex: (state) => ({ index }) => {
		if (!state.bidHintsList[index]) {
			return [];
		}
		return state.bidHintsList[index];
	},

	getDeclarerHintByIndex: (state) => ({ index }) => {
		if (!state.declarerHintsList[index]) {
			return '';
		}
		return state.declarerHintsList[index];
	},

	getDeclarerHint: (state) => {
		return state.declarerHint;
	},

	getOpeningLead: (state) => {
		return state.openingLead;
	},

	getOpeningLeadByIndex: (state) => ({ index }) => {
		if (!state.openingLeadsList[index]) {
			return null;
		}
		return state.openingLeadsList[index];
	},

	getClaimParameters: (state) => {
		return state.claimParameters;
	},

	getClaimParametersByIndex: (state) => ({ index }) => {
		if (!state.claimParametersList[index]) {
			return [];
		}
		return state.claimParametersList[index];
	},

	getClaimingTricksModalIsVisible: (state) => {
		return state.claimingTricksModalIsVisible;
	},

	getDealResultsModalIsVisible: (state) => {
		return state.dealResultsModalIsVisible;
	}

};
