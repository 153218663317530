export default {

	setDDSScoresAreVisible (state) {
		state.DDSScoresAreVisible = true;
	},

	setDDSScoresAreNotVisible (state) {
		state.DDSScoresAreVisible = false;
	}

};
