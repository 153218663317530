import { pascalCase } from 'change-case';

export default {

	getHighlightControlIsDisabled: (state, getters, rootState, rootGetters) => {
		if (rootGetters['simulator/getGameData']) {
			return false;
		}
		return true;
	},

	getHighlightingModeText: (state, getters, rootState, rootGetters) => {
		if (rootGetters['simulator/getHighlightingModeIsEnabled']) {
			return 'Stop highlighting';
		}
		return 'Start highlighting';
	},

	getHighlightingModeAction: (state, getters, rootState, rootGetters) => {
		if (rootGetters['simulator/getHighlightingModeIsEnabled']) {
			return 'setHighlightingModeIsDisabled';
		}
		return 'setHighlightingModeIsEnabled';
	},

	getHighlightingModeMenuControl: (state, getters) => {
		return {
			text: getters.getHighlightingModeText,
			onClick: {
				type: 'dispatch',
				module: 'simulator',
				action: getters.getHighlightingModeAction
			}
		};
	},

	getClearHighlightedCardsMenuControl: (state, getters, rootState, rootGetters) => {
		if (!rootGetters['simulator/getCardsAreHighlighted']) {
			return false;
		}
		return {
			text: 'Clear highlighted cards',
			onClick: {
				type: 'commit',
				module: 'simulator',
				mutation: 'clearAllHighlightedCards'
			}
		};
	},

	getHighlightSuitText: (state, getters, rootState, rootGetters) => ({ suit }) => {
		const pascalSuit = pascalCase(suit);
		if (rootGetters[`simulator/get${pascalSuit}AreHighlighted`]) {
			return `Clear ${suit} highlight`;
		}
		return `Highlight ${suit}`;
	},

	getHighlightSuitAction: (state, getters, rootState, rootGetters) => ({ suit }) => {
		const pascalSuit = pascalCase(suit);
		if (rootGetters[`simulator/get${pascalSuit}AreHighlighted`]) {
			return `set${pascalSuit}AreNotHighlighted`;
		}
		return `set${pascalSuit}AreHighlighted`;
	},

	getHighlightSuitMenuControl: (state, getters) => ({ suit }) => {
		return {
			text: getters.getHighlightSuitText({ suit }),
			onClick: {
				type: 'dispatch',
				module: 'simulator',
				action: getters.getHighlightSuitAction({ suit })
			}
		};
	},

	getHighlightControl: (state, getters) => {
		return {
			icon: 'highlight',
			id: 'highlight',
			text: 'Highlight',
			isDisabled: getters.getHighlightControlIsDisabled,
			menu: [
				getters.getHighlightSuitMenuControl({ suit: 'spades' }),
				getters.getHighlightSuitMenuControl({ suit: 'hearts' }),
				getters.getHighlightSuitMenuControl({ suit: 'diamonds' }),
				getters.getHighlightSuitMenuControl({ suit: 'clubs' }),
				getters.getClearHighlightedCardsMenuControl,
				getters.getHighlightingModeMenuControl
			].filter(Boolean)
		};
	}

};
