export default {

	getTextByUuid: (state, getters) => ({ uuid }) => {
		const diagram = getters.getDiagramByUuid({ uuid });
		if (!diagram?.config?.text) {
			return '';
		}
		return diagram?.config?.text;
	},

	getIsCorrectByUuid: (state, getters) => ({ uuid }) => {
		const diagram = getters.getDiagramByUuid({ uuid });
		return diagram?.config?.isCorrect;
	}

};
