import { SIMULATOR_MODES, DEFAULT_PLAYER_POSITION } from '@/consts';

export default {
	loading: true,
	mode: SIMULATOR_MODES.PREVIEW,
	playerPosition: DEFAULT_PLAYER_POSITION,
	playersPositions: [],
	bidExplanationsList: [],
	bidHintsList: [],
	declarerHint: '',
	declarerHintsList: [],
	openingLead: null,
	openingLeadsList: [],
	currentGameData: false,
	currentGameState: null,
	gameStates: [],
	currentGameStateIndex: 0,
	hiddenCards: [],
	highlightingModeIsEnabled: false,
	highlightedCards: [],
	bidHistoryIsVisible: false,
	rolesAreVisible: false,
	DDSScoresAreVisible: false,
	faceDownCards: [],
	tablePositions: [],
	gameId: null,
	lessonId: null,
	gameIds: [],
	studyModeBiddingInProgress: false,
	playerFailedAttemptToBid: false,
	initialBidsList: [],
	highlightedBid: null,
	studyModeTricksInProgress: false,
	changePlayerPositionNotificationIsVisible: false,
	claimParameters: [],
	claimParametersList: [],
	claimingTricksModalIsVisible: false,
	dealResultsModalIsVisible: false,
	sessionGameData: [],
	botCards: []
};
