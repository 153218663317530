import api from '@/services/api';

export default {

	async setLesson ({ dispatch, getters }, { lessonId }) {
		if (getters.getContext === 'teaching') {
			await dispatch('setTeachingLesson', {
				lessonId
			});
		} else if (getters.getContext === 'admin') {
			await dispatch('setAdminLesson', {
				lessonId
			});
		} else {
			await dispatch('setStudentLesson', {
				lessonId
			});
		}
	},

	async setStudentLesson ({ commit, getters }, { lessonId }) {
		const lesson = await api.courses.getLessonById({
			lessonId
		});
		if (!lesson) {
			commit('ui/showError', null, { root: true });
			return false;
		}
		commit('setLesson', { lesson });
	},

	async setTeachingLesson ({ commit, getters }, { lessonId }) {
		const lesson = await api.teaching.getLessonById({
			lessonId
		});
		if (!lesson) {
			commit('ui/showError', null, { root: true });
			return false;
		}
		commit('setLesson', { lesson });
	},

	async setAdminLesson ({ commit, getters }, { lessonId }) {
		const lesson = await api.admin.getLessonById({
			lessonId
		});
		if (!lesson) {
			commit('ui/showError', null, { root: true });
			return false;
		}
		commit('setLesson', { lesson });
	}

};
