export default {

	// async setActivitiesByLessonId ({ commit, getters }, {
	// 	lessonId
	// }) {
	// 	const activities = await api.courses.getActivitiesByLessonId({
	// 		lessonId
	// 	});
	// 	if (!activities) {
	// 		commit('ui/showError', null, { root: true });
	// 		return false;
	// 	}
	// 	commit('setActivities', { activities });
	// }

};
