import { cardOrder }   from '@/consts';

export default {

	getHandByUuidAndPosition: (state, getters) => ({
		uuid,
		position
	}) => {
		const diagram = getters.getDiagramByUuid({ uuid });
		return diagram?.config?.[position];
	},

	getNumberOfCardsInHandByUuidAndPosition: (state, getters) => ({
		uuid,
		position
	}) => {
		const hand = getters.getHandByUuidAndPosition({
			uuid,
			position
		});
		if (!hand) {
			return false;
		}
		const cards = [];
		for (const suit in hand) {
			cards.push(...hand[suit]);
		}
		return cards.length;
	},

	getCardsByUuidAndPositionAndSuit: (state, getters) => ({
		uuid,
		position,
		suit
	}) => {
		return getters.getDiagramByUuid({
			uuid
		})?.config?.[position]?.[suit];
	},

	getFormattedCardsByUuidAndPositionAndSuit: (state, getters) => ({
		uuid,
		position,
		suit
	}) => {
		const thisSuit = getters.getCardsByUuidAndPositionAndSuit({
			uuid,
			position,
			suit
		});
		if (!thisSuit) {
			return false;
		}
		return cardOrder.filter((card) => {
			if (!thisSuit.includes(card)) {
				return false;
			}
			return card;
		});
	},

	getHideEmptySuitsByUuid: (state, getters) => ({ uuid, value }) => {
		const diagram = getters.getDiagramByUuid({ uuid });
		return diagram?.config?.hideEmptySuits;
	}

};
