export default {

	getMimeType: (state) => {
		return state.mimeType;
	},

	getMicrophonePermission: (state) => {
		return state.microphonePermission;
	},

	getControlsVisible: (state) => {
		return state.controlsVisible;
	},

	getRecordingInProgress: (state) => {
		return state.recordingInProgress;
	},

	getRecordingPaused: (state) => {
		return state.recordingPaused;
	},

	getPreviewBlob: (state) => {
		return state.previewBlob;
	}

};
