import gameService from '@/services/gameService';
import { DEFAULT_PLAYER_POSITION, SIMULATOR_MODES } from '@/consts';

export default {

	loadGameStates (
		{ commit, dispatch, getters },
		{
			mode,
			gameIds,
			gameStates,
			gameStateIndex,
			playersPositions,
			bidExplanationsList = [],
			bidHintsList = [],
			declarerHintsList = [],
			openingLeadsList = [],
			claimParametersList = []
		}
	) {
		commit('setGameIds', gameIds);
		commit('setGameStates', { gameStates });
		commit('setPlayersPositions', playersPositions);
		commit('setBidExplanationsList', bidExplanationsList);
		commit('setBidHintsList', bidHintsList);
		commit('setDeclarerHintsList', declarerHintsList);
		commit('setOpeningLeadsList', openingLeadsList);
		commit('setClaimParametersList', claimParametersList);
		commit('resetCurrentGameStateIndex');

		const index = gameStateIndex || 0;

		commit('setCurrentGameStateIndex', { index });

		dispatch('initGameFromGameState', {
			gameId: getters.getGameIdByIndex({
				index
			}),
			gameState: getters.getGameStateByIndex({
				index
			}),
			playerPosition: getters.getPlayerPositionByIndex({
				index
			}),
			bidExplanations: getters.getBidExplanationsByIndex({
				index
			}),
			bidHints: getters.getBidHintsByIndex({
				index
			}),
			declarerHint: getters.getDeclarerHintByIndex({
				index
			}),
			openingLead: getters.getOpeningLeadByIndex({
				index
			}),
			claimParameters: getters.getClaimParametersByIndex({
				index
			}),
			mode
		});
	},

	loadGameState (
		{ commit, dispatch, getters },
		{
			mode,
			gameId,
			gameState,
			playerPosition,
			bidExplanations,
			bidHints,
			declarerHint,
			openingLead,
			claimParameters
		}
	) {
		dispatch('initGameFromGameState', {
			mode,
			gameId,
			gameState,
			playerPosition,
			bidExplanations,
			bidHints,
			declarerHint,
			openingLead,
			claimParameters
		});
	},

	async initGameFromGameState (
		{ commit, dispatch, getters },
		{
			mode,
			gameId,
			gameState,
			playerPosition = DEFAULT_PLAYER_POSITION,
			bidExplanations = [],
			bidHints = [],
			declarerHint = '',
			openingLead = null,
			claimParameters = []
		}
	) {
		try {
			// TODO: Decide do we need to show loader before every Game start
			commit('setLoading', true);

			// TODO: Do we need to leave and join for every game? Does it really make a difference?
			await dispatch('leaveGame');

			commit('setGameId', { gameId });
			commit('setSessionGameData', {
				gameId,
				playerPosition
			});
			commit('setGameMode', mode);
			commit('setPlayerPosition', playerPosition);
			commit('simulatorParameters/setBidExplanations', bidExplanations, { root: true });
			commit('simulatorParameters/setBidHints', bidHints, { root: true });
			commit('setDeclarerHint', declarerHint);
			commit('setOpeningLead', openingLead);
			commit('setClaimParameters', claimParameters);
			commit('setCurrentGameState', { currentGameState: gameState });

			await dispatch('joinGame');

			const gameData = await gameService.getNewGameFromGameState({ gameState });
			if (
				gameState.startWithEmptyAuction ||
				getters.getStartWithEmptyAuctionForMode(mode)
			) {
				commit('setInitialBidsList', gameData.auction.bids);
				await dispatch('resetToBeginningOfAuction', { gameData });
			} else {
				commit('setCurrentGameData', {
					currentGameData: gameData
				});
			}

			dispatch('turnHandsForMode', mode);
			dispatch('setPlayerTablePosition');
		} catch (error) {
			console.error(error);
		} finally {
			commit('setLoading', false);
		}
	},

	loadPreviousGameState ({ commit, dispatch, getters }) {
		if (!getters.getPreviousGameState) {
			return false;
		}
		dispatch('loadGameStateByIndex', {
			index: getters.getPreviousGameStateIndex
		});
	},

	loadNextGameState ({ commit, dispatch, getters }) {
		if (!getters.getNextGameState) {
			return false;
		}
		dispatch('loadGameStateByIndex', {
			index: getters.getNextGameStateIndex
		});
	},

	loadGameStateByIndex ({ commit, dispatch, getters }, { index }) {
		if (!getters.getGameStateByIndex({ index })) {
			return false;
		}
		commit('setCurrentGameStateIndex', { index });
		dispatch('initGameFromGameState', {
			gameId: getters.getGameIdByIndex({
				index
			}),
			gameState: getters.getGameStateByIndex({
				index
			}),
			playerPosition: getters.getPlayerPositionByIndex({
				index
			}),
			bidExplanations: getters.getBidExplanationsByIndex({
				index
			}),
			bidHints: getters.getBidHintsByIndex({
				index
			}),
			declarerHint: getters.getDeclarerHintByIndex({
				index
			}),
			openingLead: getters.getOpeningLeadByIndex({
				index
			}),
			claimParameters: getters.getClaimParametersByIndex({
				index
			}),
			mode: getters.getGameMode
		});
	},

	resetGameState ({ commit }) {
		commit('clearGameId');
		commit('clearSessionGameData');
		commit('clearGameStates');
		commit('setGameMode', SIMULATOR_MODES.PREVIEW);
		commit('setCurrentGameData', { currentGameData: false });
		commit('setCurrentGameState', { currentGameState: null });
		commit('setPlayerPosition', DEFAULT_PLAYER_POSITION);
		commit('setPlayersPositions', []);
		commit('setBidExplanationsList', []);
		commit('setBidHintsList', []);
		commit('setStudyModeBiddingInProgress', false);
		commit('setPlayerFailedAttemptToBid', false);
		commit('setInitialBidsList', []);
		commit('setHighlightedBid', null);
		commit('setGameIds', []);
		commit('setDeclarerHint', '');
		commit('setDeclarerHintsList', []);
		commit('setOpeningLead', null);
		commit('setOpeningLeadsList', []);
		commit('setClaimParameters', []);
		commit('setClaimParametersList', []);
		commit('resetCurrentGameStateIndex');
		commit('setBotCards', []);
	},

	loadWestPlayGame ({ dispatch }) {
		dispatch('loadGameState',  {
			gameState: {
				name: 'West play',
				deal: 'W:AK87.A.KJ74.AJ62 943.Q9852.93.K97 QJT65.K763.A52.8 2.JT4.QT86.QT543',
				auction: {
					value: 'W',
					bids: [
						'2NT',
						'P',
						'3C',
						'P',
						'3S',
						'P',
						'4NT',
						'P',
						'5H',
						'P',
						'5NT',
						'P',
						'6S',
						'P',
						'P',
						'P'
					]
				}
			}
		});
	},

	loadNorthPlayGame ({ dispatch }) {
		dispatch('loadGameState',  {
			gameState: {
				name: 'North play',
				deal: 'N:52.T9743.J85.KT4 T9873.Q52.A763.9 KQJ.86.QT92.AJ53 A64.AKJ.K4.Q8762',
				auction: {
					value: 'N',
					bids: [
						'P',
						'1NT',
						'X',
						'2D',
						'P',
						'2H',
						'P',
						'P',
						'2S',
						'P',
						'3S',
						'P',
						'4S',
						'P',
						'P',
						'P'
					]
				}
			}
		});
	},

	loadEastPlayGame ({ dispatch }) {
		dispatch('loadGameState',  {
			gameState: {
				name: 'East play',
				deal: 'E:T9873.Q52.A763.9 KQJ.86.QT92.AJ53 A64.AKJ.K4.Q8762 52.T9743.J85.KT4',
				auction: {
					value: 'E',
					bids: [
						'P',
						'1NT',
						'X',
						'2D',
						'P',
						'2H',
						'P',
						'P',
						'2S',
						'P',
						'3S',
						'P',
						'4S',
						'P',
						'P',
						'P'
					]
				}
			}
		});
	},

	loadSouthPlayGame ({ dispatch }) {
		dispatch('loadGameState',  {
			gameState: {
				name: 'South play',
				deal: 'N:A983.A54.T7432.Q 7.962.AKJ9.86543 KJ654.J73.Q5.AKJ QT2.KQT8.86.T972',
				auction: {
					value: 'N',
					bids: [
						'P',
						'P',
						'1S',
						'P',
						'3S',
						'P',
						'4S',
						'P',
						'P',
						'P'
					]
				}
			}
		});
	},

	loadSouthPlayBigDummyGame ({ dispatch }) {
		dispatch('loadGameState',  {
			gameState: {
				name: 'South play (Big dummy)',
				deal: 'N:.K5.AQJ987542.94 95..K3.AKQ876532 AQJT87643.Q8.T6. K2.AJT976432..JT',
				auction: {
					value: 'N',
					bids: [
						'P',
						'P',
						'4S',
						'P',
						'P',
						'P'
					]
				}
			}
		});
	}

};
