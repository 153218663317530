export default {

	selectAnswerByUuidAndAnswerValue ({ commit, getters }, {
		uuid,
		answerValue
	}) {
		if (getters.getAnswerIsSelectedByUuidAndAnswerValue({
			uuid,
			answerValue
		})) {
			return false;
		}
		commit('selectAnswerByUuidAndAnswerValue', {
			uuid,
			answerValue
		});
	}

};
