import gameService from '@/services/gameService';

export default {

	async joinGame () {
		await gameService.joinGame();
	},

	async leaveGame () {
		await gameService.leaveGame();
	},

	async undo ({ dispatch, commit, getters }) {
		if (!getters.getGameData ||
				!getters.getBidHistory?.length) {
			return false;
		}
		const ddsWasEnabled = getters.getDDSScoresAreVisible;
		dispatch('setDDSScoresAreNotVisible');
		const gameData = await gameService.undo({
			gameData: getters.getGameData
		});
		commit('setCurrentGameData', {
			currentGameData: gameData
		});
		if (ddsWasEnabled &&
				getters.getAuctionIsComplete) {
			dispatch('setDDSScoresAreVisible');
		}
	},

	completeGame ({ commit, getters }) {
		const gameData = getters.getGameData;
		if (gameData) {
			commit('setCurrentGameData', {
				currentGameData: {
					...gameData,
					isComplete: true
				}
			});
		}
	},

	async restartGame ({ dispatch, commit, getters }) {
		await dispatch('resetToBeginningOfAuction');
		const playerPosition = getters.getOriginalPlayerPosition || getters.getPlayerPosition;
		commit('setPlayerPosition', playerPosition);
		dispatch('turnHandsForMode', getters.getGameMode);
		dispatch('setPlayerTablePosition');
	}

};
