import { connect } from 'socket.io-client';
import store			 from '@/store';

class SocketIOService {
	static _instance = null;
	io = null;

	static getInstance () {
		if (!this._instance) {
			this._instance = new SocketIOService();
		}

		return this._instance;
	}

	get connected () {
		return !!this.io?.connected;
	}

	init () {
		if (this.connected) {
			return this.io;
		}

		this.io = connect(process.env.VUE_APP_BRIDGE128_API, {
			extraHeaders: {
				Authorization: `Bearer ${store.getters['auth/getToken']}`
			}
		});

		return this.io;
	}

	disconnect () {
		if (this.connected) {
			this.io.disconnect();
			this.io = null;
		}
	}

	async emitWithAck (event, payload = {}) {
		return this.io.emitWithAck(event, this._addPayloadMetadata(payload));
	}

	/* Expects emitWithAck() response as { error: string | null, value: any | null } */
	async emitWithAckError (event, payload = {}) {
		const resp = await this.emitWithAck(event, payload);
		if (resp?.error) {
			throw new Error(resp.error);
		}

		return resp?.value;
	}

	_addPayloadMetadata (payload) {
		return {
			...payload,
			metadata: {
				gameId: store.getters['simulator/getGameId'],
				lessonId: store.getters['simulator/getLessonId']
			}
		};
	}
}

export {
	SocketIOService
};
