export default {

	clearAllHighlightedCards (state) {
		state.highlightedCards = [];
	},

	setHighlightingModeIsEnabled (state) {
		state.highlightingModeIsEnabled = true;
	},

	setHighlightingModeIsDisabled (state) {
		state.highlightingModeIsEnabled = false;
	},

	setCardIsHighlighted (state, { cardName }) {
		state.highlightedCards.push(cardName);
	},

	setCardsAreHighlighted (state, { cardNames }) {
		state.highlightedCards = [
			...state.highlightedCards,
			...cardNames
		];
	},

	setCardsAreNotHighlighted (state, { cardNames }) {
		state.highlightedCards = state.highlightedCards.filter((thisCardName) => {
			return !cardNames.includes(thisCardName);
		});
	},

	setCardIsNotHighlighted (state, { cardName }) {
		state.highlightedCards = state.highlightedCards.filter((thisCardName) => {
			return cardName !== thisCardName;
		});
	}

};
