const defaultSuitOrder = [
	{
		fullName:  'Spades',
		colour:    'black',
		suit:      'S'
	},
	{
		fullName:  'Hearts',
		colour:    'red',
		suit:      'H'
	},
	{
		fullName:  'Clubs',
		colour:    'black',
		suit:      'C'
	},
	{
		fullName:  'Diamonds',
		colour:    'red',
		suit:      'D'
	}
];

export default {

	getDealMatrixCardsByPositionAndSuit: (state, getters) => ({
		position,
		suit
	}) => {
		const positionInitial = position[0].toUpperCase();
		const suitInitial = suit[0].toUpperCase();
		const cardsInPosition = getters.getDealMatrixCardsByPosition({
			position: positionInitial
		});
		const cardsInSuit = cardsInPosition.filter((card) => {
			return card.name[1] === suitInitial;
		}).map((card) => {
			return card.name[0];
		});
		return cardsInSuit;
	},

	getDealMatrixCardsByPosition: (state, getters) => ({
		position
	}) => {
		return getters.getUnplayedCardsByPosition({
			position
		});
	},

	getCardsByPosition: (state, getters) => ({ position }) => {
		return getters.getPlayerByPosition({ position }).hand;
	},

	getSortedCards: (state, getters) => ({ cards, suitOrder }) => {
		if (!cards?.length) {
			return false;
		}
		return suitOrder.map((suit) => {
			return cards.filter((card) => {
				return card.name.includes(suit.suit);
			});
		});
	},

	getColourOfTrumpSuit: (state, getters) => {
		if (!getters.getTrumpSuit) {
			return false;
		}
		return getters.getTrumpSuit.colour;
	},

	getTrumpSuit: (state, getters) => {
		if (!getters.getContract) {
			return false;
		}
		return defaultSuitOrder.find((suit) => {
			return getters.getContract.includes(suit.suit);
		});
	},

	getSortedCardsByPosition: (state, getters) => ({ position }) => {
		const cards = getters.getCardsByPosition({ position });
		if (!getters.getTrumpSuit) {
			// show default suit order
			return getters.getSortedCards({
				cards,
				suitOrder: defaultSuitOrder
			});
		}
		const suitOrderWithoutTrumps = defaultSuitOrder.filter((suit) => {
			return suit.suit !== getters.getTrumpSuit.suit;
		});
		const suitOrder = [
			getters.getTrumpSuit,
			suitOrderWithoutTrumps.find((suit) => {
				return suit.colour !== getters.getColourOfTrumpSuit;
			}),
			suitOrderWithoutTrumps.find((suit) => {
				return suit.colour === getters.getColourOfTrumpSuit;
			}),
			suitOrderWithoutTrumps.reverse().find((suit) => {
				return suit.colour !== getters.getColourOfTrumpSuit;
			})
		];
		return getters.getSortedCards({
			cards,
			suitOrder
		});
	},

	getUnplayedCardsByPosition: (state, getters) => ({ position }) => {
		// return getters.getSortedCardsByPosition({ position }).flat().filter((card) => {
		// 	return card.state === 'dealt';
		// });
		const sortedCards = getters.getSortedCardsByPosition({
			position
		});
		if (!sortedCards) {
			return false;
		}
		const flattenedCards = sortedCards.flat();
		const dealtCards = flattenedCards.filter((card) => {
			return card.state === 'dealt';
		});
		return dealtCards;
	},

	getUnplayedDummyCardsByPosition: (state, getters) => ({ position }) => {
		const sortedCards = getters.getSortedCardsByPosition({
			position
		});
		if (!sortedCards) {
			return false;
		}
		return sortedCards.map((suit) => {
			return suit.filter((card) => {
				return card.state === 'dealt';
			});
		});
	},

	getLengthOfLongestSuitByPosition: (state, getters) => ({ position }) => {
		const suits = getters.getSortedCardsByPosition({ position });
		const suitLengths = suits.map((suit) => {
			return suit.length;
		});
		return Math.max(...suitLengths);
	}

};
