import { get }   from '@/services/api/methods';
import { post }  from '@/services/api/methods';
import { del }   from '@/services/api/methods';
// import { put }      from '@/services/api/methods';

export default {

	getTeacherProfileById: ({ profileId }) => {
		return get(`/teacher-profiles/${profileId}`);
	},

	subscribeById: ({ profileId }) => {
		return post(`/teacher-profiles/${profileId}/subscribe`);
	},

	unsubscribeById: ({ profileId }) => {
		return del(`/teacher-profiles/${profileId}/subscribe`);
	}

};
