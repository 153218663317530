export default {

	getActiveDeclarerHint: (state) => {
		return state.activeDeclarerHint;
	},

	getDeclarerHintIsVisible: (state) => {
		return state.declarerHintIsVisible;
	}

};
