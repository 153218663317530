import { get, put } from '@/services/api/methods';

export default {

	getCurrentUserTeacherProfile: () => {
		return get('/teacher/profile');
	},

	updateCurrentUserTeacherProfile: ({ teacher }) => {
		return put('/teacher/profile', {
			requestData: teacher
		});
	},

	getTeacherBidExplanations: () => {
		return get('/teacher/profile/bid-explanations');
	},

	getTeacherBidHints: () => {
		return get('/teacher/profile/bid-hints');
	},

	getTeacherDeclarerHints: () => {
		return get('/teacher/profile/declarer-hints');
	},

	getTeacherSummaryMessages: () => {
		return get('/teacher/profile/summary-messages');
	}

};
