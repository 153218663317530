export default {

	getBidHints: (state) => {
		return state.bidHints;
	},

	getBidHintByIndex: (state) => (index) => {
		const bidHints = state.bidHints;
		return bidHints[index]?.hint;
	},

	getBidHintByBidAndPosition: (state) => ({ bid, position }) => {
		const bidHints = state.bidHints;
		const index = bidHints.findIndex(item => (
			item.bid === bid &&
			item.seat === position
		));

		if (index !== -1) {
			return bidHints[index]?.hint;
		}
		return '';
	},

	getBidHintIsVisible: (state) => {
		return state.bidHintIsVisible;
	},

	getActiveBidHint: (state) => {
		return state.activeBidHint;
	}

};
