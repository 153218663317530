import deals       from './deals';
import pagination  from './pagination';
import filters     from './filters';
import activity    from './activity';

export default {

	...deals,
	...pagination,
	...filters,
	...activity

};
