export default {

	getRemoteControlWindowIsOpen: (state) => {
		return state.remoteControlWindowIsOpen;
	},

	getRemoteControlControlText: (state, getters) => {
		if (getters.getRemoteControlWindowIsOpen) {
			return 'Close';
		}
		return 'Open';
	},

	getRemoteControlControlAction: (state, getters) => {
		if (getters.getRemoteControlWindowIsOpen) {
			return 'closeRemoteControl';
		}
		return 'openRemoteControl';
	},

	getRemoteControlControl: (state, getters) => {
		return {
			icon: 'gamepad',
			id: 'remoteControl',
			text: getters.getRemoteControlControlText,
			isDisabled: false,
			onClick: {
				type: 'dispatch',
				module: 'simulatorControls',
				action: getters.getRemoteControlControlAction
			}
		};
	}

};
