export default {

	getBidExplanations: (state) => {
		return state.bidExplanations;
	},

	getBidExplanationByIndex: (state) => (index) => {
		const bidExplanations = state.bidExplanations;
		return bidExplanations[index]?.explanation;
	},

	getBidExplanationIsVisible: (state) => {
		return state.bidExplanationIsVisible;
	},

	getActiveBidExplanation: (state) => {
		return state.activeBidExplanation;
	}

};
