export default {

	getCurrentGameStateIndex: (state) => {
		return state.currentGameStateIndex;
	},

	getGameIdByIndex: (state) => ({ index }) => {
		if (!state.gameIds[index]) {
			return false;
		}
		return state.gameIds[index];
	},

	getGameStateByIndex: (state) => ({ index }) => {
		if (!state.gameStates[index]) {
			return false;
		}
		return state.gameStates[index];
	},

	getGameStatesLength: (state) => {
		return state.gameStates?.length;
	},

	getCurrentGameStateIsFirst: (state, getters) => {
		const currentIndex = getters.getCurrentGameStateIndex;
		return currentIndex === 0;
	},

	getCurrentGameStateIsLast: (state, getters) => {
		const currentIndex = getters.getCurrentGameStateIndex;
		return currentIndex === getters.getGameStatesLength - 1;
	},

	getPreviousGameStateIndex: (state, getters) => {
		if (getters.getGameStateIsFirst) {
			return false;
		}
		return getters.getCurrentGameStateIndex - 1;
	},

	getNextGameStateIndex: (state, getters) => {
		if (getters.getGameStateIsLast) {
			return false;
		}
		return getters.getCurrentGameStateIndex + 1;
	},

	getPreviousGameState: (state, getters) => {
		if (getters.getPreviousGameStateIndex === false) {
			return false;
		}
		return getters.getGameStateByIndex({
			index: getters.getPreviousGameStateIndex
		});
	},

	getNextGameState: (state, getters) => {
		if (getters.getNextGameStateIndex === false) {
			return false;
		}
		return getters.getGameStateByIndex({
			index: getters.getNextGameStateIndex
		});
	}

};
