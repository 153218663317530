export default {

	selectAnswerByUuidAndAnswerValue (state, {
		uuid,
		answerValue
	}) {
		state.selectedAnswers.push({
			uuid: uuid,
			answerValue: answerValue
		});
	},

	clearSelectedAnswers (state) {
		state.selectedAnswers = [];
	},

	highlightCorrectAnswers (state) {
		state.correctAnswersAreHighlighted = true;
	},

	unhighlightCorrectAnswers (state) {
		state.correctAnswersAreHighlighted = false;
	}

};
