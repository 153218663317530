export default {

	setDiagramInModalByUuid ({ commit, getters  }, { uuid }) {
		const diagram = getters.getDiagramByUuid({ uuid });
		commit('ui/setModalIsVisible', null, { root: true });
		commit('setDiagramInModal', { diagram });
	},

	revertChangesToDiagramInModal ({ commit, getters }) {
		const uuid = getters.getDiagramInModal.uuid;
		commit('setDiagramByUuid', {
			uuid,
			diagram: getters.getDiagramInModal
		});
		commit('clearDiagramInModal');
		commit('ui/setModalIsHidden', null, { root: true });
	}

};
