export default {

	setActivity (state, { activity }) {
		state.activity = activity;
	},

	clearActivity (state) {
		state.activity = undefined;
	},

	setActivityProgress (state, { progress }) {
		state.activity = {
			progress,
			...state.activity
		};
	}

};
