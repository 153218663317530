import simulator     from './simulator';
import gameStates    from './gameStates';
import game          from './game';
import auction       from './auction';
import tricks        from './tricks';
import hands         from './hands';
import highlighting  from './highlighting';
import hiding        from './hiding';
import dds           from './dds';
import turning       from './turning';
import table         from './table';
import studyAuction  from './studyAuction';
import studyTricks   from './studyTricks';

export default {

	...simulator,
	...gameStates,
	...game,
	...auction,
	...tricks,
	...hands,
	...highlighting,
	...hiding,
	...dds,
	...turning,
	...table,
	...studyAuction,
	...studyTricks

};
