import api from '@/services/api';
import router from '@/router';

export default {

	async setGroups ({ commit, dispatch, getters, state }) {
		try {
			const response = await api.deals.getGroups({
				include: 'metadata,filename,board,dealer,name,focus,keywords,tags,bid_explanations,bid_hints,player_position',
				searchString: state.searchString,
				filters: state.activeFilters,
				page: state.currentPage,
				per: state.perPage
			});
			commit('setNumPages', {
				numPages: response.meta.numPages
			});
			commit('setTotalItems', {
				totalItems: response.meta.totalItems
			});
			commit('setFilterOptions', {
				board:       response.meta.board,
				dealer:      response.meta.dealer,
				filename:    response.meta.filename,
				focus:       response.meta.focuses,
				keywords:    response.meta.keywords,
				name:        response.meta.name,
				tags:        response.meta.tags,
				classified:  response.meta.classified
			});
			commit('setGroups', {
				groups: response.results
			});
		} catch (err) {
			console.log(err);
		}
	},

	async navigateToNextDealInQueryById ({ commit, dispatch, getters, state }, {
		id,
		dealRoute = 'summary'
	}) {
		const getDealRouteUrl = (nextId) => {
			if (dealRoute === 'edit') {
				return `/teaching/deals/${nextId}/edit`;
			}
			return `/teaching/deals/${nextId}`;
		};
		if (!id) {
			return false;
		}
		if (!getters.getDealIsLastDealInGroupsById({ id })) {
			const nextId = getters.getIdOfNextDealInGroupsById({
				id
			});
			await router.push(getDealRouteUrl(nextId));
			return;
		}
		await dispatch('setNextPage');
		const nextId = getters.getIdOfFirstDealInNextGroups;
		await router.push(getDealRouteUrl(nextId));
		commit('clearNextGroups');
		commit('setCurrentPage', {
			currentPage: state.currentPage + 1
		});
		await dispatch('setGroups');
	},

	async navigateToPreviousDealInQueryById ({ commit, dispatch, getters, state }, {
		id,
		dealRoute = 'summary'
	}) {
		const getDealRouteUrl = (nextId) => {
			if (dealRoute === 'edit') {
				return `/teaching/deals/${nextId}/edit`;
			}
			return `/teaching/deals/${nextId}`;
		};
		if (!id) {
			return false;
		}
		if (!getters.getDealIsFirstDealInGroupsById({ id })) {
			const nextId = getters.getIdOfPreviousDealInGroupsById({
				id
			});
			await router.push(getDealRouteUrl(nextId));
			return;
		}
		await dispatch('setPreviousPage');
		const nextId = getters.getIdOfLastDealInPreviousGroups;
		await router.push(getDealRouteUrl(nextId));
		commit('clearPreviousGroups');
		commit('setCurrentPage', {
			currentPage: state.currentPage - 1
		});
		await dispatch('setGroups');
	},

	async setNextPage ({ commit, dispatch, getters, state }) {
		try {
			const response = await api.deals.getGroups({
				include: 'metadata,filename,board,dealer,name,focus_keyword,tags',
				searchString: state.searchString,
				filters: state.activeFilters,
				page: state.currentPage + 1,
				per: state.perPage
			});
			commit('setNextGroups', {
				groups: response.results
			});
		} catch (err) {
			console.log(err);
		}
	},

	async setPreviousPage ({ commit, dispatch, getters, state }) {
		try {
			const response = await api.deals.getGroups({
				include: 'metadata,filename,board,dealer,name,focus_keyword,tags',
				searchString: state.searchString,
				filters: state.activeFilters,
				page: state.currentPage - 1,
				per: state.perPage
			});
			commit('setPreviousGroups', {
				groups: response.results
			});
		} catch (err) {
			console.log(err);
		}
	}

};
