export default {

	turnAllCardsFaceUp (state) {
		state.faceDownCards = [];
	},

	setCardIsFaceDown (state, { cardName }) {
		state.faceDownCards.push(cardName);
	},

	setCardsAreFaceDown (state, { cardNames }) {
		state.faceDownCards = [
			...state.faceDownCards,
			...cardNames
		];
	},

	setCardsAreFaceUp (state, { cardNames }) {
		state.faceDownCards = state.faceDownCards.filter((thisCardName) => {
			return !cardNames.includes(thisCardName);
		});
	},

	setCardIsFaceUp (state, { cardName }) {
		state.faceDownCards = state.faceDownCards.filter((thisCardName) => {
			return cardName !== thisCardName;
		});
	}

};
