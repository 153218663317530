export default {

	setBidHints (state, value) {
		state.bidHints = value;
	},

	setBidHintIsVisible (state, value) {
		state.bidHintIsVisible = value;
	},

	setActiveBidHint (state, hint) {
		state.activeBidHint = hint;
	}

};
