export default {

	setGroups (state, { groups }) {
		state.groups = groups;
	},

	clearGroups (state) {
		state.groups = [];
	},

	setNextGroups (state, { groups }) {
		state.nextGroups = groups;
	},

	clearNextGroups (state) {
		state.nextGroups = [];
	},

	setPreviousGroups (state, { groups }) {
		state.previousGroups = groups;
	},

	clearPreviousGroups (state) {
		state.previousGroups = [];
	}

};
