export default {

	addSeenTourStepByIndex ({ commit, getters }, { tourStepIndex }) {
		const tourStep = getters.getTourStepByIndex({
			tourStepIndex
		});
		commit('addSeenTourStep', { tourStep });
	}

};
