import { defaultTablePositions } from '@/consts';

export default {

	setPlayerPosition (state, position) {
		state.playerPosition = position;
	},

	setPlayersPositions (state, positions) {
		state.playersPositions = positions;
	},

	setTablePositions (state, { tablePositions }) {
		state.tablePositions = tablePositions;
	},

	resetTablePositions (state) {
		// TODO: Should be set based on state.playerPosition (shifted if needed)
		state.tablePositions = defaultTablePositions;
	}

};
