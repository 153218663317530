import { get }   from '@/services/api/methods';
import { post }  from '@/services/api/methods';

export default {

	linkAccount: () => {
		return post('/teacher/payouts/link-stripe');
	},
	getLinkedAccount: () => {
		return get('/teacher/payouts/linked-account');
	},
	getAccountDashboard: () => {
		return get('/teacher/payouts/linked-account/dashboard');
	}

};
