import store           from '@/store/index.js';

export default {

	getDiagramInModal: (state, getters) => {
		if (!state.diagramInModal) {
			return false;
		}
		return state.diagramInModal;
	},

	getDiagramIsInModalByUuid: (state, getters) => ({ uuid }) => {
		return getters.getDiagramInModal?.uuid === uuid;
	},

	getAddDiagramOptionsByContext: (state, getters) => ({ context }) => {
		return state.diagramTypes.filter((diagram) => {
			return diagram.contexts.includes(context);
		}).map((diagram) => {
			return {
				text: diagram.name,
				action: () => {
					store.commit('quiz/addDiagramByContextAndType', {
						context,
						type: diagram.type
					});
				}
			};
		});
	},

	getAllDiagrams: (state) => {
		return state.diagrams;
	},

	getDiagramsByContext: (state) => ({ context }) => {
		if (!state?.diagrams) {
			return [];
		}
		return state.diagrams.filter((diagram) => {
			return diagram.context === context;
		});
	},

	getDiagramByUuid: (state, getters) => ({ uuid }) => {
		if (!state?.diagrams) {
			return false;
		}
		return state.diagrams.find((diagram) => {
			return diagram.uuid === uuid;
		});
	}

};
