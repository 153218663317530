export default {

	getDDSScoresControlIsDisabled: (state, getters, rootState, rootGetters) => {
		if (!rootGetters['simulator/getGameData']) {
			return true;
		}
		if (!rootGetters['simulator/getAuctionIsComplete']) {
			return true;
		}
		return false;
	},

	getDDSScoresControlAction: (state, getters, rootState, rootGetters) => {
		if (rootGetters['simulator/getDDSScoresAreVisible']) {
			return 'setDDSScoresAreNotVisible';
		}
		return 'setDDSScoresAreVisible';
	},

	getDDSScoresControl: (state, getters) => {
		return {
			icon: 'equal',
			id: 'showDDS',
			text: 'DDS',
			isDisabled: getters.getDDSScoresControlIsDisabled,
			onClick: {
				type: 'dispatch',
				module: 'simulator',
				action: getters.getDDSScoresControlAction
			}
		};
	}

};
