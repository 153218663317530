export default {

	hideAllHands ({ dispatch, getters }) {
		['W', 'N', 'E', 'S'].forEach((position) => {
			dispatch('setHandIsHiddenByPosition', { position });
		});
	},

	hideAllHandsExceptSouth ({ dispatch, getters }) {
		['W', 'N', 'E'].forEach((position) => {
			dispatch('setHandIsHiddenByPosition', { position });
		});
		dispatch('setHandIsNotHiddenByPosition', { position: 'S' });
	},

	showDummy ({ dispatch, getters }) {
		['N', 'E', 'W'].forEach((position) => {
			if (!getters.getIsDummyByPosition({ position })) {
				return;
			}
			dispatch('setHandIsNotHiddenByPosition', { position });
		});
	},

	hideAllHandsExceptSouthAndDummy ({ dispatch, getters }) {
		['N', 'E', 'W'].forEach((position) => {
			if (getters.getIsDummyByPosition({ position })) {
				dispatch('setHandIsNotHiddenByPosition', { position });
				return;
			}
			dispatch('setHandIsHiddenByPosition', { position });
		});
		dispatch('setHandIsNotHiddenByPosition', { position: 'S' });
	},

	toggleCardIsHidden ({ commit, getters }, { cardName }) {
		if (getters.getCardIsHiddenByCardName({ cardName })) {
			commit('setCardIsNotHidden', { cardName });
			return;
		}
		commit('setCardIsHidden', { cardName });
	},

	setSpadesAreHidden ({ dispatch }) {
		dispatch('setCardsAreHiddenBySuit', { suit: 'S' });
	},

	setSpadesAreNotHidden ({ dispatch }) {
		dispatch('setCardsAreNotHiddenBySuit', { suit: 'S' });
	},

	setHeartsAreHidden ({ dispatch }) {
		dispatch('setCardsAreHiddenBySuit', { suit: 'H' });
	},

	setHeartsAreNotHidden ({ dispatch }) {
		dispatch('setCardsAreNotHiddenBySuit', { suit: 'H' });
	},

	setDiamondsAreHidden ({ dispatch }) {
		dispatch('setCardsAreHiddenBySuit', { suit: 'D' });
	},

	setDiamondsAreNotHidden ({ dispatch }) {
		dispatch('setCardsAreNotHiddenBySuit', { suit: 'D' });
	},

	setClubsAreHidden ({ dispatch }) {
		dispatch('setCardsAreHiddenBySuit', { suit: 'C' });
	},

	setClubsAreNotHidden ({ dispatch }) {
		dispatch('setCardsAreNotHiddenBySuit', { suit: 'C' });
	},

	setAllCardsAreHidden ({ commit, getters }) {
		if (!getters.getDeck) {
			return false;
		}
		commit('clearAllHiddenCards');
		commit('setCardsAreHidden', {
			cardNames: getters.getDeck()
		});
	},

	setAllCardsAreVisible ({ commit }) {
		commit('clearAllHiddenCards');
	},

	toggleHandIsHiddenByPosition ({ dispatch, getters }, { position }) {
		if (getters.getHandIsHiddenByPosition({ position })) {
			dispatch('setHandIsNotHiddenByPosition', { position });
			return;
		}
		dispatch('setHandIsHiddenByPosition', { position });
	},

	setHandIsHiddenByPosition ({ commit, getters }, { position }) {
		const unplayedCards = getters.getUnplayedCardsByPosition({
			position
		});
		if (!unplayedCards) {
			return false;
		}
		const cardNames = getters.getUnplayedCardsByPosition({
			position
		}).map((card) => {
			return card.name;
		});
		commit('setCardsAreHidden', { cardNames });
	},

	setHandIsNotHiddenByPosition ({ commit, getters }, { position }) {
		const unplayedCards = getters.getUnplayedCardsByPosition({
			position
		});
		if (!unplayedCards) {
			return false;
		}
		const cardNames = getters.getUnplayedCardsByPosition({
			position
		}).map((card) => {
			return card.name;
		});
		commit('setCardsAreNotHidden', { cardNames });
	},

	setCardsAreHiddenBySuit ({ commit, getters }, { suit }) {
		if (!getters.getDeck) {
			return false;
		}
		commit('setCardsAreHidden', {
			cardNames: getters.getDeck.filter((cardName) => {
				return cardName.includes(suit);
			})
		});
	},

	setCardsAreNotHiddenBySuit ({ commit, getters }, { suit }) {
		if (!getters.getDeck) {
			return false;
		}
		commit('setCardsAreNotHidden', {
			cardNames: getters.getDeck.filter((cardName) => {
				return cardName.includes(suit);
			})
		});
	}

};
