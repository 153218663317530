import quiz      from './quiz';
import diagrams  from './diagrams';
import answers   from './answers';

export default {

	...quiz,
	...diagrams,
	...answers

};
