import store          from '@/store';
import qs             from 'qs';
import snakecaseKeys  from 'snakecase-keys';
import camelcaseKeys  from 'camelcase-keys';

const getReqUrl = ({
	url,
	options
}) => {
	if (!url &&
			!options) {
		console.log('No url, no options');
		return false;
	}
	if (!options) {
		return url;
	}
	let {
		searchString,
		filters,
		...queryObj
	} = options;
	if (options.searchString) {
		queryObj.q = options.searchString;
	}
	if (options.filters) {
		const formattedFilters = formatFilters(filters);
		queryObj = {
			...formattedFilters,
			...queryObj
		};
	}
	const query = qs.stringify(formatQuery(queryObj), {
		arrayFormat: 'comma'
	});
	return `${url}?${query}`;
};

const getErrorMessage = (error) => {
	if (!error) {
		return false;
	}
	if (typeof error === 'string') {
		return error;
	}
	if (error?.response?.data?.message) {
		return error.response.data.message;
	}
	return error.message;
};

const handleRequestError = (error) => {
	store.commit('ui/showNotification', {
		notification: getErrorMessage(error)
	}, { root: true });
};

const formatQuery = (query) => {
	if (!query) {
		return '';
	}
	return snakecaseKeys(query);
};

const formatFilters = (filters) => {
	if (!filters) {
		return false;
	}
	return snakecaseKeys(filters);
};

const formatRequestData = (requestData) => {
	if (!requestData) {
		return false;
	}
	return snakecaseKeys(requestData, { deep: true });
};

const formatResponseData = (responseData) => {
	if (!responseData) {
		return false;
	}
	return camelcaseKeys(responseData, { deep: true });
};

export default {
	getReqUrl,
	formatRequestData,
	handleRequestError,
	formatResponseData
};
