import { DEFAULT_MIMETYPE } from '@/services/screenRecorderService';

export default {
	mimeType: DEFAULT_MIMETYPE,
	microphonePermission: true,
	previewBlob: null,
	controlsVisible: false,
	recordingInProgress: false,
	recordingPaused: false
};
