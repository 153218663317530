import gameService from '@/services/gameService';

export default {

	async playCardByName ({ commit, dispatch, state, getters }, { cardName }) {
		try {
			const gameData = await gameService.playCardByName({
				cardName,
				gameData: getters.getGameData
			});
			commit('setCurrentGameData', { currentGameData: gameData });
			if (!getters.getOpeningLeadHasJustBeenPlayed) {
				return;
			}
			dispatch('turnDummyFaceUp');
		} catch (err) {
			console.log(err);
			// TODO: Add current error to state?
			// console.log(`${card.rank.rank}${card.suit.symbol} ${card.player.seat.name}: ${err.message}`);
		}
	},

	async playCardFromDDS ({ commit, dispatch, getters }) {
		try {
			const gameData = await gameService.playCardFromDDS();

			commit('setCurrentGameData', { currentGameData: gameData });
			if (!getters.getOpeningLeadHasJustBeenPlayed) {
				return;
			}
			dispatch('turnDummyFaceUp');
		} catch (err) {
			console.log(err);
		}
	},

	async undoCard ({ commit, state, getters }) {
		if (!getters.getGameData ||
				!getters.getGameData.actions ||
				!getters.getGameData.actions.length) {
			return false;
		}
		const gameData = await gameService.undoCard({
			gameData: getters.getGameData
		});
		commit('setCurrentGameData', { currentGameData: gameData });
	},

	async undoTrick ({ commit, state, getters }) {
		if (!getters.getGameData ||
				!getters.getGameData.actions ||
				!getters.getGameData.actions.length) {
			return false;
		}
		const gameData = await gameService.undoTrick({
			gameData: getters.getGameData
		});
		commit('setCurrentGameData', { currentGameData: gameData });
	},

	async resetToBeginningOfTricks ({ commit, dispatch, state, getters }) {
		if (!getters.getGameData ||
				!getters.getGameData.auction ||
				!getters.getGameData.auction.isComplete) {
			return false;
		}
		const gameData = await gameService.resetToBeginningOfTricks({
			gameData: getters.getGameData
		});
		commit('setCurrentGameData', { currentGameData: gameData });
		dispatch('turnAllCardsFaceDown');
		commit('clearAllHighlightedCards');
	},

	async claimRemainingTricks ({ commit, dispatch, state, getters }, { tricks }) {
		if (!getters.getGameData ||
			!getters.getGameData.auction ||
			!getters.getGameData.auction.isComplete) {
			return false;
		}

		const result = await gameService.claimTricks({ tricks });
		if (result) {
			commit('setCurrentGameData', { currentGameData: result });
		}

		return !!result;
	}

};
