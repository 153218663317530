export default {

	toggleProfileMenuIsVisible (state) {
		state.profileMenuIsVisible = !state.profileMenuIsVisible;
	},

	setProfileMenuIsHidden (state) {
		state.profileMenuIsVisible = false;
	},

	toggleDrawerIsVisible (state) {
		state.drawerIsVisible = !state.drawerIsVisible;
	},

	setDrawerIsHidden (state) {
		state.drawerIsVisible = false;
	},

	showNotification (state, { notification }) {
		state.notification = notification;
		setTimeout(() => {
			// should be an action but time is short
			this.commit('ui/removeNotification');
		}, 3000);
	},

	removeNotification (state) {
		state.notification = '';
	},

	setLoadingIsVisible (state) {
		state.loadingIsVisible = true;
		document.body.setAttribute('data-loading', true);
	},

	setLoadingIsHidden (state) {
		state.loadingIsVisible = false;
		document.body.removeAttribute('data-loading');
	},

	setModalIsHidden (state) {
		state.modalIsVisible = false;
		document.body.removeAttribute('data-modal');
	},

	showError (state) {
		state.errorIsVisible = true;
		// should be an action but time is short
		this.commit('ui/clearBreadcrumbs');
	},

	hideError (state) {
		state.errorIsVisible = false;
	},

	setModalIsVisible (state) {
		state.modalIsVisible = true;
		document.body.setAttribute('data-modal', true);
	},

	setBreadcrumbs (state, { breadcrumbs }) {
		state.breadcrumbs = breadcrumbs;
	},

	clearBreadcrumbs (state) {
		state.breadcrumbs = undefined;
	},

	clearTourSteps (state) {
		state.tourSteps = [];
	},

	addTourSteps (state, { tourSteps }) {
		const unseenTourSteps = tourSteps.filter((step) => {
			return !state.seenTourSteps.includes(step.tourId);
		});
		state.tourSteps.push(...unseenTourSteps);
	},

	addSeenTourStep (state, { tourStep }) {
		state.seenTourSteps.push(tourStep.tourId);
	},

	addSeenFinalTourStep (state) {
		const tourSteps = state.tourSteps;
		const lastTourStepIndex = state.tourSteps.length - 1;
		const lastTourStep = tourSteps[lastTourStepIndex];
		const lastTourStepTourId = lastTourStep.tourId;
		state.seenTourSteps.push(
			lastTourStepTourId
		);
	}

};
