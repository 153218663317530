export default {

	setRemoteControlWindowIsOpen (state) {
		state.remoteControlWindowIsOpen = true;
	},

	setRemoteControlWindowIsNotOpen (state) {
		state.remoteControlWindowIsOpen = false;
	}

};
