export default {

	setHideEmptySuitsByUuid (state, {
		uuid,
		hideEmptySuits
	}) {
		state.diagrams = state.diagrams.map((diagram) => {
			if (diagram.uuid !== uuid) {
				return diagram;
			}
			return {
				...diagram,
				config: {
					...diagram.config,
					hideEmptySuits
				}
			};
		});
	},

	setCardsByUuidAndPositionAndSuit (state, {
		uuid,
		position,
		suit,
		cards
	}) {
		state.diagrams = state.diagrams.map((diagram) => {
			if (diagram.uuid !== uuid) {
				return diagram;
			}
			return {
				...diagram,
				config: {
					...diagram.config,
					[position]: {
						...diagram.config?.[position],
						[suit]: cards
					}
				}
			};
		});
	}

};
