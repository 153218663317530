import gameService from '@/services/gameService';

export default {

	async setDDSScoresAreVisible ({ commit, getters }) {
		if (!getters.getGameData) {
			return false;
		}
		const gameData = await gameService.enableDDS({
			gameData: getters.getGameData
		});
		commit('setCurrentGameData', {
			currentGameData: gameData
		});
		commit('setDDSScoresAreVisible');
	},

	async setDDSScoresAreNotVisible ({ commit, getters }) {
		if (!getters.getGameData) {
			return false;
		}
		const gameData = await gameService.disableDDS({
			gameData: getters.getGameData
		});
		commit('setCurrentGameData', {
			currentGameData: gameData
		});
		commit('setDDSScoresAreNotVisible');
	}

};
