import ui          from './ui';
import lesson      from './lesson';
import activities  from './activities';
import activity    from './activity';

export default {

	...ui,
	...lesson,
	...activities,
	...activity

};
