import { get } from '@/services/api/methods';

export default {

	getArticles: ({
		include,
		page,
		per,
		category,
		featured
	} = {}) => {
		return get('/kb/articles', {
			options: {
				include,
				page,
				per,
				featured,
				category
			}
		});
	},

	getArticleBySlug: ({ slug }) => {
		return get(`/kb/articles/${slug}`);
	},

	getArticleBySlugAndCategorySlug: ({
		articleSlug,
		categorySlug,
		include
	}) => {
		return get(`/kb/articles/${categorySlug}/${articleSlug}`, {
			options: {
				include
			}
		});
	},

	getCategories: ({
		include
	}) => {
		return get('/kb/categories', {
			options: {
				include
			}
		});
	},

	getCategoryBySlug: ({
		slug,
		include
	}) => {
		return get(`/kb/categories/${slug}`, {
			options: {
				include
			}
		});
	}

};
