export default {

	// addBid ({ commit, dispatch, getters }, { bid }) {
	// 	try {
	// 		const gameData = gameService.addBidByName({
	// 			bidName: bid.bid,
	// 			gameData: getters.getGameData
	// 		});
	// 		commit('setCurrentGameData', { currentGameData: gameData });
	// 	} catch (err) {
	// 		console.log(err);
	// 	}
	// }

};
