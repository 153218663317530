export default {

	getQuiz: (state, getters) => {
		return {
			title: getters.getTitle,
			config: {
				question: getters.getQuestion,
				hint: getters.getHint,
				mustChooseAllCorrectAnswers: getters.getMustChooseAllCorrectAnswers,
				explanation: getters.getExplanation,
				diagrams: getters.getAllDiagrams
			}
		};
	},

	getTitle: (state) => {
		return state.title;
	},

	getQuestion: (state) => {
		return state.question;
	},

	getHint: (state) => {
		return state.hint;
	},

	getMustChooseAllCorrectAnswers: (state) => {
		return state.mustChooseAllCorrectAnswers;
	},

	getExplanation: (state) => {
		return state.explanation;
	}

};
