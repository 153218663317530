import { SIMULATOR_MODES } from '@/consts';

export default {

	getGameData: (state, getters) => {
		if (!state.currentGameData) {
			return false;
		}
		return state.currentGameData;
	},

	getGameState: (state) => {
		return state.currentGameState;
	},

	getIsLoading: (state) => {
		return state.loading;
	},

	getGameMode: (state) => {
		return state.mode;
	},

	getIsStudyMode: (state) => {
		return state.mode === SIMULATOR_MODES.STUDY;
	},

	getIsStudyBiddingModeStarted: (state, getters) => {
		return getters.getIsStudyMode && state.studyModeBiddingInProgress;
	},

	getIsStudyModeBiddingNotStarted: (state, getters) => {
		return getters.getIsStudyMode && !state.studyModeBiddingInProgress;
	},

	getStartWithEmptyAuctionForMode: () => (mode) => {
		return [
			SIMULATOR_MODES.STUDY
			// TODO: Add more modes if they should start with an empty auction
		].includes(mode);
	},

	getDeck: (state, getters) => {
		if (!getters.getGameData) {
			return false;
		}
		return getters.getGameData?.deck;
	},

	getDealTitle: (state, getters) => {
		if (!getters.getGameData) {
			return false;
		}
		return getters.getGameData?.name;
	},

	getVulnerabilityString: (state, getters) => {
		if (!getters.getGameData) {
			return false;
		}
		if (!getters.getGameData.vulnerable) {
			return false;
		}
		const str = getters.getGameData.vulnerable;
		if (str === 'None') {
			return 'VUL: Nil All';
		}
		if (str === 'NS' ||
				str === 'EW') {
			return `VUL: ${str[0]}/${str[1]}`;
		}
		return 'VUL: All';
	},

	getGameId (state) {
		return state.gameId;
	},

	getSessionGameData (state) {
		return state.sessionGameData;
	},

	getLessonId (state) {
		return state.lessonId;
	},

	getGameIsComplete (state, getters) {
		return getters.getGameData?.isComplete;
	}

};
