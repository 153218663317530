<template>
	<component
		data-component="icon"
		:is="getSvg"
		:data-colour="colour"
		:data-icon="icon"
		:alt="icon"
	/>
</template>

<script>

	/* eslint-disable vue/no-unused-components */

	import { pascalCase }         from 'change-case';
	import ArrowLeft              from '@/assets/icons/arrow-left.svg?inline';
	import ArrowRight             from '@/assets/icons/arrow-right.svg?inline';
	import Book                   from '@/assets/icons/book.svg?inline';
	import Burger                 from '@/assets/icons/burger.svg?inline';
	import CaretDown              from '@/assets/icons/caret-down.svg?inline';
	import CaretUp                from '@/assets/icons/caret-up.svg?inline';
	import ChevronLeft            from '@/assets/icons/chevron-left.svg?inline';
	import ChevronLeftDouble      from '@/assets/icons/chevron-left-double.svg?inline';
	import ChevronRight           from '@/assets/icons/chevron-right.svg?inline';
	import ChevronRightDouble     from '@/assets/icons/chevron-right-double.svg?inline';
	import Club                   from '@/assets/icons/club.svg?inline';
	import ClubWithoutPadding     from '@/assets/icons/club-without-padding.svg?inline';
	import Courses                from '@/assets/icons/courses.svg?inline';
	import CrossCircle            from '@/assets/icons/cross-circle.svg?inline';
	import Cross                  from '@/assets/icons/cross.svg?inline';
	import Dealer                 from '@/assets/icons/dealer.svg?inline';
	import Diamond                from '@/assets/icons/diamond.svg?inline';
	import DiamondWithoutPadding  from '@/assets/icons/diamond-without-padding.svg?inline';
	import Document               from '@/assets/icons/document.svg?inline';
	import EditAlt                from '@/assets/icons/edit-alt.svg?inline';
	import Edit                   from '@/assets/icons/edit.svg?inline';
	import Equal                  from '@/assets/icons/equal.svg?inline';
	import Exclamation            from '@/assets/icons/exclamation.svg?inline';
	import Ff                     from '@/assets/icons/ff.svg?inline';
	import FlipCard               from '@/assets/icons/flip-card.svg?inline';
	import Gamepad                from '@/assets/icons/gamepad.svg?inline';
	import Heart                  from '@/assets/icons/heart.svg?inline';
	import HeartWithoutPadding    from '@/assets/icons/heart-without-padding.svg?inline';
	import Hide                   from '@/assets/icons/hide.svg?inline';
	import Highlight              from '@/assets/icons/highlight.svg?inline';
	import Home                   from '@/assets/icons/home.svg?inline';
	import Info                   from '@/assets/icons/info.svg?inline';
	import Medal                  from '@/assets/icons/medal.svg?inline';
	import Menu                   from '@/assets/icons/menu.svg?inline';
	import Plane                  from '@/assets/icons/plane.svg?inline';
	import Play                   from '@/assets/icons/play.svg?inline';
	import Plus                   from '@/assets/icons/plus.svg?inline';
	import Reorder                from '@/assets/icons/reorder.svg?inline';
	import RotateBack             from '@/assets/icons/rotate-back.svg?inline';
	import RotateForward          from '@/assets/icons/rotate-forward.svg?inline';
	import Rw                     from '@/assets/icons/rw.svg?inline';
	import Search                 from '@/assets/icons/search.svg?inline';
	import Settings               from '@/assets/icons/settings.svg?inline';
	import Ship                   from '@/assets/icons/ship.svg?inline';
	import Show                   from '@/assets/icons/show.svg?inline';
	import Spade                  from '@/assets/icons/spade.svg?inline';
	import SpadeWithoutPadding    from '@/assets/icons/spade-without-padding.svg?inline';
	import Spinner                from '@/assets/icons/spinner.svg?inline';
	import Star                   from '@/assets/icons/star.svg?inline';
	import User                   from '@/assets/icons/user.svg?inline';
	import Users                  from '@/assets/icons/users.svg?inline';
	import UserCircle             from '@/assets/icons/user-circle.svg?inline';
	import UserGroup              from '@/assets/icons/user-group.svg?inline';
	import Warning                from '@/assets/icons/warning.svg?inline';
	import Check                  from '@/assets/icons/check.svg?inline';
	import Question               from '@/assets/icons/question.svg?inline';
	import FullscreenOpen         from '@/assets/icons/fullscreen-open.svg?inline';
	import FullscreenClose        from '@/assets/icons/fullscreen-close.svg?inline';
	import Stop                   from '@/assets/icons/stop.svg?inline';
	import Pause                  from '@/assets/icons/pause.svg?inline';

	export default {
		components: {
			ArrowLeft,
			ArrowRight,
			Book,
			Burger,
			CaretDown,
			CaretUp,
			ChevronLeft,
			ChevronLeftDouble,
			ChevronRight,
			ChevronRightDouble,
			Club,
			ClubWithoutPadding,
			Courses,
			CrossCircle,
			Cross,
			Dealer,
			Diamond,
			DiamondWithoutPadding,
			Document,
			EditAlt,
			Edit,
			Equal,
			Exclamation,
			Ff,
			FlipCard,
			Gamepad,
			Heart,
			HeartWithoutPadding,
			Hide,
			Highlight,
			Home,
			Info,
			Medal,
			Menu,
			Plane,
			Play,
			Plus,
			Reorder,
			RotateBack,
			RotateForward,
			Rw,
			Search,
			Settings,
			Ship,
			Show,
			Spade,
			SpadeWithoutPadding,
			Spinner,
			Star,
			User,
			Users,
			UserCircle,
			UserGroup,
			Warning,
			Question,
			Check,
			FullscreenOpen,
			FullscreenClose,
			Stop,
			Pause
		},
		props: {
			icon: {
				type: [String, Boolean],
				default: 'box'
			},
			colour: {
				type: String,
				default: 'text'
			}
		},
		computed: {
			getSvg () {
				if (!this.icon) {
					return false;
				}
				return pascalCase(this.icon);
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='icon'] {
		display:block;
		&[data-colour='black'] {
			* {
				fill: $c-brand-black !important;
			}
		}
		&[data-colour='red'] {
			* {
				fill: $c-brand-red !important;
			}
		}
		&[data-colour='blue'] {
			* {
				fill: $c-brand-blue !important;
			}
		}
		&[data-colour='green'] {
			* {
				fill: $c-brand-green !important;
			}
		}
		&[data-colour='orange'] {
			* {
				fill: $c-brand-orange !important;
			}
		}
		&[data-colour='blue-lighter-1'] {
			* {
				fill: $c-brand-blue-lighter-1 !important;
			}
		}
		&[data-colour='blue-lighter-2'] {
			* {
				fill: $c-brand-blue-lighter-2 !important;
			}
		}
		&[data-colour='blue-lighter-3'] {
			* {
				fill: $c-brand-blue-lighter-3 !important;
			}
		}
		&[data-colour='blue-lighter-4'] {
			* {
				fill: $c-brand-blue-lighter-4 !important;
			}
		}
		&[data-colour='grey'] {
			* {
				fill: $c-brand-grey !important;
			}
		}
		&[data-colour='grey-lighter-1'] {
			* {
				fill: $c-brand-grey-lighter-1 !important;
			}
		}
		&[data-colour='grey-lighter-2'] {
			* {
				fill: $c-brand-grey-lighter-2 !important;
			}
		}
		&[data-colour='grey-lighter-3'] {
			* {
				fill: $c-brand-grey-lighter-3 !important;
			}
		}
		&[data-colour='grey-lighter-4'] {
			* {
				fill: $c-brand-grey-lighter-4 !important;
			}
		}
		&[data-colour='text'] {
			* {
				fill: $c-text !important;
			}
		}
		&[data-colour='white'] {
			* {
				fill: $c-white !important;
			}
		}
		&[data-colour='yellow'] {
			* {
				fill: yellow !important;
			}
		}
	}

</style>
