export default {

	getPagination: (state, getters) => {
		return {
			perPage: state.perPage,
			currentPage: state.currentPage,
			totalPages: state.numPages
		};
	},

	getTotalItems: (state, getters) => {
		return state.totalItems;
	}

};
