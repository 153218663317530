export default {

	getBidsByUuid: (state, getters) => ({ uuid }) => {
		const diagram = getters.getDiagramByUuid({ uuid });
		return diagram?.config?.bids;
	},

	getDealerPositionByUuid: (state, getters) => ({ uuid }) => {
		const diagram = getters.getDiagramByUuid({ uuid });
		return diagram?.config?.dealerPosition;
	},

	getPostSymbolByUuid: (state, getters) => ({ uuid }) => {
		const diagram = getters.getDiagramByUuid({ uuid });
		return diagram?.config?.postSymbol;
	}

};
