export default {

	setTitle (state, { title }) {
		state.title = title;
	},

	clearTitle (state) {
		state.title = '';
	},

	setQuestion (state, { question }) {
		state.question = question;
	},

	clearQuestion (state) {
		state.question = '';
	},

	setHint (state, { hint }) {
		state.hint = hint;
	},

	clearHint (state) {
		state.hint = '';
	},

	setMustChooseAllCorrectAnswers (state, {
		mustChooseAllCorrectAnswers
	}) {
		state.mustChooseAllCorrectAnswers = mustChooseAllCorrectAnswers;
	},

	clearMustChooseAllCorrectAnswers (state) {
		state.mustChooseAllCorrectAnswers = false;
	},

	setExplanation (state, { explanation }) {
		state.explanation = explanation;
	},

	clearExplanation (state) {
		state.explanation = '';
	}

};
