export default {

	setMimeType (state, value) {
		state.mimeType = value;
	},

	setMicrophonePermission (state, value) {
		state.microphonePermission = value;
	},

	setControlsVisible (state, value) {
		state.controlsVisible = value;
	},

	setRecordingInProgress (state, value) {
		state.recordingInProgress = value;
	},

	setRecordingPaused (state, value) {
		state.recordingPaused = value;
	},

	setPreviewBlob (state, value) {
		state.previewBlob = value;
	}

};
