import bidExplanations from './bidExplanations';
import bidHints from './bidHints';
import declarerHint from './declarerHint';

export default {

	...bidExplanations,
	...bidHints,
	...declarerHint,

	clearParameters ({ commit }) {
		commit('setBidExplanations', []);
		commit('setActiveBidExplanation', '');
		commit('setBidExplanationIsVisible', false);
		commit('setBidHints', []);
		commit('setActiveBidHint', '');
		commit('setBidHintIsVisible', false);
		// TODO: Clear others parameters...
	}

};
