export default {

	openRemoteControl ({ dispatch, commit, state }) {
		window.open(
			'/simulator-remote',
			'remoteControl',
			'width=263,height=360,resizable=0,location=0'
		);
		commit('setRemoteControlWindowIsOpen');
	},

	closeRemoteControl ({ dispatch, commit, state, getters }) {
		commit('setRemoteControlWindowIsNotOpen');
		window.close();
	}

};
