export default {

	getDrawerIsVisible: (state) => {
		return state.drawerIsVisible;
	},

	getNotification: (state) => {
		if (!state.notification ||
				!state.notification.length) {
			return false;
		}
		return state.notification;
	},

	getLoadingIsVisible: (state) => {
		return state.loadingIsVisible;
	},

	getErrorIsVisible: (state) => {
		return state.errorIsVisible;
	},

	getBreadcrumbs: (state) => {
		if (!state.breadcrumbs?.length) {
			return false;
		}
		return state.breadcrumbs;
	},

	getTourSteps: (state) => {
		return state.tourSteps;
	},

	getUnseenTourSteps: (state) => {
		return	state.tourSteps.filter((step) => {
			return !state.seenTourSteps.includes(step.tourId);
		});
	},

	getTourStepIds: (state) => {
		return state.tourSteps.map((step) => {
			return step.tourId;
		});
	},

	getTourStepByIndex: (state) => ({ tourStepIndex }) => {
		return state.tourSteps[tourStepIndex];
	}

};
