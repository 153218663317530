// import api from '@/services/api';

export default {

	// async setDeals ({ commit, dispatch, getters, state }) {
	// 	try {
	// 		const response = await api.deals.getDeals({
	// 			include: 'metadata,filename,board,dealer,name,focus_keyword,tags',
	// 			searchString: state.searchString,
	// 			filters: state.activeFilters,
	// 			page: state.currentPage,
	// 			per: 10,
	// 			sortBy: 'name',
	// 			sortDir: 'ASC'
	// 		});
	// 		commit('setMeta', {
	// 			currentPage: response.meta.page,
	// 			numPages: response.meta.numPages,
	// 			totalItems: response.meta.totalItems
	// 		});
	// 		commit('setFilterOptions', {
	// 			board: response.meta.board,
	// 			dealer: response.meta.dealer,
	// 			filename: response.meta.filename,
	// 			focus: response.meta.focus,
	// 			name: response.meta.name,
	// 			tags: response.meta.name
	// 		});
	// 		commit('setDeals', {
	// 			deals: response.results
	// 		});
	// 	} catch (err) {
	// 		console.log(err);
	// 	}
	// }

};
