export default {

	clearSimulatorControls ({ commit }) {
		commit('closeAllMenus');
		commit('setRemoteControlWindowIsNotOpen');
	},

	clickControl ({ dispatch, commit, getters }, { control }) {
		if (control.isDisabled) {
			return false;
		}
		if (getters.getControlMenuIsOpen({ control })) {
			return commit('closeAllMenus');
		}
		commit('closeAllMenus');
		dispatch('doControlOnClick', { control });
		if (control.menu) {
			commit('openMenu', { control });
		}
	},

	doControlOnClick ({ dispatch, commit }, { control }) {
		if (!control.onClick ||
				!control.onClick.type) {
			commit('closeAllMenus');
			return;
		}
		if (!control.onClick.action &&
				!control.onClick.mutation) {
			commit('closeAllMenus');
			return;
		}
		if (control.onClick.type !== 'dispatch' &&
				control.onClick.type !== 'commit') {
			commit('closeAllMenus');
			return;
		}
		if (control.onClick.type === 'dispatch') {
			dispatch(`${control.onClick.module}/${control.onClick.action}`, null, { root: true });
		}
		if (control.onClick.type === 'commit') {
			commit(`${control.onClick.module}/${control.onClick.mutation}`, null, { root: true });
		}
		commit('closeAllMenus');
	}

};
