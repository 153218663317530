export default {

	setBidsByUuid (state, { uuid, bids }) {
		state.diagrams = state.diagrams.map((diagram) => {
			if (diagram.uuid !== uuid) {
				return diagram;
			}
			return {
				...diagram,
				config: {
					...diagram.config,
					bids
				}
			};
		});
	},

	setDealerPositionByUuid (state, { uuid, dealerPosition }) {
		state.diagrams = state.diagrams.map((diagram) => {
			if (diagram.uuid !== uuid) {
				return diagram;
			}
			return {
				...diagram,
				config: {
					...diagram.config,
					dealerPosition
				}
			};
		});
	},

	setPostSymbolByUuid (state, { uuid, postSymbol }) {
		state.diagrams = state.diagrams.map((diagram) => {
			if (diagram.uuid !== uuid) {
				return diagram;
			}
			return {
				...diagram,
				config: {
					...diagram.config,
					postSymbol
				}
			};
		});
	}

};
