import { pascalCase }  from 'change-case';

export default {

	getActivity: (state, getters) => {
		return state.activity;
	},

	getActivityId: (state, getters) => {
		return getters.getActivity.id;
	},

	getActivityIndex: (state, getters) => {
		return getters.getActivities?.findIndex((activity) => {
			return activity.id === getters.getActivityId;
		});
	},

	getActivityType: (state, getters) => {
		return getters.getActivity?.type;
	},

	getActivityTitle: (state, getters) => {
		return getters.getActivity?.title;
	},

	getActivityComponent: (state, getters) => {
		if (!getters.getActivityType) {
			return false;
		}
		if (getters.getActivity.type === 'embeddedPresentation' ||
				getters.getActivity.type === 'video') {
			return 'Media';
		}
		return pascalCase(getters.getActivity.type);
	},

	getActivityMediaUrl: (state, getters) => {
		return getters.getActivity?.media?.[0]?.url;
	},

	getActivityMediaComponent: (state, getters) => {
		if (getters.getActivityType === 'embeddedPresentation') {
			return 'PresentationViewer';
		}
		return 'VideoViewer';
	},

	getActivityIntertitleHeading (state, getters) {
		return getters.getActivity?.title;
	},

	getActivityIntertitleText (state, getters) {
		return getters.getActivity?.config?.text;
	}

};
