import { POSITIONS_SHORT, SIMULATOR_MODES } from '@/consts';

export default {

	turnHandsForMode ({ dispatch, getters }, mode) {
		if ([SIMULATOR_MODES.STUDY].includes(mode)) {
			dispatch('turnAllHandsFaceDownExceptPosition', getters.getPlayerPosition);
		} else {
			dispatch('turnAllCardsFaceDown');
		}
	},

	turnAllCardsFaceDown ({ dispatch, getters }) {
		Object
			.values(POSITIONS_SHORT)
			.forEach((position) => {
				dispatch('setHandIsFaceDownByPosition', { position });
			});
	},

	turnAllHandsFaceDownExceptPosition ({ dispatch, getters }, position) {
		Object
			.values(POSITIONS_SHORT)
			.filter(item => item !== position)
			.forEach((pos) => {
				dispatch('setHandIsFaceDownByPosition', { position: pos });
			});
		dispatch('setHandIsFaceUpByPosition', { position });
	},

	turnPlayerHandFaceUp ({ dispatch, getters }) {
		dispatch('setHandIsFaceUpByPosition', getters.getPlayerPosition);
	},

	TurnAllHandsFaceDownExceptSouth ({ dispatch, getters }) {
		['W', 'N', 'E'].forEach((position) => {
			dispatch('setHandIsFaceDownByPosition', { position });
		});
		dispatch('setHandIsFaceUpByPosition', { position: 'S' });
	},

	turnDummyFaceUp ({ dispatch, getters }) {
		['N', 'E', 'S', 'W'].forEach((position) => {
			if (!getters.getIsDummyByPosition({ position })) {
				return;
			}
			dispatch('setHandIsFaceUpByPosition', { position });
		});
	},

	turnAllHandsFaceDownExceptSouthAndDummy ({ dispatch, getters }) {
		['N', 'E', 'W'].forEach((position) => {
			if (getters.getIsDummyByPosition({ position })) {
				dispatch('setHandIsFaceUpByPosition', { position });
				return;
			}
			dispatch('setHandIsFaceDownByPosition', { position });
		});
		dispatch('setHandIsFaceUpByPosition', { position: 'S' });
	},

	toggleCardIsFaceUp ({ commit, getters }, { cardName }) {
		if (getters.getCardIsFaceDownByCardName({ cardName })) {
			commit('setCardIsFaceUp', { cardName });
			return;
		}
		commit('setCardIsFaceDown', { cardName });
	},

	setSpadesAreFaceDown ({ dispatch }) {
		dispatch('setCardsAreFaceDownBySuit', { suit: 'S' });
	},

	setSpadesAreFaceUp ({ dispatch }) {
		dispatch('setCardsAreFaceUpBySuit', { suit: 'S' });
	},

	setHeartsAreFaceDown ({ dispatch }) {
		dispatch('setCardsAreFaceDownBySuit', { suit: 'H' });
	},

	setHeartsAreFaceUp ({ dispatch }) {
		dispatch('setCardsAreFaceUpBySuit', { suit: 'H' });
	},

	setDiamondsAreFaceDown ({ dispatch }) {
		dispatch('setCardsAreFaceDownBySuit', { suit: 'D' });
	},

	setDiamondsAreFaceUp ({ dispatch }) {
		dispatch('setCardsAreFaceUpBySuit', { suit: 'D' });
	},

	setClubsAreFaceDown ({ dispatch }) {
		dispatch('setCardsAreFaceDownBySuit', { suit: 'C' });
	},

	setClubsAreFaceUp ({ dispatch }) {
		dispatch('setCardsAreFaceUpBySuit', { suit: 'C' });
	},

	setAllCardsAreFaceDown ({ commit, getters }) {
		commit('clearAllFaceDownCards');
		if (!getters.getDeck) {
			return false;
		}
		commit('setCardsAreFaceDown', {
			cardNames: getters.getDeck()
		});
	},

	turnAllCardsFaceUp ({ commit }) {
		commit('turnAllCardsFaceUp');
	},

	toggleHandIsFaceDownByPosition ({ dispatch, getters }, { position }) {
		if (getters.getHandIsFaceDownByPosition({ position })) {
			dispatch('setHandIsFaceUpByPosition', { position });
			return;
		}
		dispatch('setHandIsFaceDownByPosition', { position });
	},

	setHandIsFaceDownByPosition ({ commit, getters }, { position }) {
		const unplayedCards = getters.getUnplayedCardsByPosition({
			position
		});
		if (!unplayedCards) {
			return false;
		}
		const cardNames = getters.getUnplayedCardsByPosition({
			position
		}).map((card) => {
			return card.name;
		});
		commit('setCardsAreFaceDown', { cardNames });
	},

	setHandIsFaceUpByPosition ({ commit, getters }, { position }) {
		const unplayedCards = getters.getUnplayedCardsByPosition({
			position
		});
		if (!unplayedCards) {
			return false;
		}
		const cardNames = getters.getUnplayedCardsByPosition({
			position
		}).map((card) => {
			return card.name;
		});
		commit('setCardsAreFaceUp', { cardNames });
	},

	setCardsAreFaceDownBySuit ({ commit, getters }, { suit }) {
		if (!getters.getDeck) {
			return false;
		}
		commit('setCardsAreFaceDown', {
			cardNames: getters.getDeck.filter((cardName) => {
				return cardName.includes(suit);
			})
		});
	},

	setCardsAreFaceUpBySuit ({ commit, getters }, { suit }) {
		if (!getters.getDeck) {
			return false;
		}
		commit('setCardsAreFaceUp', {
			cardNames: getters.getDeck.filter((cardName) => {
				return cardName.includes(suit);
			})
		});
	}

};
